import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { Backdrop, CircularProgress } from '@material-ui/core';
import styles from './MTRPurchaseAdditionalCallsPage.scss';
import logoNoTextImage from '../../../../myndlift-signup/public/assets/logo-no-text.jpg';
import logoOnlyTextImage from '../../../../myndlift-signup/public/assets/logo-only-text.jpg';
import { withStripeElementsHOC } from '../../Core/hocs/withStripeElements/withStripeElements';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { PaymentStatusDialog } from './PaymentStatusDialog/PaymentStatusDialog';
import failedImage from '../../../../myndlift-signup/public/assets/failed.png';
import checkedImage from '../../../../myndlift-signup/public/assets/checked.png';
import {
  // postToNode,
  getCurrentURLParams,
  notifyAppThatComponentIsLoaded,
  postMessageToApp,
  postToFunctions,
} from '../../utils/utils';

const FailedPaymentDialog = props => (
  <PaymentStatusDialog
    primaryText="Payment failed"
    primaryTextDescription="Unfortunately, we couldn’t process your payment"
    primaryBtnText="TRY AGAIN"
    image={failedImage}
    {...props}
  />
);

const SucceededPaymentDialog = props => (
  <PaymentStatusDialog
    primaryText="Payment succeeded"
    primaryTextDescription="An additional call was added to your calls balance"
    primaryBtnText="Schedule call"
    image={checkedImage}
    {...props}
  />
);

const initialValue = Symbol('IV');
const MTRPurchaseAdditionalCallsPageBase = ({
  onComponentReadyToRender,
  stripe,
}) => {
  const { fbtoken, userId, adminUserId } = getCurrentURLParams();

  const [isFailedPaymentDialogOpen, setIsFailedPaymentDialogOpen] = useState(
    false
  );
  const [
    isSucceededPaymentDialogOpen,
    setIsSucceededPaymentDialogOpen,
  ] = useState(false);
  const [shouldShowLoader, setShouldShowLoader] = useState(false);
  const [isComponentReadyToRender, setIsComponentReadyToRender] = useState(
    false
  );

  const [additionalSessionPrice] = useFirebaseDB({
    path: 'remoteUsersConfig/additionalCallsCost',
    onceListener: true,
    initialValue,
  });

  useEffect(() => {
    if (additionalSessionPrice !== initialValue && !isComponentReadyToRender) {
      setIsComponentReadyToRender(true);
      onComponentReadyToRender();
      notifyAppThatComponentIsLoaded();
    }
  }, [
    additionalSessionPrice,
    isComponentReadyToRender,
    onComponentReadyToRender,
  ]);

  const cardElementRef = useRef();
  const [creditCardError, setCreditCardError] = useState(null);
  const onCreditCardChange = useCallback(e => {
    if (e.error && e.error.message) {
      setCreditCardError(e.error.message);
    } else if (e.empty) {
      setCreditCardError('Credit card details are Required');
    } else {
      setCreditCardError(null);
    }
  }, []);

  const handlePaymentFailure = useCallback(() => {
    setIsFailedPaymentDialogOpen(true);
  }, []);
  const handlePaymentSuccess = useCallback(() => {
    setIsSucceededPaymentDialogOpen(true);
  }, []);

  const onPurchaseAdditionalSession = useCallback(async () => {
    setShouldShowLoader(true);
    try {
      const stripeTokenResult = await stripe.createToken();
      if (stripeTokenResult.error) {
        setCreditCardError(stripeTokenResult.error.message);
      } else {
        const buyAdditionalCallsResult = await postToFunctions({
          path: 'mtr-buyAdditionalCalls',
          data: {
            details: stripeTokenResult,
            fbtoken,
            userId,
            remoteAdminId: adminUserId,
          },
        });

        if (buyAdditionalCallsResult.result) {
          handlePaymentSuccess();
        } else {
          handlePaymentFailure();
        }
      }
    } catch (err) {
      handlePaymentFailure();
    }
    setShouldShowLoader(false);
  }, [
    fbtoken,
    handlePaymentFailure,
    handlePaymentSuccess,
    adminUserId,
    stripe,
    userId,
  ]);

  return (
    isComponentReadyToRender && (
      <div>
        <div className={styles.logo_container}>
          <img src={logoNoTextImage} />
          <img src={logoOnlyTextImage} />
        </div>
        <div className={styles.main_divider} />
        <div className={styles.content}>
          <h2>Purchase an additional Neuro Coach session</h2>

          <div className={styles.stripe_element}>
            <CardElement
              onChange={onCreditCardChange}
              onReady={element => {
                cardElementRef.current = element;
              }}
              style={{
                base: {
                  color: '#585858',
                },
              }}
              hidePostalCode
            />
            <p className={styles.error}>{creditCardError}</p>
          </div>

          <button type="button" onClick={onPurchaseAdditionalSession}>
            <span>Purchase for ${additionalSessionPrice}</span>
          </button>
        </div>

        {shouldShowLoader && (
          <div>
            <Backdrop open classes={{ root: styles.backdrop }} />

            <div className={styles.progress_container}>
              <CircularProgress color="inherit" />
            </div>
          </div>
        )}

        <FailedPaymentDialog
          isOpen={isFailedPaymentDialogOpen}
          onPrimaryBtnClick={() => {
            setIsFailedPaymentDialogOpen(false);
          }}
        />
        <SucceededPaymentDialog
          isOpen={isSucceededPaymentDialogOpen}
          onPrimaryBtnClick={() => {
            postMessageToApp('scheduleExtraNeuroCoachCall');
          }}
        />
      </div>
    )
  );
};

MTRPurchaseAdditionalCallsPageBase.propTypes = exact({
  stripe: PropTypes.object.isRequired,
  onComponentReadyToRender: PropTypes.func.isRequired,
});

export const MTRPurchaseAdditionalCallsPage = React.memo(
  withStripeElementsHOC(injectStripe(MTRPurchaseAdditionalCallsPageBase))
);
MTRPurchaseAdditionalCallsPage.displayName = 'MTRPurchaseAdditionalCallsPage';
