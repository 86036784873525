export const formattedPlanTypesByBillingPeriodInMonths = {
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  biAnnually: 'Biannually',
  annually: 'Annually',
};

export const getPlanTypeByBillingPeriodInMonths = ({
  billingPeriodInMonths,
}) => {
  if (billingPeriodInMonths === 1) {
    return formattedPlanTypesByBillingPeriodInMonths.monthly;
  }
  if (billingPeriodInMonths === 3) {
    return formattedPlanTypesByBillingPeriodInMonths.quarterly;
  }
  if (billingPeriodInMonths === 6) {
    return formattedPlanTypesByBillingPeriodInMonths.biAnnually;
  }
  if (billingPeriodInMonths === 12) {
    return formattedPlanTypesByBillingPeriodInMonths.annually;
  }

  return `(${billingPeriodInMonths * 4}) Days Plan`;
};

export const getPlanPriceByWeek = ({ price, billingPeriodInMonths }) => {
  const numberOfWeeks = billingPeriodInMonths * 4;
  return Math.ceil(price / numberOfWeeks);
};

export const getPlanPriceByMonth = ({ price, billingPeriodInMonths }) => {
  return Math.ceil(price / billingPeriodInMonths);
};

export const getPlanSaving = ({
  price,
  billingPeriodInMonths,
  monthlyPlanPrice,
}) => {
  return monthlyPlanPrice * billingPeriodInMonths - price;
};

export const getPlanSavingPercentage = ({
  price,
  billingPeriodInMonths,
  monthlyPlanPrice,
}) => {
  const planSaving = getPlanSaving({
    price,
    billingPeriodInMonths,
    monthlyPlanPrice,
  });
  return parseInt(
    (planSaving / (monthlyPlanPrice * billingPeriodInMonths)) * 100,
    10
  );
};
