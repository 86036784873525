import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { CustomBtn } from '../CustomBtn/CustomBtn';
import styles from './SuccessfulPayment.scss';
import postCheckoutImage from '../../../../../myndlift-signup/public/assets/post-checkout.gif';
import postCheckoutOnlySoftwarePackageImage from '../../../../../myndlift-signup/public/assets/package-only.gif';

const SuccessfulPaymentBase = ({
  onVisitBlog,
  isFamilyMember,
  hasOnlySoftwarePackage,
  onAddMoreUser,
  moreUserPrice,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.img_container}>
        <img
          src={
            hasOnlySoftwarePackage
              ? postCheckoutOnlySoftwarePackageImage
              : postCheckoutImage
          }
        />
      </div>
      <h2>Thank you for your purchase! What’s next?</h2>
      <p>
        You will receive an email with an activation code to create your
        account.
      </p>

      <p>
        Interested in adding another family member at a{' '}
        <span style={{ fontWeight: 900 }}>discounted price?</span>
      </p>

      <div className={styles.btn_container}>
        <CustomBtn onClick={onAddMoreUser}>
          <span style={{ paddingLeft: 32, paddingRight: 32 }}>
            add more users
          </span>
        </CustomBtn>
      </div>

      <p>
        Or check out{' '}
        <a href="#" onClick={onVisitBlog}>
          our neurofeedback and brain health blog!
        </a>
      </p>
    </div>
  );
};

SuccessfulPaymentBase.propTypes = exact({
  onVisitBlog: PropTypes.func.isRequired,
  isFamilyMember: PropTypes.bool.isRequired,
  hasOnlySoftwarePackage: PropTypes.bool.isRequired,
  onAddMoreUser: PropTypes.func.isRequired,
  moreUserPrice: PropTypes.number.isRequired,
});

export const SuccessfulPayment = React.memo(SuccessfulPaymentBase);
SuccessfulPayment.displayName = 'SuccessfulPayment';
