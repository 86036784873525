import React from 'react';

/* eslint-disable react/no-unknown-property */

export const StepsIcon = props => (
  <svg
    width={341.372}
    height={256.999}
    {...props}
    viewBox="0 0 341.372 256.999"
  >
    <g
      id="Group_5"
      data-name="Group 5"
      transform="translate(-229.349 -1155.678)"
    >
      <circle
        id="Ellipse_2"
        data-name="Ellipse 2"
        cx="15"
        cy="15"
        r="15"
        transform="translate(229.349 1182.677)"
        fill="#a0b6f7"
      />
      <path
        id="Path_18"
        data-name="Path 18"
        d="M9107.905,5096.886c-72.041,38.006-339.3-26.612-325.99,40.286s3.956,26.411,0,46.3-18.126,18.676-5.752,35.708,15.8,3.455,11.553,22.9,16.713,20.889-3.584,42.763-2.217,59.922-2.217,59.922"
        transform="translate(-8537.651 -3940.323)"
        fill="none"
        stroke="#a0b6f7"
        stroke-width="2"
      />
      <text
        id="_1"
        data-name="1"
        transform="translate(244.349 1202.677)"
        fill="#fff"
        font-size="14"
        font-family="Avenir-Heavy, Avenir"
        font-weight="800"
      >
        <tspan x="-4" y="0">
          1
        </tspan>
      </text>
      <circle
        id="Ellipse_3"
        data-name="Ellipse 3"
        cx="15"
        cy="15"
        r="15"
        transform="translate(229.349 1232.677)"
        fill="#a0b6f7"
      />
      <text
        id="_2"
        data-name="2"
        transform="translate(244.349 1252.677)"
        fill="#fff"
        font-size="14"
        font-family="Avenir-Heavy, Avenir"
        font-weight="800"
      >
        <tspan x="-4" y="0">
          2
        </tspan>
      </text>
      <circle
        id="Ellipse_4"
        data-name="Ellipse 4"
        cx="15"
        cy="15"
        r="15"
        transform="translate(229.349 1282.677)"
        fill="#a0b6f7"
      />
      <text
        id="_3"
        data-name="3"
        transform="translate(244.349 1302.677)"
        fill="#fff"
        font-size="14"
        font-family="Avenir-Heavy, Avenir"
        font-weight="800"
      >
        <tspan x="-4" y="0">
          3
        </tspan>
      </text>
      <circle
        id="Ellipse_5"
        data-name="Ellipse 5"
        cx="15"
        cy="15"
        r="15"
        transform="translate(229.349 1332.677)"
        fill="#a0b6f7"
      />
      <text
        id="_4"
        data-name="4"
        transform="translate(244.349 1352.677)"
        fill="#fff"
        font-size="14"
        font-family="Avenir-Heavy, Avenir"
        font-weight="800"
      >
        <tspan x="-4" y="0">
          4
        </tspan>
      </text>
      <circle
        id="Ellipse_6"
        data-name="Ellipse 6"
        cx="15"
        cy="15"
        r="15"
        transform="translate(229.349 1382.677)"
        fill="#a0b6f7"
      />
      <text
        id="_5"
        data-name="5"
        transform="translate(244.349 1402.677)"
        fill="#fff"
        font-size="14"
        font-family="Avenir-Heavy, Avenir"
        font-weight="800"
      >
        <tspan x="-4" y="0">
          5
        </tspan>
      </text>
    </g>
  </svg>
);
