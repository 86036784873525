import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog, DialogActions } from '@material-ui/core';
import styles from './OutstandingPaymentErrorDialog.scss';
import failedImage from '../../../../../myndlift-signup/public/assets/failed.png';
import Button from '@material-ui/core/Button';

const OutstandingPaymentErrorDialogBase = ({
  isOpen,
  amount,
  onConfirm,
  onCancel,
  onRetry,
}) => {
  return (
    <Dialog open={isOpen} classes={{ paper: styles.dialog_paper }}>
      <div className={styles.content}>
        <img src={failedImage} />
        <p className={styles.payment_failed_label}>Action required</p>
        <p className={styles.payment_failed_description}>
          Our attempt to bill your credit card on file failed. The current
          outstanding amount is ${amount}. We’re asking to resolve this in order
          to proceed with the service.
        </p>
        <DialogActions style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <Button onClick={onCancel} color="default" variant="text">
            <span className={styles.button}>Nevermind</span>
          </Button>
          <Button onClick={onRetry} color="primary" variant="contained">
            <span className={styles.button}>Retry Charge</span>
          </Button>
        </DialogActions>
        <DialogActions>
          <Button
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
            onClick={onConfirm}
            color="primary"
            variant="contained"
            autoFocus
          >
            <span className={styles.button}>Update payment method</span>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

OutstandingPaymentErrorDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  amount: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
});

export const OutstandingPaymentErrorDialog = React.memo(
  OutstandingPaymentErrorDialogBase
);
OutstandingPaymentErrorDialog.displayName = 'OutstandingPaymentErrorDialog';
