exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".TestimonialCard_testimonial_card{display:flex;width:100%;height:100%;align-items:center}.TestimonialCard_testimonial_card_image_container{display:flex;align-items:center;justify-content:center;height:auto;padding-right:10px;width:23%;box-sizing:content-box}@media only screen and (max-width: 960px){.TestimonialCard_testimonial_card_image_container{width:35%}}@media only screen and (max-width: 768px){.TestimonialCard_testimonial_card_image_container{width:40%}}@media only screen and (max-width: 480px){.TestimonialCard_testimonial_card_image_container{width:80%}}.TestimonialCard_testimonial_card_img{width:100%;height:100%;-o-object-fit:cover;object-fit:cover;cursor:pointer}@media only screen and (max-width: 960px){.TestimonialCard_testimonial_card_img{-o-object-fit:contain;object-fit:contain}}.TestimonialCard_testimonial_card_text_container{width:70%;font-size:18px;align-items:center;display:flex;justify-content:center;align-items:center;box-sizing:content-box}@media only screen and (max-width: 960px){.TestimonialCard_testimonial_card_text_container{width:100%;padding:0 10px}}.TestimonialCard_testimonial_card_quote_text{overflow:hidden;width:100%;height:auto;box-sizing:content-box}.TestimonialCard_testimonial_card_info{display:flex;flex-direction:column;justify-content:center;padding-top:20px;font-size:16px;font-weight:bold}@media only screen and (max-width: 960px){.TestimonialCard_testimonial_card_info{flex-direction:row;justify-content:space-between;align-items:flex-start;box-sizing:content-box;width:100%}}.TestimonialCard_testimonial_card_user_name{display:flex;align-items:center}@media only screen and (max-width: 960px){.TestimonialCard_testimonial_card_user_name{flex-direction:column;justify-content:flex-start;align-items:flex-start;max-width:145px}}.TestimonialCard_testimonial_card_location{display:flex;align-items:baseline}\n", ""]);

// Exports
exports.locals = {
	"testimonial_card": "TestimonialCard_testimonial_card",
	"testimonial_card_image_container": "TestimonialCard_testimonial_card_image_container",
	"testimonial_card_img": "TestimonialCard_testimonial_card_img",
	"testimonial_card_text_container": "TestimonialCard_testimonial_card_text_container",
	"testimonial_card_quote_text": "TestimonialCard_testimonial_card_quote_text",
	"testimonial_card_info": "TestimonialCard_testimonial_card_info",
	"testimonial_card_user_name": "TestimonialCard_testimonial_card_user_name",
	"testimonial_card_location": "TestimonialCard_testimonial_card_location"
};