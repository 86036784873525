exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".FAQS_root{position:relative;padding-bottom:15px}.FAQS_root h2{margin:0px;margin-top:39px;margin-bottom:20px;text-align:center;padding:0px 20px}@media only screen and (max-width: 768px){.FAQS_root h2{padding-right:46px}}.FAQS_root p{font-size:20px !important}.FAQS_root a{color:#3a88fe}.FAQS_root a:visited{color:#3a88fe}.FAQS_search_icon_container{position:absolute;top:50px;right:20px;cursor:pointer}.FAQS_shrinked_search_label{display:none !important}.FAQS_close_icon_container{position:absolute;top:20px;right:54px;cursor:pointer}.FAQS_search_field{padding:0px 30px;padding-right:60px;padding-left:24px;position:relative;position:absolute;width:calc(100% - 49px);top:30px}.FAQS_expansion_panel_root{box-shadow:none !important}.FAQS_border{border-bottom:solid 1px #a8a8a842 !important}.FAQS_border_container{padding:0px 24px}.FAQS_text_container>p{padding-left:30px;font-size:14px !important}.FAQS_item-exit{opacity:1}.FAQS_item-exit-active{opacity:0;transition:0.4s ease-in-out}.FAQS_slide-in-right,.FAQS_item-enter{-webkit-animation:FAQS_slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;animation:FAQS_slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both}@-webkit-keyframes FAQS_slide-in-right{0%{transform:translateX(1000px);opacity:0}100%{transform:translateX(0);opacity:1}}@keyframes FAQS_slide-in-right{0%{transform:translateX(1000px);opacity:0}100%{transform:translateX(0);opacity:1}}\n", ""]);

// Exports
exports.locals = {
	"root": "FAQS_root",
	"search_icon_container": "FAQS_search_icon_container",
	"shrinked_search_label": "FAQS_shrinked_search_label",
	"close_icon_container": "FAQS_close_icon_container",
	"search_field": "FAQS_search_field",
	"expansion_panel_root": "FAQS_expansion_panel_root",
	"border": "FAQS_border",
	"border_container": "FAQS_border_container",
	"text_container": "FAQS_text_container",
	"item-exit": "FAQS_item-exit",
	"item-exit-active": "FAQS_item-exit-active",
	"slide-in-right": "FAQS_slide-in-right",
	"item-enter": "FAQS_item-enter"
};