import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import dayJS from 'dayjs';
import classNames from 'classnames';
import dayjsDuration from 'dayjs/plugin/duration';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { CardElement, injectStripe } from 'react-stripe-elements';
import logoNoTextImage from '../../../../myndlift-signup/public/assets/logo-no-text.jpg';
import logoOnlyTextImage from '../../../../myndlift-signup/public/assets/logo-only-text.jpg';
import checkedImage from '../../../../myndlift-signup/public/assets/checked.png';
import failedImage from '../../../../myndlift-signup/public/assets/failed.png';
import styles from './MTRRetryInvoicesPage.scss';
import {
  // postToNode,
  getCurrentURLParams,
  postMessageToApp,
  postToFunctions,
} from '../../utils/utils';
import { usePreviousValue } from '../../Core/hooks/usePreviousValue';
import { DelayedRenderer } from '../../Core/Components/DelayedRenderer/DelayedRenderer';

const wrapWithDelayedRenderer = node => (
  <DelayedRenderer delay={50}>{node}</DelayedRenderer>
);

dayJS.extend(dayjsDuration);

const steps = {
  reviewAction: 'reviewAction',
  loader: 'loader',
  editPayment: 'editPayment',
  paymentSucceeded: 'paymentSucceeded',
  paymentMightHaveSucceededPartially: 'paymentMightHaveSucceededPartially',
};
const initialStep = steps.editPayment;

const stripeElementStyle = {
  base: {
    color: '#585858',
  },
};

const GenericOutstandingPaymentsUpdatedStep = ({
  title,
  subTitle,
  onDoneBtnClick,
  isSuccess,
  btnLbl,
  isFromManageSubs,
}) => {
  return (
    <div className={styles.generic_subscription_updated_step}>
      <img
        src={isSuccess ? checkedImage : failedImage}
        className={styles.check_image}
      />
      <div className={styles.info_text}>
        <p>{title}</p>
        <p>{subTitle}</p>
      </div>
      <div className={styles.btn_container}>
        <button
          type="button"
          onClick={() => {
            onDoneBtnClick(isFromManageSubs);
          }}
        >
          <span>{btnLbl ? btnLbl : 'done'}</span>
        </button>
      </div>
    </div>
  );
};
GenericOutstandingPaymentsUpdatedStep.propTypes = exact({
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
  btnLbl: PropTypes.node,
  onDoneBtnClick: PropTypes.func.isRequired,
  isSuccess: PropTypes.bool,
  isFromManageSubs: PropTypes.bool,
});

const initialErrorContainer = {
  msg: null,
  handler: null,
};

const goToManageSubs = () => {
  window.open(
    `/mtr-manage-subscription.html${window.location.search}`,
    '_self'
  );
};
const onDoneBtnClickFunc = isFromManageSubs => {
  if (!!isFromManageSubs) {
    goToManageSubs();
  } else {
    postMessageToApp('doneBtnClicked');
  }
};

const MTRRetryInvoicesPageBase = ({
  customerDetails,
  adminUserId,
  mtrCode,
  stripe,
  outstandingPaymentsAmount,
  isFromManageSubs,
}) => {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [
    editPaymentMethodStepOpener,
    setEditPaymentMethodStepOpener,
  ] = useState(null);
  const prvStep = usePreviousValue(currentStep);
  const [loaderText, setLoaderText] = useState(null);
  const [stripeError, setStripeError] = useState(null);
  const [creditCardError, setCreditCardError] = useState(null);
  const [creditCardUpdateNote, setCreditCardUpdateNote] = useState(null);

  const [errorContainer, setErrorContainer] = useState(initialErrorContainer);
  const [
    outstandingPaymentDialogRenderedStep,
    setOutstandingPaymentDialogRenderedStep,
  ] = useState(null);

  const [
    currentOutstandingPaymentAmount,
    setCurrentOutstandingPaymentAmount,
  ] = useState(outstandingPaymentsAmount);

  const fbTokenRef = useRef(getCurrentURLParams().fbtoken);
  const creditCardElementRef = useRef();

  // const postToNodeWithDefaultData = params =>
  //   postToNode({
  //     ...params,
  //     data: {
  //       fbtoken: fbTokenRef.current,
  //       ...params.data,
  //     },
  //   });

  const postToFunctionsWithDefaultData = params =>
    postToFunctions({
      ...params,
      data: {
        fbtoken: fbTokenRef.current,
        ...params.data,
      },
    });

  const onCreditCardChange = useCallback(e => {
    if (e.error && e.error.message) {
      setCreditCardError(e.error.message);
    } else if (e.empty) {
      setCreditCardError('Card Number Is Required');
    } else {
      setCreditCardError(null);
    }
  }, []);

  const onBack = () => {
    switch (currentStep) {
      case steps.editPayment:
        if (isFromManageSubs) {
          goToManageSubs();
        } else {
          setCurrentStep(editPaymentMethodStepOpener);
          setCreditCardError(null);
          setCreditCardUpdateNote(null);
          setStripeError(null);
        }

        break;
      default:
        setCurrentStep(prvStep);
        break;
    }
  };

  const onUpdateCreditCard = () => {
    const handleCreditCardFailedUpdate = () => {
      setCreditCardUpdateNote('Credit card was not updated!');
      setCurrentStep(steps.editPayment);
    };

    const handlePartialPayment = () => {
      setCurrentStep(steps.paymentMightHaveSucceededPartially);
    };

    const handleSuccessfulRegisterPayment = async () => {
      setOutstandingPaymentDialogRenderedStep(
        setCurrentStep(steps.paymentSucceeded)
      );
    };

    stripe.createToken().then(async result => {
      if (result.error) {
        if (result.error.type !== 'validation_error') {
          handleCreditCardFailedUpdate();
        }
      } else {
        try {
          setLoaderText('Updating credit card and retrying payments...');
          setCurrentStep(steps.loader);
          const data = await postToFunctionsWithDefaultData({
            path: 'mtr-registerPaymentMethod',
            data: {
              details: result,
              userId: customerDetails.id,
              remoteAdminId: adminUserId,
            },
          });

          if (data.result === true) {
            const retryOutstandingInvoicesResponse = await postToFunctionsWithDefaultData(
              {
                path: 'mtr-retryOutstandingInvoices',
                data: {
                  userId: customerDetails.id,
                  remoteAdminId: adminUserId,
                  mtrCode: mtrCode,
                },
              }
            );

            if (retryOutstandingInvoicesResponse.totalDue === 0) {
              handleSuccessfulRegisterPayment();
            } else {
              outstandingPaymentsAmount =
                retryOutstandingInvoicesResponse.totalDue;
              await setCurrentOutstandingPaymentAmount(
                retryOutstandingInvoicesResponse.totalDue
              );
              handlePartialPayment();
            }
          } else {
            handleCreditCardFailedUpdate();
          }
        } catch (err) {
          handleCreditCardFailedUpdate();
        }
      }
    });
  };
  const retryBtnClick = () => {
    setCurrentStep(steps.editPayment);
  };

  const renderLoaderStep = () => {
    return (
      <div className={classNames(styles.loader_step, styles.slide_in_left)}>
        {loaderText && <p className={styles.loader_text}>{loaderText}</p>}
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  };

  const renderEditPaymentMethodStep = () => {
    const animationClass =
      prvStep === steps.reviewAction
        ? styles.slide_in_left
        : styles.slide_in_right;
    return (
      <div className={classNames(styles.payment_step, animationClass)}>
        <div className={styles.inputs}>
          <div className={styles.stripe_element}>
            <CardElement
              onChange={onCreditCardChange}
              onReady={element => {
                creditCardElementRef.current = element;
              }}
              style={stripeElementStyle}
              hidePostalCode
            />
            <p className={styles.error}>{creditCardError}</p>
          </div>

          {stripeError && (
            <div className={styles.stripe_error}>
              <p>{stripeError}</p>
            </div>
          )}

          {creditCardUpdateNote && (
            <div className={styles.credit_card_update_note}>
              <p>{creditCardUpdateNote}</p>
            </div>
          )}
        </div>
        <div className={styles.buttons_container}>
          <button type="button" onClick={onUpdateCreditCard}>
            <span>
              Update Credit Card and Retry Payments $
              {currentOutstandingPaymentAmount
                ? currentOutstandingPaymentAmount
                : 0}{' '}
              (USD)
            </span>
          </button>
        </div>
      </div>
    );
  };

  const renderPaymentSucceededStep = () => {
    return (
      <div className={styles.slide_in_left}>
        <GenericOutstandingPaymentsUpdatedStep
          title="Thank you!"
          subTitle="All invoices have been paid"
          isSuccess={true}
          isFromManageSubs={isFromManageSubs}
          onDoneBtnClick={onDoneBtnClickFunc}
        />
      </div>
    );
  };

  const renderPaymentPartiallySucceededStep = () => {
    return (
      <div className={styles.slide_in_left}>
        <GenericOutstandingPaymentsUpdatedStep
          title="Oops!"
          subTitle={`Seems like some invoices are still unpaid, $${currentOutstandingPaymentAmount} (USD)`}
          isSuccess={false}
          btnLbl="Retry"
          isFromManageSubs={false}
          onDoneBtnClick={retryBtnClick}
        />
      </div>
    );
  };

  const renderErrorScreen = () => {
    const errorHandler =
      errorContainer.handler ||
      (() => {
        setErrorContainer(initialErrorContainer);
        setCurrentStep(initialStep);
      });

    return (
      <div className={styles.error_screen}>
        <p className={styles.label}>Something went wrong</p>
        <p className={styles.message}>{errorContainer.msg}</p>

        <div className={styles.btn_container}>
          <button type="button" onClick={errorHandler}>
            <span>ok</span>
          </button>
        </div>
      </div>
    );
  };

  const shouldHideBackIcon =
    (!isFromManageSubs ||
      (isFromManageSubs && currentStep !== steps.editPayment)) &&
    (currentStep === initialStep ||
      currentStep === steps.loader ||
      currentStep === steps.paymentSucceeded ||
      currentStep === steps.paymentMightHaveSucceededPartially ||
      (errorContainer && errorContainer.msg !== null));
  return (
    <div className={styles.root}>
      <div className={styles.logo_container}>
        <img
          src={logoNoTextImage}
          style={{
            paddingLeft: shouldHideBackIcon ? 0 : 50,
          }}
        />
        <img src={logoOnlyTextImage} />

        <div
          className={styles.back_icon_container}
          style={{
            display: shouldHideBackIcon ? 'none' : 'initial',
          }}
        >
          <ArrowBackIcon
            style={{ color: 'inherit', fontSize: 34 }}
            onClick={onBack}
          />
        </div>
      </div>
      <div className={styles.main_divider} />
      <div className={classNames(styles.content_container)}>
        <div className={styles.content}>
          {errorContainer.msg === null ? (
            <>
              {currentStep === steps.loader &&
                wrapWithDelayedRenderer(renderLoaderStep())}
              {currentStep === steps.editPayment &&
                wrapWithDelayedRenderer(renderEditPaymentMethodStep())}
              {currentStep === steps.paymentSucceeded &&
                wrapWithDelayedRenderer(renderPaymentSucceededStep())}
              {currentStep === steps.paymentMightHaveSucceededPartially &&
                wrapWithDelayedRenderer(renderPaymentPartiallySucceededStep())}
            </>
          ) : (
            renderErrorScreen()
          )}
        </div>
      </div>
    </div>
  );
};

MTRRetryInvoicesPageBase.propTypes = exact({
  customerDetails: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  mtrCode: PropTypes.string.isRequired,
  adminUserId: PropTypes.string.isRequired,
  outstandingPaymentsAmount: PropTypes.number.isRequired,
  isFromManageSubs: PropTypes.string,
});

export const MTRRetryInvoicesPage = React.memo(
  injectStripe(MTRRetryInvoicesPageBase)
);
MTRRetryInvoicesPage.displayName = 'MTRRetryInvoicesPage';
