/* eslint-disable */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import Flag from 'react-world-flags';
import { countryFlags } from '../../../utils/utils';
import styles from './TestimonialCard.scss';

const TestimonialCardBase = ({
  testimonial,
  testimonialType,
  onUserImageClick,
  // isYoutubeDialogOpen,
  // setIsYoutubeDialogOpen,
  // setCurrentVideoDialogInfo,
  detectOS,
  // sendEvents,
  customStyles,
  /* The styles prop is expected to contain CSS rules corresponding to class names
  defined within the testimonial card component's stylesheet.
  These class names align with the styling definitions within the component's own CSS file. */
  /* Note: Custom styles are used to adjust how cards look within the same parent component,
   such as Slick or Swiper carousels. It's best to keep all cards within these contexts looking consistent.
   Special styles like "Kirk Cousins" are supported, but adding more custom styles should be done sparingly
   to keep things straightforward and easy to manage. */
}) => {
  const {
    country,
    countryIso,
    flagEmoji,
    imageUrl,
    imageWithYTlogoUrl,
    quoteText,
    userTitle,
    user,
    // youtubeEmbedVideoId,
    // youtubeVideoTitle,
    // youtubeVideoType,
  } = testimonial;

  const os = detectOS();

  // Check if the user is "Kirk Cousins"
  // NOTE: This logic may need to change if more custom card styles are introduced.
  const isKirk = user === 'Kirk Cousins';

  return (
    <div
      className={classNames(
        styles.testimonial_card,
        customStyles?.testimonial_card
      )}
    >
      <div
        className={classNames(
          styles.testimonial_card_image_container,
          customStyles?.testimonial_card_image_container,
          isKirk && customStyles?.kirks_img_container
        )}
      >
        <img
          src={testimonialType === 'video' ? imageWithYTlogoUrl : imageUrl}
          className={classNames(
            styles.testimonial_card_img,
            customStyles?.testimonial_card_img,
            isKirk && customStyles?.kirks_img
          )}
          onClick={() => {
            onUserImageClick(testimonial, testimonialType);
          }}
        />
      </div>
      <div
        className={classNames(
          styles.testimonial_card_text_container,
          customStyles?.testimonial_card_text_container
        )}
      >
        <p
          className={classNames(
            styles.testimonial_card_quote_text,
            customStyles?.testimonial_card_quote_text
          )}
        >
          {`“${quoteText}”`}
        </p>
        <div
          className={classNames(
            styles.testimonial_card_info,
            customStyles?.testimonial_card_info,
            isKirk && customStyles?.kirks_card_info
          )}
        >
          <div
            className={classNames(
              styles.testimonial_card_user_name,
              customStyles?.testimonial_card_user_name,
              isKirk && customStyles?.kirks_card_user_name
            )}
          >
            <p>{`${user}${userTitle && ','}`}&nbsp;</p>
            <p>{userTitle && `${userTitle} `}</p>
            {isKirk && testimonial.netflixLogoImageUrl && (
              <img
                src={testimonial.netflixLogoImageUrl}
                className={customStyles?.kirks_netflix_logo_img}
              />
            )}
          </div>
          <p
            className={classNames(
              styles.testimonial_card_location,
              customStyles?.testimonial_card_location
            )}
          >
            {os === 'Windows' ? (
              <Flag
                code={countryIso}
                fallback={<span>{countryIso}</span>}
                height={11}
              />
            ) : (
              countryFlags[countryIso]?.flag || flagEmoji
            )}
            &#160;
            {country}
          </p>
        </div>
      </div>
    </div>
  );
};

TestimonialCardBase.propTypes = exact({
  testimonial: PropTypes.object.isRequired,
  testimonialType: PropTypes.string,
  detectOS: PropTypes.func.isRequired,
  onUserImageClick: PropTypes.func,
  // setIsYoutubeDialogOpen: PropTypes.func,
  // setCurrentVideoDialogInfo: PropTypes.func,
  // sendEvents: PropTypes.func,
  customStyles: PropTypes.object,
});

TestimonialCardBase.defaultProps = {
  onUserImageClick: () => {},
  // setIsYoutubeDialogOpen: () => {},
  // setCurrentVideoDialogInfo: () => {},
  // sendEvents: () => {},
  customStyles: {},
  testimonialType: 'video',
};
export const TestimonialCard = memo(TestimonialCardBase);
TestimonialCard.displayName = 'TestimonialCard';
