import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog } from '@material-ui/core';
import styles from './AccountReactivatedSuccessfullyDialog.scss';
import checkedImage from '../../../../../myndlift-signup/public/assets/checked.png';

const AccountReactivatedSuccessfullyDialogBase = ({ isOpen, onDone }) => {
  return (
    <Dialog open={isOpen} classes={{ paper: styles.dialog_paper }}>
      <div className={styles.content}>
        <img src={checkedImage} />
        <p className={styles.label}>Your account is reactivated</p>
        <p className={styles.description}>Enjoy your training!</p>
        <div className={styles.btn_container}>
          <button onClick={onDone} type="button">
            <span>Done</span>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

AccountReactivatedSuccessfullyDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onDone: PropTypes.func.isRequired,
});

export const AccountReactivatedSuccessfullyDialog = React.memo(
  AccountReactivatedSuccessfullyDialogBase
);
AccountReactivatedSuccessfullyDialog.displayName =
  'AccountReactivatedSuccessfullyDialog';
