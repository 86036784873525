import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { MTRDeactivation } from './MTRDeactivation';
import {
  getCurrentURLParams,
  notifyAppThatComponentIsLoaded,
  postToFunctions,
} from '../../utils/utils';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';

const MTRDeactivationContainerBase = ({ onComponentReadyToRender }) => {
  const { customerId, mtrCode, adminUserId, fbtoken } = getCurrentURLParams();

  const [groupId, setGroupId] = useState(null);
  const [isComponentReadyToRender, setIsComponentReadyToRender] = useState(
    null
  );

  const [plans] = useFirebaseDB({
    path: `remoteBilling/customPlans/${groupId}`,
    onceListener: true,
    errorListener: groupId ? undefined : () => {},
  });

  const [postMembershipPlan] = useFirebaseDB({
    path: `remoteBilling/customPlans/${groupId}/postMembershipPlan`,
    onceListener: true,
    errorListener: groupId ? undefined : () => {},
  });

  const [currentPlan, setCurrentPlan] = useState(null);

  useEffect(() => {
    if (!!plans && !!groupId && !!currentPlan) {
      if (!isComponentReadyToRender) {
        setIsComponentReadyToRender(true);
        notifyAppThatComponentIsLoaded();
      }
    }
  }, [currentPlan, groupId, isComponentReadyToRender, plans]);

  // useEffect(() => {
  //   if (plans) {
  //     onComponentReadyToRender();
  //   }
  // }, [plans, onComponentReadyToRender]);

  useEffect(() => {
    (async () => {
      const data = await postToFunctions({
        path: 'mtr-subscribedPlanDetails',
        data: {
          userId: customerId,
          mtrCode,
          fbtoken,
        },
      });
      setCurrentPlan(data[mtrCode]);
      setGroupId(data[mtrCode].groupId);
    })();
  }, [customerId, fbtoken, mtrCode]);

  return isComponentReadyToRender ? (
    <StripeProvider
      stripe={window.stripe}
      key={window.stripe ? 'stripe' : 'null'}
    >
      <Elements>
        <MTRDeactivation
          // customerDetails={{
          //   name: `${customerInfo.firstName} ${customerInfo.lastName}`,
          //   gender: customerInfo.privateDetails.gender,
          //   email: customerInfo.email,
          //   id: customerId,
          // }}
          adminUserId={adminUserId}
          currentPlan={currentPlan}
          plans={plans}
          mtrCode={mtrCode}
          // stripe={window.stripe}
          // currentCardInfo={currentCardInfo}
          // handleCardInfoUpdate={handleCardInfoUpdate}
          // adminEmail={adminEmail}
          shouldRenderNewPlans={plans.groupType === 'membership'}
          postMembershipPlan={postMembershipPlan}
          // cancelationDiscount={cancelationDiscount}
          onComponentReadyToRender={onComponentReadyToRender}
          // fbtoken={fbtoken}
        />
      </Elements>
    </StripeProvider>
  ) : null;
};

MTRDeactivationContainerBase.propTypes = exact({
  onComponentReadyToRender: PropTypes.func.isRequired,
});

export const MTRDeactivationContainer = React.memo(
  MTRDeactivationContainerBase
);
MTRDeactivationContainer.displayName = 'MTRDeactivationContainer';
