import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import styles from './CustomBtn.scss';

const CustomBtnBase = ({ onClick, children, isInverted = false }) => {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.root, { [styles.inverted]: isInverted })}
    >
      {children}
    </div>
  );
};

CustomBtnBase.propTypes = exact({
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isInverted: PropTypes.bool,
});

export const CustomBtn = React.memo(CustomBtnBase);
CustomBtn.displayName = 'CustomBtn';
