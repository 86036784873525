import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
// import nth from 'lodash/nth';
import exact from 'prop-types-exact';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { SuccessfulPayment } from './SuccessfulPayment/SuccessfulPayment';
import { RegistrationFlowPage } from '../RegistrationFlowPage/RegistrationFlowPage';
import { MTRSubscriptionPageContainer } from './MTRSubscriptionPageContainer';
import { getCurrentURLParams, getEventLogger } from '../../utils/utils';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
// import { getPlanPriceByWeek } from '../../models/mtr-subscription/mtr-subscription';
// import { IntroPageContainer } from './IntroPage/IntroPageContainer';

const logMTRSubscriptionAppEvent = getEventLogger({
  prefix: 'mtr_subscription_app',
});

const MTRSubscriptionAppBase = ({ onComponentReadyToRender }) => {
  const { groupId } = getCurrentURLParams();
  const [defaultGroupId] = useFirebaseDB({
    path: 'config/remoteDefaultGroupId',
    onceListener: true,
  });

  const [isComponentReadyToRender, setIsComponentReadyToRender] = useState(
    false
  );

  const plansErrorListener = useCallback(
    err => {
      if (groupId) {
        throw err;
      }
    },
    [groupId]
  );

  useEffect(() => {
    if (defaultGroupId) {
      setIsComponentReadyToRender(true);
    }
  }, [defaultGroupId]);

  // const defaultGroupId = '-NC-PYCyyKTAS48sD3x4';

  const [plans] = useFirebaseDB({
    path: `remoteBilling/customPlans/${groupId || defaultGroupId}`,
    errorListener: plansErrorListener,
  });

  return (
    <Router>
      <Switch>
        <Route
          path="/successful-payment"
          render={() => {
            if (!plans || !isComponentReadyToRender) {
              return null;
            }
            // eslint-disable-next-line no-shadow
            const { userId, activationCode, groupId } = getCurrentURLParams();
            // const lastPlan = nth(plans.familyMemberPlans.planOptions, -1);
            // const familyPriceStartingAt = getPlanPriceByWeek({
            //   price: lastPlan.price,
            //   billingPeriodInMonths: lastPlan.billingPeriodInMonths,
            // });
            return (
              <SuccessfulPayment
                // onVisitBlog={async () => {
                //   logMTRSubscriptionAppEvent({ name: 'on_visit_blog' });
                //   window.open(
                //     'https://www.myndlift.com/neurofeedback-blog',
                //     '_self'
                //   );
                // }}
                onAddMoreUser={async () => {
                  // event might be lost but still better than slow user experience
                  logMTRSubscriptionAppEvent({
                    name: 'on_add_family_member',
                  });
                  window.open(
                    `${
                      window.location.origin
                    }?userId=${userId}&utm_source=dashboard&groupId=${groupId ||
                      defaultGroupId}`,
                    '_self'
                  );
                }}
                // isFamilyMember={!!userId}
                hasOnlySoftwarePackage={!!userId}
                // moreUserPrice={familyPriceStartingAt}
                onComponentReadyToRender={onComponentReadyToRender}
                activationCode={activationCode}
              />
            );
          }}
        />

        <Route
          path="/remote-payment-page.html"
          render={() => {
            if (!isComponentReadyToRender) {
              return null;
            }
            return (
              <MTRSubscriptionPageContainer
                onComponentReadyToRender={onComponentReadyToRender}
                remoteDefaultGroupId={defaultGroupId}
              />
            );
          }}
        />

        <Route
          path="/subscription"
          render={() => {
            if (!isComponentReadyToRender) {
              return null;
            }
            return (
              <MTRSubscriptionPageContainer
                onComponentReadyToRender={onComponentReadyToRender}
                remoteDefaultGroupId={defaultGroupId}
              />
            );
          }}
        />
        {/* </Route> */}

        <Route
          path="/"
          render={() => {
            if (!isComponentReadyToRender) {
              return null;
            }
            const url = window.location.href;
            // eslint-disable-next-line no-useless-escape
            const match = url.match(/\/([^\/]+)$/);
            const deepLinkCouponCode = match ? match[1] : null;
            if (deepLinkCouponCode) {
              // eslint-disable-next-line no-return-assign
              return (
                <RegistrationFlowPage
                  onComponentReadyToRender={
                    (document.getElementById('loader-container').style.display =
                      'none')
                  }
                  deepLinkCouponCode={deepLinkCouponCode}
                />
              );
            }
            return (
              <MTRSubscriptionPageContainer
                onComponentReadyToRender={onComponentReadyToRender}
                remoteDefaultGroupId={defaultGroupId}
                deepLinkCouponCode={deepLinkCouponCode}
              />
            );
          }}
        />
      </Switch>
    </Router>
  );
};

MTRSubscriptionAppBase.propTypes = exact({
  onComponentReadyToRender: PropTypes.func.isRequired,
});

export const MTRSubscriptionApp = React.memo(MTRSubscriptionAppBase);
MTRSubscriptionApp.displayName = 'MTRSubscriptionApp';
