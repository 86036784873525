import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog } from '@material-ui/core';
import styles from './FailedPaymentDialog.scss';
import failedImage from '../../../../../myndlift-signup/public/assets/failed.png';

const FailedPaymentDialogBase = ({ isOpen, onTryAgain }) => {
  return (
    <Dialog open={isOpen} classes={{ paper: styles.dialog_paper }}>
      <div className={styles.content}>
        <img src={failedImage} />
        <p className={styles.payment_failed_label}>Payment failed</p>
        <p className={styles.payment_failed_description}>
          {isOpen !== true ? (
            <div>
              {isOpen === 'Payment failed'
                ? 'Unfortunately, we couldn’t process your payment'
                : isOpen}
            </div>
          ) : null}
        </p>
        <div className={styles.btn_container}>
          <button onClick={onTryAgain} type="button">
            <span>TRY AGAIN</span>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

FailedPaymentDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onTryAgain: PropTypes.func.isRequired,
});

export const FailedPaymentDialog = React.memo(FailedPaymentDialogBase);
FailedPaymentDialog.displayName = 'FailedPaymentDialog';
