import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

const DelayedRendererBase = ({ delay, children }) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setTimeout(() => setShouldRender(true), delay);
  });
  return shouldRender ? <>{children}</> : null;
};

DelayedRendererBase.propTypes = exact({
  delay: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
});

export const DelayedRenderer = React.memo(DelayedRendererBase);
DelayedRenderer.displayName = 'DelayedRenderer';
