import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { MTRManageSubscriptionPage } from './MTRManageSubscriptionPage';
import {
  getCurrentURLParams,
  notifyAppThatComponentIsLoaded,
  postToFunctions,
  // postToNode,
} from '../../utils/utils';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';

const MTRMangeSubscriptionPageContainerBase = ({
  onComponentReadyToRender,
}) => {
  const { customerId, mtrCode, adminUserId, fbtoken } = getCurrentURLParams();

  const [currentCardInfo, setCurrentCardInfo] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [isComponentReadyToRender, setIsComponentReadyToRender] = useState(
    null
  );

  const [adminEmail] = useFirebaseDB({
    path: `remoteAdminUsers/${adminUserId}/email`,
    onceListener: true,
  });

  const [customerInfo] = useFirebaseDB({
    path: `userInfo/${customerId}`,
    onceListener: true,
  });

  // const [defaultGroupId] = useFirebaseDB({
  //   path: 'config/remoteDefaultGroupId',
  //   onceListener: true,
  // });

  const [plans] = useFirebaseDB({
    path: `remoteBilling/customPlans/${groupId}`,
    onceListener: true,
    errorListener: groupId ? undefined : () => {},
  });

  const [postMembershipPlan] = useFirebaseDB({
    path: `remoteBilling/customPlans/${groupId}/postMembershipPlan`,
    onceListener: true,
    errorListener: groupId ? undefined : () => {},
  });

  const [cancelationDiscount] = useFirebaseDB({
    path: 'remoteUsersConfig/specialCoupons/deactivationAlternativeDiscount',
    onceListener: true,
  });

  const [currentPlan, setCurrentPlan] = useState(null);

  useEffect(() => {
    if (
      !!plans &&
      !!customerInfo &&
      !!groupId &&
      !!currentPlan &&
      // !!currentCardInfo &&
      !!adminEmail
    ) {
      if (!isComponentReadyToRender) {
        setIsComponentReadyToRender(true);
        notifyAppThatComponentIsLoaded();
      }
    }
  }, [
    adminEmail,
    currentPlan,
    customerInfo,
    groupId,
    isComponentReadyToRender,
    // currentCardInfo,
    plans,
  ]);

  const updateCurrentCardInfo = useCallback(async () => {
    try {
      const { cardInfo } = await postToFunctions({
        path: 'billing-paymentSourceInfo',
        data: {
          userId: adminUserId,
          fbtoken,
        },
      });
      setCurrentCardInfo(cardInfo);
    } catch (e) {
      setCurrentCardInfo(null);
    }
  }, [adminUserId, fbtoken]);

  const handleCardInfoUpdate = useCallback(() => {
    return updateCurrentCardInfo();
  }, [updateCurrentCardInfo]);

  useEffect(() => {
    updateCurrentCardInfo();
  }, [updateCurrentCardInfo]);

  useEffect(() => {
    if (plans) {
      onComponentReadyToRender();
    }
  }, [plans, onComponentReadyToRender]);

  useEffect(() => {
    (async () => {
      const data = await postToFunctions({
        path: 'mtr-subscribedPlanDetails',
        data: {
          userId: customerId,
          mtrCode,
          fbtoken,
        },
      });
      setCurrentPlan(data[mtrCode]);
      setGroupId(data[mtrCode].groupId);
    })();
  }, [customerId, fbtoken, mtrCode]);

  return isComponentReadyToRender ? (
    <StripeProvider
      stripe={window.stripe}
      key={window.stripe ? 'stripe' : 'null'}
    >
      <Elements>
        <MTRManageSubscriptionPage
          customerDetails={{
            name: `${customerInfo.firstName} ${customerInfo.lastName}`,
            gender: customerInfo.privateDetails.gender,
            email: customerInfo.email,
            id: customerId,
          }}
          adminUserId={adminUserId}
          currentPlan={currentPlan}
          plans={plans}
          mtrCode={mtrCode}
          stripe={window.stripe}
          currentCardInfo={currentCardInfo}
          handleCardInfoUpdate={handleCardInfoUpdate}
          adminEmail={adminEmail}
          shouldRenderNewPlans={plans.groupType === 'membership'}
          postMembershipPlan={postMembershipPlan}
          cancelationDiscount={cancelationDiscount}
        />
      </Elements>
    </StripeProvider>
  ) : null;
};

MTRMangeSubscriptionPageContainerBase.propTypes = exact({
  onComponentReadyToRender: PropTypes.func.isRequired,
});

export const MTRMangeSubscriptionPageContainer = React.memo(
  MTRMangeSubscriptionPageContainerBase
);
MTRMangeSubscriptionPageContainer.displayName =
  'MTRMangeSubscriptionPageContainer';
