import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './MuseDialog.scss';
import museImage from '../../../../../myndlift-signup/public/assets/muse_popup.png';

const MuseDialogBase = ({ isOpen, onClose, hideMuseHeadset }) => {
  return (
    <Dialog
      open={isOpen}
      classes={{ paper: styles.dialog_paper }}
      maxWidth="md"
    >
      <div className={styles.icon_continer}>
        <CloseIcon
          style={{
            cursor: 'pointer',
            color: '#808080',
            marginTop: 10,
            marginRight: 10,
          }}
          onClick={onClose}
        />
      </div>
      <div className={styles.content_continer}>
        <div className={styles.content_title}>What's a Muse headband?</div>
        <img src={museImage} className={styles.popup_img} />
        <div style={{ marginBottom: 20 }}>
          The Muse headband is designed to measure your brain activity. It's
          essential to train with Myndlift, which supports the Original Muse
          (2016 model: MU-02), Muse 2 (model: MU-03), and Muse S.
        </div>
        <div style={{ marginBottom: 15, fontWeight: 900 }}>
          Why isn’t it included in the Myndlift Kit?
        </div>
        <div>
          <div>
            Since Muse is widely available, we figured there’s a chance that you
            might already have one.
          </div>
          <div>
            If you don’t, you can purchase one separately
            {hideMuseHeadset
              ? '.'
              : ' or opt for a Muse 2 through Myndlift after choosing a plan.'}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

MuseDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hideMuseHeadset: PropTypes.bool,
});

export const MuseDialog = React.memo(MuseDialogBase);
MuseDialog.displayName = 'MuseDialog';
