import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { CustomBtn } from '../CustomBtn/CustomBtn';
import { notifyAppThatUserHasRegistered } from '../../../utils/utils';
import styles from './SuccessfulPayment.scss';
import postCheckoutImage from '../../../../../myndlift-signup/public/assets/post-checkout.gif';
import TwoPeopleImage from '../../../../../myndlift-signup/public/assets/2-people-headset.jpg';
import postCheckoutOnlySoftwarePackageImage from '../../../../../myndlift-signup/public/assets/package-only.gif';

const SuccessfulPaymentBase = ({
  // onVisitBlog,
  hasOnlySoftwarePackage,
  onAddMoreUser,
  onComponentReadyToRender,
  activationCode,
}) => {
  const wasComponentReadyToRenderFuncCalledRef = useRef(false);
  if (!wasComponentReadyToRenderFuncCalledRef.current) {
    onComponentReadyToRender();
    notifyAppThatUserHasRegistered(activationCode);
    wasComponentReadyToRenderFuncCalledRef.current = true;
  }

  return (
    <div className={styles.root}>
      <div className={styles.img_container}>
        <img
          src={
            hasOnlySoftwarePackage
              ? postCheckoutOnlySoftwarePackageImage
              : postCheckoutImage
          }
          className={styles.img_gif}
        />
      </div>
      <h2>We’re excited to have you onboard, {sessionStorage.newUserName}</h2>
      <div className={styles.text_border}>
        <p
          className={styles.text_container}
          style={{ borderBottom: '1px #0000001c solid' }}
        >
          <div style={{ fontSize: 18, fontWeight: 900, marginBottom: 20 }}>
            What's next?
          </div>
          You will receive an email with an activation code to create your
          account.
        </p>
        <p style={{ marginBottom: 0 }}>
          <img src={TwoPeopleImage} className={styles.img_style} />
        </p>

        <p className={styles.text_container} style={{ paddingTop: 0 }}>
          <div style={{ fontWeight: 900, marginBottom: 20 }}>
            Hurray! You’ve just unlocked 37% OFF on family members.
          </div>
          <div>
            P.S. You can share the same hardware but have different accounts.
          </div>
          <div className={styles.btn_container} style={{ paddingBottom: 0 }}>
            <CustomBtn onClick={onAddMoreUser}>
              <span style={{ paddingLeft: 32, paddingRight: 32 }}>
                add a member
              </span>
            </CustomBtn>
          </div>
        </p>
      </div>

      <p>
        <span role="img" aria-label="Statuscope">
          🩺
        </span>{' '}
        This order might be eligible for HSA/FSA reimbursement.{' '}
        <a href="https://app.truemed.com/qualify/tm_qual_5y4zi04w78?utm=post_purchase">
          Get Reimbursed
        </a>
        .
      </p>
    </div>
  );
};

SuccessfulPaymentBase.propTypes = exact({
  // onVisitBlog: PropTypes.func.isRequired,
  hasOnlySoftwarePackage: PropTypes.bool.isRequired,
  onAddMoreUser: PropTypes.func.isRequired,
  onComponentReadyToRender: PropTypes.func.isRequired,
  activationCode: PropTypes.string.isRequired,
});

export const SuccessfulPayment = React.memo(SuccessfulPaymentBase);
SuccessfulPayment.displayName = 'SuccessfulPayment';
