import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './AgeDialog.scss';

const AgeDialogBase = ({ isOpen, onClose }) => {
  return (
    <Dialog
      open={isOpen}
      classes={{ paper: styles.dialog_paper }}
      maxWidth="md"
    >
      <div className={styles.icon_continer}>
        <CloseIcon
          style={{
            cursor: 'pointer',
            color: '#808080',
            marginTop: 20,
            marginRight: 30,
          }}
          onClick={onClose}
        />
      </div>
      <div className={styles.content_continer}>
        <div className={styles.text_content}>
          Unfortunately, the program does not support children under the age of
          13.
        </div>
        <div className={styles.text_content}>
          Good news: you can still try to get access to Myndlift through one of
          our local providers:
        </div>
        <div className={styles.text_content} style={{ marginTop: 10 }}>
          <a href="https://dashboard.myndlift.com/providers-search.html">
            Provider directory
          </a>
        </div>
      </div>
    </Dialog>
  );
};

AgeDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
});

export const AgeDialog = React.memo(AgeDialogBase);
AgeDialog.displayName = 'AgeDialog';
