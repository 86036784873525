import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { RemotePaymentPage } from './RemotePaymentPage';

const initialValue = Symbol('iv');
const RemotePaymentPageContainerBase = ({
  scriptsLoadedSuccessfully,
  onComponentReadyToRender,
  ...props
}) => {
  // eslint-disable-next-line no-use-before-define
  const environmentConfig =
    window.environmentConfig[window.environmentConfig.current];
  // const nodeURL = environmentConfig.appengine.node;
  const funcURL = environmentConfig.functions;

  const [stripe, setStripe] = useState(null);
  const [database, setDatabase] = useState(null);
  const [programWithHeadsetPrice, setProgramWithHeadsetPrice] = useState(null);
  const [
    programWithHeadsetMonthlyPrice,
    setProgramWithHeadsetMonthlyPrice,
  ] = useState(null);
  const [programWithoutHeadsetPrice, setProgramWithoutHeadsetPrice] = useState(
    null
  );
  const [
    programWithoutHeadsetMonthlyPrice,
    setProgramWithoutHeadsetMonthlyPrice,
  ] = useState(null);
  const [programForFamilyPrice, setProgramForFamilyPrice] = useState(null);
  const [additionalSessionPrice, setAdditionalSessionPrice] = useState(null);
  const [discountCoupon, setDiscountCoupon] = useState(initialValue);
  const [discountCouponAmount, setDiscountCouponAmount] = useState(
    initialValue
  );
  const [faqs, setFaqs] = useState(null);

  const isStripeInitializedRef = useRef(false);
  const isFirebaseInitializedRef = useRef(false);

  useEffect(() => {
    const setStripeAndFirebaseIfTheyAreReady = () => {
      if (window.Stripe && isStripeInitializedRef.current === false) {
        isStripeInitializedRef.current = true;
        setStripe(window.Stripe(environmentConfig.stripePublicKey));
      }

      if (
        window.firebase &&
        window.firebase.database &&
        isFirebaseInitializedRef.current === false
      ) {
        isFirebaseInitializedRef.current = true;
        // eslint-disable-next-line no-undef
        firebase.initializeApp(environmentConfig.firebase);
        // eslint-disable-next-line no-undef
        setDatabase(firebase.database());
      }
    };
    setStripeAndFirebaseIfTheyAreReady();
    const onScriptLoad = () => {
      setStripeAndFirebaseIfTheyAreReady();
    };

    const registeredCallbacks = [];
    Array.from(document.scripts).forEach(script => {
      registeredCallbacks.push([
        script,
        script.addEventListener('load', onScriptLoad),
      ]);
    });

    return () => {
      registeredCallbacks.map(([script, cb]) =>
        script.removeEventListener('load', cb)
      );
      window.removeEventListener('load', onScriptLoad);
    };
  }, [environmentConfig]);

  useEffect(() => {
    if (database !== null) {
      setProgramWithHeadsetPrice(889);
      setProgramWithHeadsetMonthlyPrice(325);

      database
        .ref('remoteUsersConfig/bundles/bundleWithoutHeadset/price')
        .once('value', snapshot =>
          setProgramWithoutHeadsetPrice(snapshot.val())
        );

      database
        .ref('remoteUsersConfig/bundles/bundleWithoutHeadset/monthlyPrice')
        .once('value', snapshot =>
          setProgramWithoutHeadsetMonthlyPrice(snapshot.val())
        );

      database
        .ref('remoteUsersConfig/bundles/bundleForFamily/price')
        .once('value', snapshot => setProgramForFamilyPrice(snapshot.val()));

      database
        .ref('FAQ/old-remote-billing')
        .once('value', snapshot => setFaqs(snapshot.val()));

      database
        .ref('remoteUsersConfig/discountCoupon')
        .once('value')
        .then(snapshot => setDiscountCoupon(snapshot.val()));

      database
        .ref('remoteUsersConfig/additionalCallsCost')
        .once('value')
        .then(snapshot => setAdditionalSessionPrice(snapshot.val()));
    }
  }, [database]);

  useEffect(() => {
    (async () => {
      if (discountCoupon !== initialValue) {
        if (discountCoupon) {
          const discountCouponResponse = await window.fetch(
            `${funcURL}shop-myndliftCouponDiscount`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json; charset=utf-8',
              },
              body: JSON.stringify({
                couponCode: discountCoupon,
              }),
            }
          );

          const discountCouponResponseData = await discountCouponResponse.json();
          const discountAmount =
            discountCouponResponseData &&
            discountCouponResponseData.result === true
              ? discountCouponResponseData.discountAmount
              : 0;
          setDiscountCouponAmount(discountAmount);
        } else {
          setDiscountCouponAmount(0);
        }

        onComponentReadyToRender();
      }
    })();
  }, [discountCoupon, funcURL, onComponentReadyToRender]);

  const programPrices =
    programWithHeadsetPrice &&
    programWithHeadsetMonthlyPrice &&
    programWithoutHeadsetPrice &&
    programWithoutHeadsetMonthlyPrice &&
    programForFamilyPrice
      ? {
          withHeadset: {
            price: programWithHeadsetPrice,
            monthlyPrice: programWithHeadsetMonthlyPrice,
          },
          withoutHeadset: {
            price: programWithoutHeadsetPrice,
            monthlyPrice: programWithoutHeadsetMonthlyPrice,
          },
          family: {
            price: programForFamilyPrice,
          },
        }
      : null;

  return (
    discountCouponAmount !== initialValue &&
    programPrices !== null && (
      <StripeProvider stripe={stripe} key={stripe ? 'stripe' : 'null'}>
        <Elements>
          <RemotePaymentPage
            {...props}
            // nodeURL={nodeURL}
            programPrices={programPrices}
            faqs={faqs}
            discountCoupon={discountCoupon}
            discountCouponAmount={discountCouponAmount}
            additionalSessionPrice={additionalSessionPrice}
            funcURL={funcURL}
          />
        </Elements>
      </StripeProvider>
    )
  );
};

RemotePaymentPageContainerBase.propTypes = exact({
  scriptsLoadedSuccessfully: PropTypes.bool.isRequired,
  onComponentReadyToRender: PropTypes.func.isRequired,
});

export const RemotePaymentPageContainer = React.memo(
  RemotePaymentPageContainerBase
);
RemotePaymentPageContainer.displayName = 'RemotePaymentPageContainer';
