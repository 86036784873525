import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import mapValues from 'lodash/mapValues';
import { injectStripe } from 'react-stripe-elements';
import ModalVideo from 'react-modal-video';
import Swal from 'sweetalert2';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { FAQS } from './FAQS/FAQS';
import { CustomBtn } from './CustomBtn/CustomBtn';
import styles from './RemotePaymentPage.scss';
import packageDescriptionImage from '../../../../myndlift-signup/public/assets/img-novidbtn.png';
import playIcon from '../../../../myndlift-signup/public/assets/play_icon.png';
import includedAppImage from "../../../../myndlift-signup/public/assets/what's-included-app.png";
import includedCaseImage from "../../../../myndlift-signup/public/assets/what's-included-case.png";
import includedClinicianImage from "../../../../myndlift-signup/public/assets/what's-included-clinician.png";
import includedElectrodeImage from "../../../../myndlift-signup/public/assets/what's-included-electrode.png";
import includedMuseImage from "../../../../myndlift-signup/public/assets/what's-included-muse.png";
import includedPasteImage from "../../../../myndlift-signup/public/assets/what's-included-paste.png";
import logoImage from '../../../../myndlift-signup/public/assets/logo-horizontal.png';
import moneyBackGuaranteeImage from '../../../../myndlift-signup/public/assets/money-back-guarantee-icon.png';
import { CheckoutForm } from './CheckoutForm/CheckoutForm';
import { Loader } from '../../Core/Components/Loader/Loader';
import { SuccessfulPayment } from './SuccessfulPayment/SuccessfulPayment';
import { scrollTo, defensiveThrow } from '../../utils/utils';

const environmentConfig =
  window.environmentConfig[window.environmentConfig.current];

const shouldReportErrorsToSentry = !(
  environmentConfig.isDev || window.location.hostname === 'localhost'
);

try {
  Sentry.onLoad(() => {
    Sentry.init({
      integrations: shouldReportErrorsToSentry
        ? []
        : [
            new Sentry.Integrations.Breadcrumbs({
              console: false,
            }),
          ],
      beforeSend(event) {
        if (shouldReportErrorsToSentry) {
          return event;
        }
        return null;
      },
    });
  });
} catch (e) {
  // console.warn(e);
}

const isValidEmail = email =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

const paymentMethods = {
  oneTimePayment: 'PAY WITH ONE-PAYMENT PLAN',
  threeTimesPayment: 'PAY WITH THREE-PAYMENT PLAN',
};

const urlSearchParams = new URLSearchParams(window.location.search);
const upgradeFromApp = urlSearchParams.get('upgradeFromApp');
const initialEmail = urlSearchParams.get('email');
const userIdUrlParameter = urlSearchParams.get('userId');
const optionalUserIdUrlParameter = urlSearchParams.get('optionalUserId');
const shouldShowJustShippingForm = urlSearchParams.get('cta') === 'none';
const hasCustomerPaidSuccessfullyUrlParameter =
  urlSearchParams.get('hp') === 'true';
const utmSource = urlSearchParams.get('utm_source');
const utmCampaign = urlSearchParams.get('utm_campaign');
const couponCodeUrl = urlSearchParams.get('couponCode');

const museOptions = {
  compatibleMuse: 'I own an Original Muse or Muse 2 with one USB port',
  muse1TwoUsbPorts: 'I own an Original Muse with two USB ports',
  iWillBuyMuseMyself: 'I will buy a compatible Muse separately',
};

const sendEvents = async (eventName, params = {}) =>
  // eslint-disable-next-line no-undef
  fetch(`${environmentConfig.functions}mtr-leadsFlowHelper`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      eventName,
      eventDetails: {
        ...params,
        ...(sessionStorage.eligibilityId
          ? { eligibilityId: sessionStorage.eligibilityId }
          : {}),
        utmSource: utmSource || '',
        utmCampaign: utmCampaign || '',
        questions:
          (sessionStorage.prettyQuestions &&
            JSON.parse(sessionStorage.prettyQuestions)) ||
          {},
      },
    }),
  });

const initialCouponDiscount = {
  type: 'initialCouponDiscount',
  amount: 0,
};

const RemotePaymentPageBase = ({
  stripe,
  programPrices,
  faqs,
  discountCouponAmount,
  discountCoupon,
  additionalSessionPrice,
  funcURL,
}) => {
  const [filteredFaqs, setFilteredFaqs] = useState(null);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isBillingInfoDisplayed, setIsBillingInfoDisplayed] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [userId, setUserId] = useState(userIdUrlParameter);
  const userIdRef = useRef();

  const [isCovidDialogOpen, setIsCovidDialogOpen] = useState(false);

  const formRef = useRef();
  const checkoutFormRef = useRef();

  const onVideoOpen = useCallback(() => setIsVideoOpen(true), []);

  const [creditCardNumberError, setCreditCardNumberError] = useState(null);
  const [creditCardExpiryError, setCreditCardExpiryError] = useState(null);
  const [creditCardCVCError, setCreditCardCVCError] = useState(null);

  const [compatibleMuseText, setCompatibleMuseText] = useState(null);
  const [compatibleMuseTextColor, setCompatibleMuseTextColor] = useState(null);
  const [selectedMuseOption, setSelectedMuseOption] = useState(
    shouldShowJustShippingForm
      ? museOptions.yes
      : museOptions.iWillBuyMuseMyself
  );

  const [isCouponCodeBtnLoading, setIsCouponCodeBtnLoading] = useState(false);
  const [couponDiscount, setCouponDiscount] = useState(initialCouponDiscount);
  const [couponCodeError, setCouponCodeError] = useState(null);
  const [couponCode, setCouponCode] = useState(
    couponCodeUrl ||
      (!userId && discountCouponAmount > 0 && discountCoupon) ||
      ''
  );
  const onCouponCodeChange = useCallback(
    event => setCouponCode(event.target.value.trim()),
    []
  );

  const getCompatibleMuseDiscount = useCallback(
    () => programPrices.withHeadset.price - programPrices.withoutHeadset.price,
    [programPrices]
  );

  useEffect(() => {
    if (programPrices !== null && shouldShowJustShippingForm) {
      // setCompatibleMuseText(
      //   `We’ll include a Muse in your package. If you’d like to buy one separately and enjoy the $${getCompatibleMuseDiscount()} discount, select ‘No’.`
      // );
      setCompatibleMuseTextColor('#696a71');
    }
  }, [
    programPrices,
    setSelectedMuseOption,
    setCompatibleMuseTextColor,
    getCompatibleMuseDiscount,
  ]);

  const onSelectedMuseOptionChange = useCallback(
    (event, newValue) => {
      setSelectedMuseOption(newValue);
      if (newValue === museOptions.yes) {
        setCompatibleMuseText(
          `We’ll include a Muse in your package. If you’d like to buy one separately and enjoy the $${getCompatibleMuseDiscount()} discount, select ‘No’.`
        );
        setCompatibleMuseTextColor('#696a71');
      }
      if (newValue === museOptions.no) {
        setCompatibleMuseText(
          `Great. We'll add a $${getCompatibleMuseDiscount()} discount to your order and won't include a Muse. Keep in mind that your friend/family member needs to have a compatible headset (The Original Muse or Muse 2) to train.`
        );
        setCompatibleMuseTextColor('#24C7B6');
      }

      if (newValue === museOptions.muse1TwoUsbPorts) {
        setCompatibleMuseText(
          <span>
            Unfortunately, your Muse is not compatible. Please buy a compatible
            Muse separately in order to use Myndlift and choose the next option.{' '}
            <a
              href="https://www.amazon.com/Muse-2-Brain-Sensing-Headband/dp/B07HL2S9JQ/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: 'inherit',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              You can buy it from here
            </a>
            .
          </span>
        );
        setCompatibleMuseTextColor('#696a71');
      } else if (newValue === museOptions.iWillBuyMuseMyself) {
        setCompatibleMuseText(
          <span style={{ color: 'inherit' }}>
            Perfect. We will ship you the rest of the Myndlift kit! Please
            proceed to shipping info.
          </span>
        );
        setCompatibleMuseTextColor('#24C7B6');
      } else if (newValue === museOptions.noMuse) {
        setCompatibleMuseText(
          <span>
            Please buy a compatible Muse separately in order to use Myndlift and
            choose the previous option.{' '}
            <a
              href="https://www.amazon.com/Muse-2-Brain-Sensing-Headband/dp/B07HL2S9JQ/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: 'inherit',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              You can buy it from here
            </a>
            .
          </span>
        );
        setCompatibleMuseTextColor('#696a71');
      } else if (newValue === museOptions.compatibleMuse) {
        setCompatibleMuseText(
          <span style={{ color: 'inherit' }}>
            Perfect. You have a compatible Muse. We will ship you the rest of
            the Myndlift kit! Please proceed to shipping info.
          </span>
        );
        setCompatibleMuseTextColor('#24C7B6');
      }
    },
    [getCompatibleMuseDiscount]
  );

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentMethods.oneTimePayment
  );
  const onSelectedPaymentMethodChange = useCallback((event, newValue) => {
    setSelectedPaymentMethod(newValue);
  }, []);

  const [shouldShowLoader, setShouldShowLoader] = useState(false);
  const [
    hasCustomerPaidSuccessfully,
    setHasCustomerPaidSuccessfully,
  ] = useState(hasCustomerPaidSuccessfullyUrlParameter);

  const hasCompatibleHeadset =
    selectedMuseOption === museOptions.compatibleMuse ||
    selectedMuseOption === museOptions.iWillBuyMuseMyself ||
    selectedMuseOption === museOptions.muse1TwoUsbPorts ||
    selectedMuseOption === museOptions.no;

  const [stripeError, setStripeError] = useState(null);

  const handleFailedPayment = () =>
    Swal.fire({
      title: 'Payment failed',
      text: 'Your payment has failed',
      type: 'error',
      showCancelButton: false,
      confirmButtonText: 'Try again',
    }).then(() => {});

  const { register, handleSubmit, setValue, watch, errors } = useForm({
    defaultValues: {
      'Email Billing': initialEmail || ' ',
      // a hack to make sure first name last name fields does not have their label
      // while the value is being set by react-form-hook
      'First Name Billing': ' ',
      'Last Name Billing': ' ',
    },
  });

  const firstName = watch('First Name');
  const [prvFirstName, setPrvFirstName] = useState(firstName);
  const lastName = watch('Last Name');
  const [prvLastName, setPrvLastName] = useState(lastName);

  useEffect(() => {
    if (!isBillingInfoDisplayed) {
      if (firstName !== prvFirstName) {
        setPrvFirstName(firstName);
        setValue('First Name Billing', firstName);
      }
      if (lastName !== prvLastName) {
        setPrvLastName(lastName);
        setValue('Last Name Billing', lastName);
      }
    }
  }, [
    firstName,
    lastName,
    isBillingInfoDisplayed,
    prvFirstName,
    prvLastName,
    setValue,
  ]);

  useEffect(() => {
    if (filteredFaqs === null && faqs !== null) {
      setFilteredFaqs(faqs);
    }
  }, [faqs, filteredFaqs]);

  useEffect(() => {
    const onWindowPopState = () => {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('hp') === 'true') {
        setHasCustomerPaidSuccessfully(true);
      } else {
        setHasCustomerPaidSuccessfully(false);
      }
    };

    window.addEventListener('popstate', onWindowPopState);

    return () => window.removeEventListener('popstate', onWindowPopState);
  }, []);

  const paymentPlanEventLabel =
    selectedPaymentMethod === paymentMethods.threeTimesPayment
      ? 'Three-times payment'
      : 'One-time payment';

  const tryUserSignUp = async ({ formData, stripeToken }) => {
    const payload = {
      paymentToken: stripeToken,
      firstName: formData['First Name Billing'],
      lastName: formData['Last Name Billing'],
      billingUserId: optionalUserIdUrlParameter,
      questions:
        (sessionStorage.prettyQuestions &&
          JSON.parse(sessionStorage.prettyQuestions)) ||
        {},
      ...(userId
        ? {
            userId,
            couponCode,
          }
        : {
            hasCompatibleHeadset: true,
            isMonthly:
              selectedPaymentMethod === paymentMethods.threeTimesPayment,
            couponCode,
            email: formData['Email Billing'].trim(),
            shippingDetails: {
              email: formData['Email Billing'],
              firstName: formData['First Name'],
              lastName: formData['Last Name'],
              address: formData.Street,
              apt: formData.Apt,
              city: formData.City,
              state: formData.State,
              country: formData.Country,
              zip: formData['Zip Code'],
              phone: formData.Phone,
            },
          }),
      isWebView: !!(window.Android || window.webkit),
      upgradeFromApp,
    };

    try {
      const response = await window.fetch(`${funcURL}mtr-remoteUserSignup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();

      if (responseData.result === true) {
        setHasCustomerPaidSuccessfully(true);
        userIdRef.current = responseData.userId;
        // eslint-disable-next-line no-undef
        ga('send', {
          hitType: 'event',
          eventCategory: 'MTR',
          eventAction: 'Purchased',
          eventLabel: paymentPlanEventLabel,
        });
      }
      if (responseData.result === false) {
        if (responseData.message.toLowerCase() === 'payment failed') {
          // eslint-disable-next-line no-undef
          ga('send', {
            hitType: 'event',
            eventCategory: 'MTR',
            eventAction: 'PaymentFailed',
            eventLabel: paymentPlanEventLabel,
          });
          handleFailedPayment();
        }
      }
    } catch (err) {
      handleFailedPayment();
    }
  };

  const onSubmit = data => {
    if (isBillingInfoDisplayed || userId) {
      setShouldShowLoader(true);

      stripe
        .createToken()
        .then(async result => {
          if (result.error) {
            if (result.error.type !== 'validation_error') {
              // eslint-disable-next-line no-undef
              ga('send', {
                hitType: 'event',
                eventCategory: 'MTR',
                eventAction: 'PaymentFailed',
                eventLabel: paymentPlanEventLabel,
              });
              setStripeError(result.error.message);
            }
            setShouldShowLoader(false);
          } else {
            await tryUserSignUp({
              formData: data,
              stripeToken: result.token.id,
            });
            setShouldShowLoader(false);
          }
        })
        .catch(() => {
          // eslint-disable-next-line no-undef
          ga('send', {
            hitType: 'event',
            eventCategory: 'MTR',
            eventAction: 'PaymentFailed',
            eventLabel: paymentPlanEventLabel,
          });
        });

      sendEvents('remote_payment_page_on_pay', {
        isMonthly: selectedPaymentMethod === paymentMethods.threeTimesPayment,
      });
    } else {
      setIsCovidDialogOpen(true);
    }
  };

  const onCreditCardNumberChange = useCallback(e => {
    if (e.error && e.error.message) {
      setCreditCardNumberError(e.error.message);
    } else if (e.empty) {
      setCreditCardNumberError('Card Number Is Required');
    } else {
      setCreditCardNumberError(null);
    }
  }, []);

  const onCreditCardExpiryChange = useCallback(e => {
    if (e.error && e.error.message && !e.empty) {
      setCreditCardExpiryError(e.error.message);
    } else if (e.empty) {
      setCreditCardExpiryError('Card Expiry Date Is Required');
    } else {
      setCreditCardExpiryError(null);
    }
  }, []);

  const onCreditCardCVCChange = useCallback(e => {
    if (e.error && e.error.message) {
      setCreditCardCVCError(e.error.message);
    } else if (e.empty) {
      setCreditCardCVCError('Card CVC Is Required');
    } else {
      setCreditCardCVCError(null);
    }
  }, []);

  const faqsOnSearch = useCallback(
    event => {
      const searchString = event.target.value;
      setFilteredFaqs(
        faqs.filter(
          faq =>
            faq.question.toLowerCase().includes(searchString.toLowerCase()) ||
            faq.answer.toLowerCase().includes(searchString.toLowerCase())
        )
      );
    },
    [faqs]
  );
  const onClearSearch = useCallback(() => {
    setFilteredFaqs(faqs);
  }, [faqs]);

  useEffect(() => {
    if (!userId) {
      // eslint-disable-next-line no-new, new-cap
      new window.vidbg('#background_video', {
        mp4: '/assets/video-teaser.mp4',
        overlay: true,
        overlayColor: '#77e8ca',
        overlayAlpha: 0.2,
      });
    }
  }, [userId]);

  const onCheckoutFormConfirm = useCallback(() => {
    formRef.current.querySelector('input[type="submit"]').click();
  }, []);

  const getSubTotal = useCallback(
    () =>
      (userId && programPrices.family.price) ||
      (selectedPaymentMethod === paymentMethods.oneTimePayment &&
        programPrices.withHeadset.price) ||
      (selectedPaymentMethod === paymentMethods.threeTimesPayment &&
        programPrices.withHeadset.monthlyPrice),
    [userId, programPrices, selectedPaymentMethod]
  );

  const getFormattedSubTotal = useCallback(
    () =>
      `$${getSubTotal()}${
        selectedPaymentMethod === paymentMethods.threeTimesPayment
          ? '/mo X 3'
          : ''
      }`,
    [getSubTotal, selectedPaymentMethod]
  );

  const getTotalBeforeCouponDiscountApplied = () => {
    if (userId) {
      return programPrices.family.price;
    }

    if (hasCompatibleHeadset) {
      if (selectedPaymentMethod === paymentMethods.oneTimePayment) {
        return programPrices.withoutHeadset.price;
      }

      return programPrices.withoutHeadset.monthlyPrice;
    }

    if (selectedPaymentMethod === paymentMethods.oneTimePayment) {
      return programPrices.withoutHeadset.price;
    }

    return programPrices.withoutHeadset.monthlyPrice;
  };

  const getDiscountBasedOnCoupon = () => {
    let discount = 0;

    if (selectedPaymentMethod === paymentMethods.threeTimesPayment) {
      return discount;
    }
    if (couponDiscount.type === 'Const') {
      discount = couponDiscount.amount;
    }
    if (couponDiscount.type === 'Percent') {
      discount =
        (couponDiscount.amount / 100) * getTotalBeforeCouponDiscountApplied();
    }

    return parseFloat(discount.toFixed(2), 10);
  };

  const getTotal = () => {
    if (selectedPaymentMethod === paymentMethods.oneTimePayment || userId) {
      const discount = getDiscountBasedOnCoupon();
      return Math.max(getTotalBeforeCouponDiscountApplied() - discount, 0);
    }

    return getTotalBeforeCouponDiscountApplied();
  };

  const getFormattedTotal = () => {
    return `$${getTotal()}${
      selectedPaymentMethod === paymentMethods.oneTimePayment ? '' : '/mo X 3'
    }`;
  };

  const applyCoupon = useCallback(
    async code => {
      const response = await window.fetch(
        `${funcURL}shop-myndliftCouponDiscount`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            couponCode: code,
            ...(userId ? { userId } : {}),
          }),
        }
      );

      const responseData = await response.json();

      if (responseData && responseData.result === true) {
        if (responseData.discountAmount === 0) {
          setCouponCodeError('Invalid Coupon');
        } else {
          setCouponCodeError(null);
        }
        setCouponDiscount({
          type: responseData.discountType,
          amount: responseData.discountAmount,
        });
      } else if (responseData && responseData.result === false) {
        setCouponCodeError(responseData.message);
        setCouponDiscount({
          type: responseData.discountType,
          amount: responseData.discountAmount,
        });
      }
    },
    [funcURL, userId]
  );

  useEffect(() => {
    (async () => {
      if (
        discountCouponAmount > 0 &&
        stripe !== null &&
        programPrices !== null &&
        !userId &&
        !couponCodeUrl
      ) {
        await applyCoupon(discountCoupon);
      }
      if (couponCodeUrl && stripe !== null && programPrices !== null) {
        await applyCoupon(couponCodeUrl);
      }
    })();
  }, [
    userId,
    applyCoupon,
    stripe,
    programPrices,
    discountCouponAmount,
    discountCoupon,
  ]);

  useEffect(() => {
    try {
      window.StampedFn.init({
        apiKey: 'pubkey-IM80QP3762489Ps6C80Un9n7NBw523',
        storeUrl: 'signup.myndlift.com',
      });
    } catch (err) {
      defensiveThrow({ error: err });
    }
  }, []);

  const onCouponCodeApply = useCallback(async () => {
    setIsCouponCodeBtnLoading(true);
    await applyCoupon(couponCode);
    setIsCouponCodeBtnLoading(false);
  }, [applyCoupon, couponCode]);

  const renderDataFilledContent = () => {
    return (
      <React.Fragment>
        <div className={styles.whats_included}>
          <h2>What’s included?</h2>
          <div>
            <div>
              <img src={includedAppImage} />
              <p>Access to the Myndlift App</p>
              <p>
                You will get access to the Myndlift mobile neurofeedback app.
                The app has a variety of games that help in training your brain
                based on what your specialized neurofeedback coach has chosen
                for you.
              </p>
            </div>

            <div>
              <img src={includedClinicianImage} />
              <p>Access to a remote neurofeedback coach</p>
              <p>
                You get monthly consultation sessions with your online
                neurofeedback coach and access to a dedicated Myndlift support
                team. Extra sessions cost ${additionalSessionPrice}/session.
              </p>
            </div>

            <div>
              <img src={includedMuseImage} />
              <p>Muse EEG Headband</p>
              <p className={styles.no_margin}>
                The Muse headband measures your brain activity using its EEG
                sensors. The Myndlift app connects to the headband in order to
                get these measurements in real-time and provide the
                neurofeedback training.
              </p>
              <p className={classNames(styles.high_light, styles.no_margin)}>
                Currently, this item is out of stock, therefore we ask that you
                use your own device or purchase one separately.
              </p>
              <p style={{ display: 'none' }}>
                If you already have a compatible Muse (a Muse with one USB
                port), then we will discount $
                {programPrices.withHeadset.price -
                  programPrices.withoutHeadset.price}{' '}
                from the total price, and won’t ship you one.
              </p>
            </div>

            <div>
              <img src={includedElectrodeImage} />
              <p>External EEG Electrode</p>
              <p>
                This electrode connects directly to the Muse headband and allows
                brain activity to be measured from any location on the scalp,
                and will help in personalizing your neurofeedback experience.
              </p>
            </div>

            <div>
              <img src={includedPasteImage} />
              <p>Conductive Paste</p>
              <p>
                The conductive paste allows the electrode to be placed anywhere
                on the scalp, and at the same time ensures high signal
                acquisition quality.
              </p>
            </div>

            <div>
              <img src={includedCaseImage} />
              <p>Myndlift Hard Case</p>
              <p>
                You’ll get a compact hard case to help you put all of the
                hardware pieces together in an organized way.
              </p>
            </div>
          </div>
        </div>

        <div className={styles.call_to_actions}>
          <CustomBtn onClick={() => scrollTo('checkout-form')}>
            <span>get your myndlift kit now</span>
          </CustomBtn>
        </div>

        <div className={styles.reviews_section}>
          <div
            id="stamped-main-widget"
            data-product-id="1"
            data-name="##product.title##"
            data-url="##product.url##"
            data-image-url="##product.image##"
            data-description="##product.description##"
            data-product-sku="##product.handle##"
          />
        </div>

        <div className={styles.calls_info}>
          <h2>More Questions?</h2>
          <h2>
            Call{' '}
            <a
              href="tel:+1855-3217800"
              data-content="+1855-3217800"
              data-type="phone"
            >
              855.321.7800
            </a>
          </h2>
          <p>Monday to Friday 9:30am - 6pm Eastern Time</p>
        </div>

        <div className={styles.payment_options} id="payment-options">
          <h2>Two Options for Payment</h2>
          <p>
            You will get access to the Myndlift training app, an expert Neuro
            Coach and the neurofeedback supplies in the Myndlift Kit.
          </p>
          <div className={styles.cards_container}>
            <div>
              <div>
                <h3>Three Payments</h3>
                <p>
                  Starting from ${programPrices.withoutHeadset.monthlyPrice} X 3
                </p>
              </div>
              <div className={styles.divider} />
              <div>
                <p>- billed monthly for 3 months</p>
                <p style={{ display: 'none' }}>
                  * Full price is ${programPrices.withHeadset.monthlyPrice} if
                  you do not own a compatible Muse
                </p>
              </div>
            </div>

            <div>
              <div>
                <h3>One Payment</h3>
                <p>
                  1 payment starting from{' '}
                  {discountCouponAmount > 0 ? (
                    <>
                      <span className={styles.strike}>
                        ${programPrices.withoutHeadset.price}
                      </span>{' '}
                      <span>
                        $
                        {programPrices.withoutHeadset.price -
                          discountCouponAmount}{' '}
                      </span>
                    </>
                  ) : (
                    <span>${programPrices.withoutHeadset.price}</span>
                  )}
                </p>
              </div>
              <div className={styles.divider} />
              <div>
                <p>- 30-day money-back guarantee</p>
                <p>
                  - Save up to $
                  {parseInt(
                    3 * programPrices.withHeadset.monthlyPrice -
                      programPrices.withHeadset.price,
                    10
                  )}{' '}
                  compared to the Three-Payment Plan!
                </p>
                <p style={{ display: 'none' }}>
                  * Full price is ${programPrices.withHeadset.price} if you do
                  not own a compatible Muse
                </p>
              </div>
            </div>
          </div>

          <div className={styles.action}>
            <p>
              Please note that the Three-Payment Plan isn't eligible for the 30
              day money-back guarantee
            </p>
            <CustomBtn onClick={() => scrollTo('checkout-form')}>
              <span>get your myndlift kit now</span>
            </CustomBtn>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderCheckoutForm = () => {
    const total = getFormattedTotal();

    const subTotal = getFormattedSubTotal();

    return (
      <React.Fragment>
        <CheckoutForm
          onConfirm={onCheckoutFormConfirm}
          ref={checkoutFormRef}
          {...{
            creditCardNumberError,
            onCreditCardNumberChange,
            creditCardExpiryError,
            creditCardCVCError,
            onCreditCardExpiryChange,
            onCreditCardCVCChange,
            register,
            selectedMuseOption,
            onSelectedMuseOptionChange,
            museOptions,
            selectedPaymentMethod,
            paymentMethods,
            onSelectedPaymentMethodChange,
            total,
            subTotal,
            hasCompatibleHeadset,
            stripeError,
            compatibleMuseText,
            compatibleMuseTextColor,
            couponCode,
            isCouponCodeBtnLoading,
            onCouponCodeChange,
            onCouponCodeApply,
            couponCodeError,
            compatibleMuseDiscountDescription:
              selectedMuseOption === museOptions.iWillBuyMuseMyself ||
              selectedMuseOption === museOptions.no ||
              selectedMuseOption === museOptions.yes
                ? 'No Muse included'
                : 'No Muse included',
            couponDiscount: getDiscountBasedOnCoupon(),
            compatibleHeadsetDiscount:
              selectedPaymentMethod === paymentMethods.oneTimePayment
                ? programPrices.withHeadset.price -
                  programPrices.withoutHeadset.price
                : programPrices.withHeadset.monthlyPrice * 3 -
                  programPrices.withoutHeadset.monthlyPrice * 3,
            isBillingInfoDisplayed: !!(userId || isBillingInfoDisplayed),
            isFamilyCheckoutForm: !!userId,
            paymentOptionsId: 'payment-options',
            billingInfoSectionId: 'billing-info-section',
            shouldShowBillingEmailInput: !userId,
            validateEmail: email => {
              // i had to do this dom-access hack because react-hook-form validation
              // function does not get updated when the validation function passed to
              // register gets updated
              const billingInfoSectionNode = document.getElementById(
                'billing-info-section'
              );
              const isBillingInfoDisplayedBasedOnDom =
                billingInfoSectionNode.getAttribute(
                  'data-is-billing-info-displayed'
                ) === 'true';
              if (isBillingInfoDisplayedBasedOnDom) {
                return isValidEmail(email.trim());
              }

              return true;
            },
            errors: mapValues(errors, (error, name) => {
              if (error.type === 'required') {
                return {
                  ...error,
                  message: `${name.replace('Billing', '')} is required`,
                };
              }
              return error;
            }),
          }}
        />

        <input type="submit" style={{ display: 'none' }} />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div
        className={
          userId || shouldShowJustShippingForm
            ? styles.root_family_member_payment
            : styles.root
        }
        style={{
          display: hasCustomerPaidSuccessfully ? 'none' : 'block',
        }}
      >
        {shouldShowLoader && (
          <div>
            <Backdrop open classes={{ root: styles.backdrop }} />

            <div className={styles.progress_container}>
              <CircularProgress color="inherit" />
            </div>
          </div>
        )}

        <div className={styles.centered}>
          <div className={styles.logo_container}>
            <img src={logoImage} />
          </div>
          <div className={styles.divider} />
        </div>

        {discountCouponAmount > 0 && (
          <div className={styles.coupon_banner_container}>
            <div className={styles.coupon_banner}>
              <p>
                Limited time offer: get{' '}
                <span className={styles.bold}>${discountCouponAmount}</span> off
                with code "{discountCoupon}"
              </p>
            </div>
          </div>
        )}

        <div className={styles.package}>
          <img src={packageDescriptionImage} />
          <div>
            <h2>The Myndlift Package 1</h2>
            <p>
              Ready to make a change? Our package makes it simple for a
              neurofeedback practitioner in our network to evaluate your case,
              design a training plan for you, and supervise your training.
            </p>
            <p>
              All you need to do is order the hardware, and our team will take
              it from there — no office visits needed.
            </p>
          </div>
        </div>

        {programPrices && (
          <div className={styles.centered}>
            <div className={styles.money_back_guarantee_container}>
              {discountCouponAmount > 0 ? (
                <p>
                  Starting at{' '}
                  <span className={styles.strike}>
                    ${programPrices.withoutHeadset.price}
                  </span>{' '}
                  <span>
                    ${programPrices.withoutHeadset.price - discountCouponAmount}{' '}
                  </span>
                  <span className={styles.light_green}>
                    (limited time offer)
                  </span>
                </p>
              ) : (
                <p>Starting at ${programPrices.withoutHeadset.price}</p>
              )}
              <CustomBtn onClick={() => scrollTo('checkout-form')}>
                <span style={{ paddingLeft: 32, paddingRight: 32 }}>
                  get your package
                </span>
              </CustomBtn>
              <div className={styles.money_back_guarantee}>
                <img src={moneyBackGuaranteeImage} />
                <p>Money-back guarantee</p>
              </div>
            </div>
          </div>
        )}

        <div
          className={styles.background_video_container}
          onClick={onVideoOpen}
        >
          <div className={styles.play_icon}>
            <img src={playIcon} />
          </div>
          <div id="background_video" className={styles.background_video} />
        </div>

        {programPrices && renderDataFilledContent()}

        <form
          onSubmit={handleSubmit(onSubmit)}
          ref={formRef}
          id="checkout-form"
        >
          {stripe !== null &&
          programPrices !== null &&
          filteredFaqs !== null ? (
            <React.Fragment>
              {renderCheckoutForm()}
              <div className={styles.faqs_container}>
                <FAQS
                  faqs={filteredFaqs}
                  onSearch={faqsOnSearch}
                  onClearSearch={onClearSearch}
                  answersVariables={{ additionalSessionPrice }}
                />
              </div>
            </React.Fragment>
          ) : (
            <div className={styles.checkout_form_loader}>
              <Loader color="inherit" />
            </div>
          )}
        </form>

        <ModalVideo
          channel="youtube"
          isOpen={isVideoOpen}
          videoId="rCjpqOg3QSQ"
          onClose={() => setIsVideoOpen(false)}
        />
      </div>

      {hasCustomerPaidSuccessfully && (
        <div className={styles.root}>
          {programPrices !== null ? (
            <SuccessfulPayment
              onVisitBlog={() => {
                if (window.history && window.history.pushState) {
                  window.history.pushState({}, null, '?hp=true');
                }
                window.open(
                  'https://www.myndlift.com/neurofeedback-blog',
                  '_self'
                );
              }}
              onAddMoreUser={() => {
                window.open(
                  `${window.location.origin}?userId=${userIdRef.current}&utm_source=dashboard`,
                  '_self'
                );
              }}
              isFamilyMember={!!userId}
              hasOnlySoftwarePackage={hasCompatibleHeadset}
              moreUserPrice={programPrices.family.price}
            />
          ) : (
            <div className={styles.checkout_form_loader}>
              <Loader color="inherit" />
            </div>
          )}
        </div>
      )}

      <Dialog open={isCovidDialogOpen}>
        <DialogTitle>Before you proceed</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The package does not include a Muse headset. Please confirm that you
            either have a compatible Muse or will buy one separately.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsCovidDialogOpen(false);
              scrollTo('checkout-form');
            }}
          >
            GO BACK
          </Button>
          <Button
            onClick={() => {
              setIsCovidDialogOpen(false);
              setTimeout(() => {
                const questions =
                  sessionStorage.prettyQuestions &&
                  JSON.parse(sessionStorage.prettyQuestions);
                // eslint-disable-next-line no-undef
                ga('send', {
                  hitType: 'event',
                  eventCategory: 'MTR',
                  eventAction: 'ClickedCheckout',
                  eventLabel: (questions && questions.email) || 'unknown',
                });
                sendEvents('remote_payment_page_on_checkout', {
                  isMonthly:
                    selectedPaymentMethod === paymentMethods.threeTimesPayment,
                });
                setIsBillingInfoDisplayed(true);
              }, 200);
            }}
            variant="contained"
            autoFocus
            className={styles.covid_button}
          >
            <span style={{ color: 'inherit' }}>CONFIRM</span>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

RemotePaymentPageBase.propTypes = exact({
  stripe: PropTypes.object,
  // nodeURL: PropTypes.string.isRequired,
  programPrices: PropTypes.object,
  faqs: PropTypes.array,
  discountCouponAmount: PropTypes.number.isRequired,
  discountCoupon: PropTypes.string.isRequired,
  additionalSessionPrice: PropTypes.number.isRequired,
  funcURL: PropTypes.string.isRequired,
});

export const RemotePaymentPage = injectStripe(
  React.memo(RemotePaymentPageBase)
);
RemotePaymentPage.displayName = 'RemotePaymentPage';
