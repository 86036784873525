import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import styles from './CustomBtn.scss';

const CustomBtnBase = ({ onClick, children }) => {
  return (
    <div onClick={onClick} className={styles.root}>
      {children}
    </div>
  );
};

CustomBtnBase.propTypes = exact({
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
});

export const CustomBtn = React.memo(CustomBtnBase);
CustomBtn.displayName = 'CustomBtn';
