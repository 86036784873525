import React, { memo, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import StarIcon from '@material-ui/icons/Star';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Slider from 'react-slick';
import { debounce } from 'lodash';
import Flag from 'react-world-flags';
import { TestimonialCard } from '../TestimonialCard/TestimonialCard';
import './ReviewsCarousel.module.scss';
import { detectOS } from '../../../utils/utils';
import styles from '../../MTRSubscriptionPage/MTRSubscriptionPage.scss';
import videoTestimonialStyles from './VideoTestimonialCardCustomStyles.module.scss';

const ReviewsCarouselBase = ({
  sortedReviews,
  videoTestimonials,
  sendEvents,
  // isYoutubeDialogOpen,
  setIsYoutubeDialogOpen,
  setCurrentVideoDialogInfo,
  countryFlags,
  style,
}) => {
  const sliderRef = useRef(null);
  const containerRef = useRef(null);

  const slide = useCallback(x => {
    const threshold = 10; // Minimum scroll delta to trigger slide change
    if (Math.abs(x) > threshold) {
      if (x > 0) {
        sliderRef.current.slickNext(); // Go to the next slide
      } else {
        sliderRef.current.slickPrev(); // Go to the previous slide
      }
    }
  }, []);

  const onUserImageClick = (testimonial, testimonialType) => {
    const {
      user,
      youtubeEmbedVideoId,
      youtubeVideoTitle,
      youtubeVideoType,
    } = testimonial;
    if (testimonialType === 'video') {
      sendEvents('mtr_subscription_vid_review_click', {
        name: user,
        video_link_id: youtubeEmbedVideoId,
      });
      setCurrentVideoDialogInfo({
        videoId: youtubeEmbedVideoId,
        videoTitle: youtubeVideoTitle,
        // width: youtubeVideoType === 'shorts' ? '411' : '980',
        // height: youtubeVideoType === 'shorts' ? '730' : '560',
        className:
          youtubeVideoType === 'shorts'
            ? 'iframe_short_embed'
            : 'iframe_video_embed',
      });
      setIsYoutubeDialogOpen(true);
    }
  };

  const handleScrollSendEvent = useCallback(
    debounce(() => {
      sendEvents('mtr_subscription_more_reviews', {
        side: 'swipe',
      });
    }, 100),
    []
  );

  useEffect(() => {
    const handleWheel = e => {
      // Check if the event target is within the slider container
      if (containerRef.current && containerRef.current.contains(e.target)) {
        // Check if horizontal scroll is predominant
        if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
          e.preventDefault(); // Prevent default scrolling
          slide(e.deltaX);
          handleScrollSendEvent();
        }
      }
    };

    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (containerElement) {
        containerElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, [slide, handleScrollSendEvent]);

  const os = detectOS();

  const CustomPrevArrow = ({ onClick }) => {
    return (
      <span
        onClick={() => {
          onClick();
          sendEvents('mtr_subscription_more_reviews', {
            side: 'left',
          });
        }}
        className={classNames(
          styles.option_b_reviews_arrow,
          styles.custom_prev_arrow
        )}
      >
        <KeyboardArrowLeftIcon fontSize="large" style={{ color: '#35e3d4' }} />
      </span>
    );
  };

  const CustomNextArrow = ({ onClick }) => {
    return (
      <span
        onClick={() => {
          onClick();
          sendEvents('mtr_subscription_more_reviews', {
            side: 'right',
          });
        }}
        className={classNames(
          styles.option_b_reviews_arrow,
          styles.custom_next_arrow
        )}
      >
        <KeyboardArrowRightIcon fontSize="large" style={{ color: '#35e3d4' }} />
      </span>
    );
  };

  const settings = {
    // focusOnSelect: true,
    dots: false,
    // className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 1,
    // slidesToScroll: 1,
    // swipeToSlide: false,
    // touchToMove: false,
    speed: 400,
    variableWidth: true,
    adaptiveHeight: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    // responsive: [
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 3,
    //       swipeToSlide: true,
    //       touchToMove: true,
    //     },
    //   },
    // ],
  };
  return (
    <div className={styles.option_b_reviews_root}>
      <p className={styles.option_b_reviews_main_title} style={style}>
        Success stories from our community of over 26,000 users
      </p>
      <div className={styles.option_b_reviews}>
        <div className={styles.option_b_reviews_container} id="review_scroll">
          <div className={styles.slider_container} ref={containerRef}>
            <Slider {...settings} ref={sliderRef}>
              {videoTestimonials.map(testimonial => (
                <div
                  key={testimonial.user}
                  className={classNames(styles.option_b_testimonial_item)}
                >
                  <div
                    className={classNames(
                      styles.option_b_testimonial_item_container,
                      testimonial.user === 'Kirk Cousins' &&
                        videoTestimonialStyles.kirks_custom_card
                    )}
                  >
                    <TestimonialCard
                      testimonial={testimonial}
                      // isYoutubeDialogOpen={isYoutubeDialogOpen}
                      // setIsYoutubeDialogOpen={setIsYoutubeDialogOpen}
                      // setCurrentVideoDialogInfo={setCurrentVideoDialogInfo}
                      onUserImageClick={onUserImageClick}
                      detectOS={detectOS}
                      sendEvents={sendEvents}
                      customStyles={videoTestimonialStyles}
                    />
                  </div>
                </div>
              ))}
              {sortedReviews.map(review => (
                <div className={classNames(styles.option_b_review_item)}>
                  <div className={styles.option_b_review_item_container}>
                    <div className={styles.option_b_review_item_title}>
                      {review.reviewTitle}
                    </div>
                    <div style={{ position: 'relative', right: 5 }}>
                      {[...Array(review.reviewRating)].map(() => (
                        <StarIcon
                          style={{ color: '#13BEB0', marginRight: 5 }}
                        />
                      ))}
                    </div>
                    <div className={styles.option_b_review_item_description}>
                      {review.reviewMessage}
                    </div>
                    <div className={styles.option_b_review_item_from}>
                      <div>{review.author}</div>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        {countryFlags[review.countryIso] &&
                          (os === 'Windows' ? (
                            <Flag
                              code={review.countryIso}
                              fallback={<span>{review.countryIso}</span>}
                              height={8}
                            />
                          ) : (
                            countryFlags[review.countryIso].flag
                          ))}
                        &#160;
                        {countryFlags[review.countryIso] &&
                          countryFlags[review.countryIso].name}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

ReviewsCarouselBase.propTypes = exact({
  sortedReviews: PropTypes.array.isRequired,
  videoTestimonials: PropTypes.array.isRequired,
  sendEvents: PropTypes.func.isRequired,
  setIsReviewAtStart: PropTypes.func.isRequired,
  setIsReviewAtEnd: PropTypes.func.isRequired,
  // isYoutubeDialogOpen: PropTypes.bool.isRequired,
  setIsYoutubeDialogOpen: PropTypes.func.isRequired,
  setCurrentVideoDialogInfo: PropTypes.func.isRequired,
  countryFlags: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
});

export const ReviewsCarousel = memo(ReviewsCarouselBase);
ReviewsCarousel.displayName = 'ReviewsCarousel';
