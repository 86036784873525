import React, { useEffect, useState } from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { defensiveThrow } from '../../../utils/utils';
import { DashboardError } from '../../../models/errors/errors';

export const withStripeElementsHOC = (
  Component,
  { shouldStripeBeLoadedAlready = true } = {}
) => {
  const WithStripeElementsHOC = props => {
    const [stripe, setStripe] = useState(null);

    useEffect(() => {
      if (!shouldStripeBeLoadedAlready || !window.stripe) {
        defensiveThrow({
          error: new DashboardError(
            'withStripeElementsHOC supports only already loaded stripe script'
          ),
        });
      } else {
        setStripe(window.stripe);
      }
    }, []);

    return stripe ? (
      <StripeProvider stripe={stripe} key={stripe ? 'stripe' : 'null'}>
        <Elements>
          <Component {...props} stripe={stripe} />
        </Elements>
      </StripeProvider>
    ) : null;
  };
  return WithStripeElementsHOC;
};
