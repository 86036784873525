exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".SuccessfulPayment_root{max-width:800px;display:flex;flex-direction:column;justify-content:center;align-items:center;margin-left:auto;margin-right:auto;padding:0px 20px;color:#767676}.SuccessfulPayment_root>div:first-child{margin-bottom:10px}.SuccessfulPayment_root p{text-align:center;max-width:600px}.SuccessfulPayment_root h2{text-align:center}@media only screen and (max-width: 768px){.SuccessfulPayment_root h2{font-size:20px;margin:0}}.SuccessfulPayment_btn_container{display:flex;padding-top:20px;padding-bottom:20px}.SuccessfulPayment_scale-in-center,.SuccessfulPayment_root{-webkit-animation:SuccessfulPayment_scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;animation:SuccessfulPayment_scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both}@-webkit-keyframes SuccessfulPayment_scale-in-center{0%{transform:scale(0);opacity:1}100%{transform:scale(1);opacity:1}}@keyframes SuccessfulPayment_scale-in-center{0%{transform:scale(0);opacity:1}100%{transform:scale(1);opacity:1}}.SuccessfulPayment_text_border{box-shadow:0 0 20px 0 rgba(0,0,0,0.16);border-radius:20px;margin-bottom:20px;margin-top:20px}.SuccessfulPayment_text_container{margin:0;display:flex;flex-direction:column;justify-content:center;align-items:center;width:600px;padding:25px 15px}@media only screen and (max-width: 768px){.SuccessfulPayment_text_container{width:unset}}.SuccessfulPayment_img_style{width:105%;height:150px;-o-object-fit:cover;object-fit:cover;-o-object-position:0 -50px;object-position:0 -50px;position:relative;top:-16px}@media only screen and (max-width: 768px){.SuccessfulPayment_img_style{width:100%;-o-object-position:0 -25px;object-position:0 -25px}}.SuccessfulPayment_img_gif{width:250px;height:200px}@media only screen and (max-width: 768px){.SuccessfulPayment_img_gif{width:125px;height:100px}}\n", ""]);

// Exports
exports.locals = {
	"root": "SuccessfulPayment_root",
	"btn_container": "SuccessfulPayment_btn_container",
	"scale-in-center": "SuccessfulPayment_scale-in-center",
	"text_border": "SuccessfulPayment_text_border",
	"text_container": "SuccessfulPayment_text_container",
	"img_style": "SuccessfulPayment_img_style",
	"img_gif": "SuccessfulPayment_img_gif"
};