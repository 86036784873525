import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import dayJS from 'dayjs';
import classNames from 'classnames';
import dayjsDuration from 'dayjs/plugin/duration';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  FormControl,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { CardElement, injectStripe } from 'react-stripe-elements';
import logoImage from '../../../../myndlift-signup/public/assets/modern_logo_black.png';
import museOnFemaleHeadImage from '../../../assets/muse-on-female-head.png';
import museOnMaleHeadImage from '../../../assets/muse-on-male-head.png';
import checkedImage from '../../../../myndlift-signup/public/assets/checked.png';
import failedImage from '../../../../myndlift-signup/public/assets/failed.png';
import masterCardImage from '../../../../myndlift-signup/public/assets/mastercard.png';
import visaCardImage from '../../../../myndlift-signup/public/assets/visa.png';
import dinersCardImage from '../../../../myndlift-signup/public/assets/diners.png';
import americanExpressCardImage from '../../../../myndlift-signup/public/assets/amex.png';
import unionPayCardImage from '../../../../myndlift-signup/public/assets/unionpay.png';
import discoverCardImage from '../../../../myndlift-signup/public/assets/discover.png';
import unknownCardImage from '../../../../myndlift-signup/public/assets/unknown.png';
import cancellationImage from '../../../../myndlift-signup/public/assets/cancelation_icon.png';
// import undrawChooseImage from '../../../../myndlift-signup/public/assets/undraw_choose.png';
import nerdWithHeadsetMobileImage from '../../../../myndlift-signup/public/assets/churn-2.png';
import womanPracticeMobileImage from '../../../../myndlift-signup/public/assets/churn-1.png';
import girlHeadsetAppImage from '../../../../myndlift-signup/public/assets/churn-3.png';
import styles from './MTRManageSubscriptionPage.scss';
import {
  getPlanTypeByBillingPeriodInMonths,
  getPlanPriceByMonth,
  getPlanSaving,
  formattedPlanTypesByBillingPeriodInMonths,
} from '../../models/mtr-subscription/mtr-subscription';
import {
  // postToNode,
  addZerosToNumberToMatchLength,
  getEventLogger,
  getCurrentURLParams,
  postToFunctions,
  postMessageToApp,
} from '../../utils/utils';
import { DAY_JS_DATE_FORMATS } from '../../utils/constants';
import { usePreviousValue } from '../../Core/hooks/usePreviousValue';
import { DelayedRenderer } from '../../Core/Components/DelayedRenderer/DelayedRenderer';
import { OutstandingPaymentErrorDialog } from './ErrorDialog/OutstandingPaymentErrorDialog';

const wrapWithDelayedRenderer = node => (
  <DelayedRenderer delay={50}>{node}</DelayedRenderer>
);

dayJS.extend(dayjsDuration);

const logSubscriptionManagementEvent = getEventLogger({
  prefix: 'mtr_subscription_management_app',
});

const steps = {
  selectAction: 'selectAction',
  reviewAction: 'reviewAction',
  cancelSubscriptionConfirm: 'cancelSubscriptionConfirm',
  cancelSubscriptionReasons: 'cancelSubscriptionReasons',
  loader: 'loader',
  subscriptionWasCanceled: 'subscriptionWasCanceled',
  editPayment: 'editPayment',
  subscriptionInfo: 'subscriptionInfo',
  verification: 'verification',
  verificationFailed: 'verificationFailed',
  subscriptionUpgraded: 'subscriptionUpgraded',
  paymentMightHaveSucceededPartially: 'paymentMightHaveSucceededPartially',
  paymentSucceeded: 'paymentSucceeded',
  cancelDiscount: 'cancelDiscount',
  pauseSubscription: 'pauseSubscription',
  downgradeToContinuity: 'downgradeToContinuity',
  discountBeforeReactivation: 'discountBeforeReactivation',
};
const initialStep = steps.subscriptionInfo;

const actionIds = {
  switchPlan: 'switchPlan',
  switchToContinuityPlan: 'switchToContinuityPlan',
  cancelSubscription: 'cancelSubscription',
  cancelDeactivation: 'cancelDeactivation',
  keepSubscriptionWithDiscount: 'keepSubscriptionWithDiscount',
  cancelSubscriptionDiscount: 'cancelSubscriptionDiscount',
  pauseSubscription: 'pauseSubscription',
};

const cancelSubscriptionReasons = {
  dontWantNeuroFeedback: 'I no longer want to do neurofeedback training',
  iWantBreak: 'It was great, but I’d like to take a break',
  doesNotMatchExpectations: 'Didn’t match my expectations',
  other: 'Other',
};

// const verificationReasons = {
//   changeSubscription: 'changeSubscription',
//   cancelSubscription: 'cancelSubscription',
//   cancelDeavtivation: 'cancelDeactivation',
// };

const stripeElementStyle = {
  base: {
    color: '#585858',
  },
};

const planTypes = {
  continuityPlans: 'continuityPlans',
  corePlans: 'corePlans',
  familyMemberPlans: 'familyMemberPlans',
};

const formattedPlanTypes = {
  [planTypes.corePlans]: 'Core Training',
  [planTypes.continuityPlans]: 'Continuity Training',
  [planTypes.familyMemberPlans]: 'Family Member Training',
};

const environmentConfig =
  window.environmentConfig[window.environmentConfig.current];

const getPlanSwitchingInformation = ({
  currentPlanPrice,
  currentPlanType,
  nextPlanPrice,
  nextPlanType,
}) => {
  const isSwitchingToContinuity =
    currentPlanType !== planTypes.continuityPlans &&
    nextPlanType === planTypes.continuityPlans;
  const isUpgrading = isSwitchingToContinuity
    ? false
    : currentPlanPrice < nextPlanPrice;
  return { isSwitchingToContinuity, isUpgrading };
};

const SubscriptionCard = ({
  label,
  title,
  subTitle,
  extraInfo,
  discountInfo,
}) => {
  return (
    <div className={styles.subscription_card}>
      <p className={styles.card_label}>{label}</p>
      <div className={styles.subscription_info}>
        <p>{title}</p>
        {subTitle && <p>{subTitle}</p>}
      </div>
      {extraInfo && (
        <p className={styles.subscription_extra_info}>{extraInfo}</p>
      )}
      {discountInfo && (
        <p className={styles.subscription_extra_discount_info}>
          {discountInfo}
        </p>
      )}
    </div>
  );
};
SubscriptionCard.propTypes = exact({
  label: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
  extraInfo: PropTypes.node,
  discountInfo: PropTypes.node,
});

const GenericOutstandingPaymentsUpdatedStep = ({
  title,
  subTitle,
  onDoneBtnClick,
  isSuccess,
  btnLbl,
}) => {
  return (
    <div className={styles.generic_subscription_updated_step}>
      <img
        src={isSuccess ? checkedImage : failedImage}
        className={styles.check_image}
      />
      <div className={styles.info_text}>
        <p>{title}</p>
        <p>{subTitle}</p>
      </div>
      <div className={styles.btn_container}>
        <button type="button" onClick={onDoneBtnClick}>
          <span>{btnLbl || 'Done'}</span>
        </button>
      </div>
    </div>
  );
};
GenericOutstandingPaymentsUpdatedStep.propTypes = exact({
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
  btnLbl: PropTypes.node,
  onDoneBtnClick: PropTypes.func.isRequired,
  isSuccess: PropTypes.bool,
});

const GenericSubscriptionUpdatedStep = ({
  label,
  title,
  subTitle,
  extraInfo,
  onDoneBtnClick,
  addebleCard,
  shouldRemoveFirstCard,
}) => {
  return (
    <div className={styles.generic_subscription_updated_step}>
      <img src={checkedImage} className={styles.check_image} />
      <div className={styles.info_text}>
        <p>Thank you!</p>
        <p>Your plan was updated</p>
      </div>
      <div className={styles.updated_subscription_card}>
        {!shouldRemoveFirstCard && (
          <SubscriptionCard {...{ label, title, subTitle, extraInfo }} />
        )}
        <div style={{ marginTop: 10 }}>{addebleCard}</div>
      </div>
      <div className={styles.btn_container}>
        <button type="button" onClick={onDoneBtnClick}>
          <span>Done</span>
        </button>
      </div>
    </div>
  );
};
GenericSubscriptionUpdatedStep.propTypes = exact({
  label: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
  extraInfo: PropTypes.node,
  onDoneBtnClick: PropTypes.func.isRequired,
  addebleCard: PropTypes.node,
  shouldRemoveFirstCard: PropTypes.bool,
});

const PaymentDetails = ({ onEditPaymentMethod, currentCardInfo }) => {
  const creditCardImage = (() => {
    switch (currentCardInfo.brand) {
      case 'Visa':
        return visaCardImage;
      case 'MasterCard':
        return masterCardImage;
      case 'American Express':
        return americanExpressCardImage;
      case 'Diners Club':
        return dinersCardImage;
      case 'Discover':
        return discoverCardImage;
      case 'UnionPay':
        return unionPayCardImage;
      default:
        return unknownCardImage;
    }
  })();

  return (
    <div className={styles.payment_details}>
      <p>Payment Details</p>
      <div className={styles.credit_card_edit_box}>
        <img src={creditCardImage} />
        <div className={styles.credit_card_details}>
          <p>
            {currentCardInfo.brand} ending in {currentCardInfo.last4}
          </p>
          <p>
            Expires{' '}
            {addZerosToNumberToMatchLength(currentCardInfo.exp_month, 2)}/
            {currentCardInfo.exp_year}
          </p>
        </div>
        <span className={styles.edit_button} onClick={onEditPaymentMethod}>
          Edit
        </span>
      </div>
    </div>
  );
};

PaymentDetails.propTypes = exact({
  onEditPaymentMethod: PropTypes.func.isRequired,
  currentCardInfo: PropTypes.object.isRequired,
});

const initialErrorContainer = {
  msg: null,
  handler: null,
};

const MTRManageSubscriptionPageBase = ({
  customerDetails,
  currentPlan,
  adminUserId,
  plans,
  mtrCode,
  stripe,
  currentCardInfo,
  handleCardInfoUpdate,
  adminEmail,
  shouldRenderNewPlans,
  postMembershipPlan,
  cancelationDiscount,
}) => {
  const formattedCurrentPlanType =
    formattedPlanTypes[currentPlan.planType] || currentPlan.planType;
  const currentPlanNameBasedOnPeriod = getPlanTypeByBillingPeriodInMonths({
    billingPeriodInMonths: currentPlan.billingPeriodInMonths,
  });
  const currentPlanPricePerMonth = getPlanPriceByMonth({
    price: currentPlan.price,
    billingPeriodInMonths: currentPlan.billingPeriodInMonths,
  });

  const currentPlanRenewalDate = dayJS(currentPlan.expiration).format(
    DAY_JS_DATE_FORMATS.namedMonthDayYear
  );

  const isEligibleForContinuityPlan = currentPlan.eligibleToSwitchToContinuity;

  const firstAction = (() => {
    if (
      currentPlanNameBasedOnPeriod ===
      formattedPlanTypesByBillingPeriodInMonths.monthly
    ) {
      return {
        label:
          'Save up to 25% on the current plan by switching to a quarterly billing',
        value: actionIds.switchPlan,
      };
    }
    if (
      currentPlanNameBasedOnPeriod ===
      formattedPlanTypesByBillingPeriodInMonths.quarterly
    ) {
      return {
        label: `Save 25% on the current plan by switching to ${
          plans.corePlans.planOptions[plans.corePlans.planOptions.length - 1]
            .billingPeriodInMonths === 12
            ? 'an annual'
            : 'a biannual'
        } billing or just switch to monthly`,
        value: actionIds.switchPlan,
      };
    }
    if (
      currentPlanNameBasedOnPeriod ===
      formattedPlanTypesByBillingPeriodInMonths.biAnnually
    ) {
      return {
        label: 'Switch to monthly or quarterly billing',
        value: actionIds.switchPlan,
      };
    }

    return {
      label: 'Switch plan',
      value: actionIds.switchPlan,
    };
  })();
  const actions = [
    firstAction,
    {
      label: 'Switch to a low-cost plan without Neuro Coach sessions',
      value: actionIds.switchToContinuityPlan,
    },
    {
      label: 'Cancel my subscription to Myndlift',
      value: actionIds.cancelSubscription,
    },
  ];

  const newPlansActions = [
    {
      label: 'Cancel my subscription to Myndlift',
      value: actionIds.cancelSubscription,
    },
  ];

  const newPlansDeactivatedActions = [
    {
      label: 'Reactivate my subscription to Myndlift',
      value: actionIds.cancelDeactivation,
    },
  ];

  const [
    suggestedContinuityPlanBeforeDeactivation,
    setSuggestedContinuityPlanBeforeDeactivation,
  ] = useState(null);

  const [pauseDaysCredit, setPauseDaysCredit] = useState(0);

  const [isLoadingResume, setIsLoadingResume] = useState(false);

  const cancelDiscountActions = [
    {
      label: `Pause my subscription for up to ${pauseDaysCredit} days`,
      value: actionIds.pauseSubscription,
    },
    {
      label: 'Keep my subscription and save 50% on my next charge',
      value: actionIds.keepSubscriptionWithDiscount,
    },
    {
      label: `Switch to continuity and pay $${
        suggestedContinuityPlanBeforeDeactivation
          ? Math.round(
              suggestedContinuityPlanBeforeDeactivation.price /
                suggestedContinuityPlanBeforeDeactivation.billingPeriodInMonths
            )
          : 0
      }/mo`,
      value: actionIds.switchToContinuityPlan,
    },
    {
      label: 'No thanks, I’m not interested',
      value: actionIds.cancelSubscriptionDiscount,
    },
  ];

  const [currentStep, setCurrentStep] = useState(initialStep);

  const [
    editPaymentMethodStepOpener,
    setEditPaymentMethodStepOpener,
  ] = useState(null);
  const prvStep = usePreviousValue(currentStep);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedActionExtraInfo, setSelectedActionExtraInfo] = useState(null);
  const [planInReview, setPlanInReview] = useState(null);
  const [planTypesToReview, setPlanTypesToReview] = useState(null);
  const [reasonToCancelSubscription, setReasonToCancelSubscription] = useState(
    null
  );
  const [reasonsError, setReasonsError] = useState(null);
  const [
    commentForCancelingSubscription,
    setCommentForCancelingSubscription,
  ] = useState(null);
  // const [
  //   commentForCancelingSubscriptionError,
  //   setCommentForCancelingSubscriptionError,
  // ] = useState(null);
  const [loaderText, setLoaderText] = useState(null);
  // const [verificationReason, setVerificationReason] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState('');

  const [stripeError, setStripeError] = useState(null);
  const [creditCardError, setCreditCardError] = useState(null);
  const [creditCardUpdateNote, setCreditCardUpdateNote] = useState(null);

  const [errorContainer, setErrorContainer] = useState(initialErrorContainer);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [
    currentOutstandingPaymentAmount,
    setCurrentOutstandingPaymentAmount,
  ] = useState(null);

  const [
    isLoadinCancelationDiscount,
    setIsLoadinCancelationDiscount,
  ] = useState(false);

  const [
    isDowngradeStepFromMainScreen,
    setIsDowngradeStepFromMainScreen,
  ] = useState(false);
  const [
    isContinuityTrainStepFromMainScreen,
    setIsContinuityTrainStepFromMainScreen,
  ] = useState(false);

  const fbTokenRef = useRef(getCurrentURLParams().fbtoken);
  const creditCardElementRef = useRef();

  useEffect(() => {
    const { planOptions } = plans[planTypes.continuityPlans];
    // eslint-disable-next-line prefer-destructuring
    const suggestedPlan = planOptions[0];

    setSuggestedContinuityPlanBeforeDeactivation(suggestedPlan);
    setPauseDaysCredit(currentPlan.pauseDaysCredit);
  }, [plans, currentPlan]);

  // const postToNodeWithDefaultData = params =>
  //   postToNode({
  //     ...params,
  //     data: {
  //       fbtoken: fbTokenRef.current,
  //       ...params.data,
  //     },
  //   });

  const postToFunctionsWithDefaultData = params =>
    postToFunctions({
      ...params,
      data: {
        fbtoken: fbTokenRef.current,
        ...params.data,
      },
    });

  const onSendAnotherVerificationCode = async () => {
    setLoaderText('Sending code...');
    setCurrentStep(steps.loader);
    await postToFunctionsWithDefaultData({
      path: 'verifications-sendManageSubsVerificationCode',
      data: {
        userId: customerDetails.id,
        remoteAdminId: adminUserId,
        mtrCode,
      },
    });
    setCurrentStep(steps.verification);
  };

  const onCreditCardChange = useCallback(e => {
    if (e.error && e.error.message) {
      setCreditCardError(e.error.message);
    } else if (e.empty) {
      setCreditCardError('Card Number Is Required');
    } else {
      setCreditCardError(null);
    }
  }, []);

  const applySwitchToContinuityPlan = async () => {
    try {
      setIsLoadinCancelationDiscount(true);
      await postToFunctions({
        path: 'mtr-changePlanRequest',
        data: {
          userId: customerDetails.id,
          planType: planTypes.continuityPlans,
          packageIndex: plans[planTypes.continuityPlans].planOptions.findIndex(
            plan =>
              plan.stripeSubId ===
              suggestedContinuityPlanBeforeDeactivation.stripeSubId
          ),
          mtrCode,
          bypassTrainingMonths: true,
        },
      });
      // eslint-disable-next-line no-undef, no-restricted-globals
      location.reload();
    } catch {
      setIsLoadinCancelationDiscount(false);
    }
  };

  const pauseSubscription = async () => {
    setIsLoadinCancelationDiscount(true);
    try {
      const response = await postToFunctionsWithDefaultData({
        path: 'mtr-pauseSubscription',
        data: {
          mtrCode,
          userId: customerDetails.id,
        },
      });
      if (response.result) {
        setIsLoadinCancelationDiscount(false);
        // eslint-disable-next-line no-undef, no-restricted-globals
        location.reload();
      } else {
        setIsLoadinCancelationDiscount(false);
      }
    } catch (e) {
      setIsLoadinCancelationDiscount(false);
    }
  };

  const applyCancelationDiscount = async () => {
    setIsLoadinCancelationDiscount(true);
    try {
      const response = await postToFunctionsWithDefaultData({
        path: 'mtr-deactivationAlternativeDiscount',
        data: {
          mtrCode,
          userId: customerDetails.id,
        },
      });
      if (response.result) {
        setIsLoadinCancelationDiscount(false);
        // eslint-disable-next-line no-undef, no-restricted-globals
        location.reload();
      } else {
        setIsLoadinCancelationDiscount(false);
      }
    } catch (e) {
      setIsLoadinCancelationDiscount(false);
    }
  };

  const cancelDeactivation = () =>
    postToFunctionsWithDefaultData({
      path: 'mtr-changeActivation',
      data: {
        shouldDeactivate: false,
        // reasons: reasonToCancelSubscription,
        userId: adminUserId,
        mtrCode,
        // ...(commentForCancelingSubscription
        //   ? { comment: commentForCancelingSubscription }
        //   : {}),
      },
    });

  const onCancelDeactivationAfterVerification = async () => {
    // if (await authorizeUser()) {
    try {
      setLoaderText('Canceling deactivation...');
      await cancelDeactivation();
      setCurrentStep(steps.subscriptionUpgraded);
    } catch {
      setErrorContainer({
        msg: 'Failed to cancel deactivation',
      });
    }
    // }
  };

  const onCancelDeactivation = () => {
    setCurrentStep(steps.loader);
    onCancelDeactivationAfterVerification();
    // initVerification({
    //   reason: verificationReasons.cancelDeavtivation,
    // });
  };

  const onSelectAction = useCallback(() => {
    switch (currentStep) {
      case steps.selectAction:
        if (
          selectedAction === actionIds.switchPlan ||
          selectedAction === actionIds.switchToContinuityPlan
        ) {
          const newPlanTypeToReview =
            selectedAction === actionIds.switchPlan
              ? planTypes[currentPlan.planType]
              : planTypes.continuityPlans;
          setPlanTypesToReview(newPlanTypeToReview);
          const { nextPlanDetails } = currentPlan;
          const { planOptions } = plans[newPlanTypeToReview];
          const possiblePlanOptions = planOptions.filter(
            plan =>
              plan.stripeSubId !== currentPlan.stripeSubId &&
              (!nextPlanDetails ||
                plan.stripeSubId !== nextPlanDetails.stripeSubId)
          );

          setPlanInReview(possiblePlanOptions[0]);
          setCurrentStep(steps.reviewAction);
        }
        if (selectedAction === actionIds.cancelSubscription) {
          logSubscriptionManagementEvent({
            name: 'deactivation_flow_start',
          });
          if (currentPlan.deactivationOptions.isEligibleToPauseSubscription) {
            setCurrentStep(steps.pauseSubscription);
          } else if (
            currentPlan.deactivationOptions.isEligibleToByPassTrainingMonths
          ) {
            setCurrentStep(steps.downgradeToContinuity);
          } else if (
            currentPlan.deactivationOptions
              .isEligibleForDeactivationAlternativeDiscount
          ) {
            setCurrentStep(steps.discountBeforeReactivation);
          } else {
            setCurrentStep(steps.cancelSubscriptionConfirm);
          }
        }
        if (selectedAction === actionIds.cancelDeactivation) {
          setPlanInReview(currentPlan);
          onCancelDeactivation();
        }
        break;
      case steps.cancelDiscount:
        if (selectedAction === actionIds.keepSubscriptionWithDiscount) {
          applyCancelationDiscount();
        }
        if (selectedAction === actionIds.pauseSubscription) {
          pauseSubscription();
        }
        if (selectedAction === actionIds.cancelSubscriptionDiscount) {
          setCurrentStep(steps.cancelSubscriptionReasons);
        }
        if (selectedAction === actionIds.switchToContinuityPlan) {
          applySwitchToContinuityPlan();
        }
        break;
      default:
        setCurrentStep(steps.selectAction);
    }
  }, [currentPlan, currentStep, selectedAction, plans]);

  const onBack = () => {
    logSubscriptionManagementEvent({
      name: 'on_cancel_btn_click',
      data: {
        currentStep,
      },
    });
    if (isLoadinCancelationDiscount) {
      return;
    }

    switch (currentStep) {
      case steps.editPayment:
        setCurrentStep(editPaymentMethodStepOpener);
        setCreditCardError(null);
        setCreditCardUpdateNote(null);
        setStripeError(null);
        break;
      case steps.reviewAction:
        if (isContinuityTrainStepFromMainScreen) {
          setCurrentStep(steps.downgradeToContinuity);
          setIsContinuityTrainStepFromMainScreen(false);
        } else {
          setCurrentStep(steps.selectAction);
        }
        break;
      case steps.selectAction:
        setSelectedAction(null);
        setCurrentStep(steps.subscriptionInfo);
        break;
      case steps.pauseSubscription:
        setCurrentStep(
          selectedAction ? steps.selectAction : steps.subscriptionInfo
        );
        break;
      case steps.downgradeToContinuity:
        if (isDowngradeStepFromMainScreen) {
          setCurrentStep(steps.subscriptionInfo);
          setIsDowngradeStepFromMainScreen(false);
        } else if (
          currentPlan.deactivationOptions.isEligibleToPauseSubscription
        ) {
          setCurrentStep(steps.pauseSubscription);
        } else {
          setCurrentStep(steps.selectAction);
        }
        break;
      case steps.discountBeforeReactivation:
        if (currentPlan.deactivationOptions.isEligibleToByPassTrainingMonths) {
          setCurrentStep(steps.downgradeToContinuity);
        } else {
          if (currentPlan.deactivationOptions.isEligibleToPauseSubscription) {
            setCurrentStep(steps.pauseSubscription);
          } else {
            setCurrentStep(steps.selectAction);
          }
        }
        break;
      case steps.cancelSubscriptionConfirm:
        if (
          currentPlan.deactivationOptions
            .isEligibleForDeactivationAlternativeDiscount
        ) {
          setCurrentStep(steps.discountBeforeReactivation);
        } else if (
          currentPlan.deactivationOptions.isEligibleToByPassTrainingMonths
        ) {
          setCurrentStep(steps.downgradeToContinuity);
        } else if (
          currentPlan.deactivationOptions.isEligibleToPauseSubscription
        ) {
          setCurrentStep(steps.pauseSubscription);
        } else {
          setCurrentStep(steps.selectAction);
        }
        break;
      case steps.cancelSubscriptionReasons:
        setCurrentStep(steps.cancelSubscriptionConfirm);
        break;
      // case steps.verification:
      //   if (verificationReason === verificationReasons.changeSubscription) {
      //     setCurrentStep(steps.reviewAction);
      //   } else if (
      //     verificationReason === verificationReasons.cancelSubscription
      //   ) {
      //     setCurrentStep(steps.cancelSubscriptionReasons);
      //   } else {
      //     setCurrentStep(steps.selectAction);
      //   }
      //   break;
      default:
        setCurrentStep(prvStep);
        break;
    }
  };

  const onDoneBtnClick = () => {
    logSubscriptionManagementEvent({
      name: 'on_done_btn_click',
    });
    postMessageToApp('doneBtnClicked');
  };

  const onUpdateCreditCard = () => {
    const handleCreditCardFailedUpdate = () => {
      setCreditCardUpdateNote('Credit card was not updated!');
      setCurrentStep(steps.editPayment);
    };
    stripe.createToken().then(async result => {
      if (result.error) {
        if (result.error.type !== 'validation_error') {
          handleCreditCardFailedUpdate();
        }
      } else {
        try {
          setLoaderText('Updating credit card...');
          setCurrentStep(steps.loader);
          const data = await postToFunctionsWithDefaultData({
            path: 'mtr-registerPaymentMethod',
            data: {
              details: result,
              userId: customerDetails.id,
              remoteAdminId: adminUserId,
            },
          });

          if (data.result === true) {
            await handleCardInfoUpdate();
            setCurrentStep(editPaymentMethodStepOpener);
          } else {
            handleCreditCardFailedUpdate();
          }
        } catch (err) {
          handleCreditCardFailedUpdate();
        }
      }
    });
  };

  const onKeepMyTraining = () => {
    logSubscriptionManagementEvent({
      name: 'on_keep_my_training_btn_click',
    });
    setCurrentStep(steps.selectAction);
  };

  const onRetryPaymentClicked = async () => {
    const retryOutstandingInvoicesResponse = await postToFunctionsWithDefaultData(
      {
        path: 'mtr-retryOutstandingInvoices',
        data: {
          mtrCode,
          userId: customerDetails.id,
          remoteAdminId: adminUserId,
        },
      }
    );

    if (retryOutstandingInvoicesResponse.totalDue === 0) {
      // eslint-disable-next-line no-param-reassign
      delete currentPlan.outstandingPaymentsAmount;
      setCurrentStep(steps.paymentSucceeded);
    } else {
      // eslint-disable-next-line no-param-reassign
      currentPlan.outstandingPaymentsAmount =
        retryOutstandingInvoicesResponse.totalDue;
      await setCurrentOutstandingPaymentAmount(
        retryOutstandingInvoicesResponse.totalDue
      );
      setCurrentStep(steps.paymentMightHaveSucceededPartially);
    }
  };

  const createOnEditPaymentMethod = stepOpener => () => {
    logSubscriptionManagementEvent({ name: 'on_edit_payment_method' });
    setCurrentStep(steps.editPayment);
    setEditPaymentMethodStepOpener(stepOpener);
  };

  // const initVerification = ({ reason }) => {
  //   postToFunctionsWithDefaultData({
  //     path: 'verifications-sendManageSubsVerificationCode',
  //     data: {
  //       userId: customerDetails.id,
  //       remoteAdminId: adminUserId,
  //       mtrCode,
  //     },
  //   });
  //   setVerificationReason(reason);
  //   setCurrentStep(steps.verification);
  // };

  const onChangeSubscriptionAfterVerification = async () => {
    logSubscriptionManagementEvent({
      name: 'on_verify_btn_click',
    });
    // if (await authorizeUser()) {
    try {
      setLoaderText('Updating subscription...');
      await postToFunctionsWithDefaultData({
        path: 'mtr-changePlanRequest',
        data: {
          userId: adminUserId,
          planType: planTypesToReview,
          packageIndex: plans[planTypesToReview].planOptions.findIndex(
            plan => plan.stripeSubId === planInReview.stripeSubId
          ),
          mtrCode,
        },
      });
      setCurrentStep(steps.subscriptionUpgraded);
    } catch {
      setErrorContainer({
        msg: 'Failed to change plan',
      });
    }
    // }
  };

  const onUpdateSubscription = async () => {
    logSubscriptionManagementEvent({
      name: 'on_update_subscription',
      data: {
        planInReview,
        planType: planTypesToReview,
      },
    });
    setCurrentStep(steps.loader);
    onChangeSubscriptionAfterVerification();
    // initVerification({
    //   reason: verificationReasons.changeSubscription,
    // });
  };

  // const cancelSubscription = () =>
  //   postToFunctionsWithDefaultData({
  //     path: 'mtr-changeActivation',
  //     data: {
  //       shouldDeactivate: true,
  //       reasons: reasonToCancelSubscription,
  //       userId: adminUserId,
  //       mtrCode,
  //       ...(commentForCancelingSubscription
  //         ? { comment: commentForCancelingSubscription }
  //         : {}),
  //     },
  //   });

  // const authorizeUser = async () => {
  //   if (verificationCode.trim() === '') {
  //     setVerificationCodeError('Verification code is required!');
  //     return false;
  //   }

  //   setLoaderText('Verifying...');
  //   setCurrentStep(steps.loader);
  //   try {
  //     const verificationRequest = await postToFunctionsWithDefaultData({
  //       path: 'verifications-manageSubsAuthenticateWithCode',
  //       data: {
  //         verificationCode,
  //         userId: customerDetails.id,
  //         remoteAdminId: adminUserId,
  //         mtrCode,
  //       },
  //     });

  //     if (verificationRequest.result) {
  //       fbTokenRef.current = verificationRequest.fbtoken;
  //       return true;
  //     }

  //     setCurrentStep(steps.verificationFailed);
  //     return false;
  //   } catch (err) {
  //     setCurrentStep(steps.verificationFailed);
  //     return false;
  //   }
  // };

  // const onCancelSubscriptionAfterVerification = async () => {
  //   if (await authorizeUser()) {
  //     try {
  //       setLoaderText('Canceling subscription...');
  //       await cancelSubscription();
  //       setCurrentStep(steps.subscriptionWasCanceled);
  //     } catch {
  //       setErrorContainer({
  //         msg: 'Failed to cancel subscription',
  //       });
  //     }
  //   }
  // };

  const onTryVerificationAgain = () => {
    setCurrentStep(steps.verification);
    logSubscriptionManagementEvent({
      name: 'on_send_me_another_code_btn_click',
    });
  };

  const resumePlan = async () => {
    setIsLoadingResume(true);
    try {
      const response = await postToFunctionsWithDefaultData({
        path: 'mtr-unpauseSubscription',
        data: {
          mtrCode,
          userId: customerDetails.id,
        },
      });
      if (response.result) {
        setIsLoadingResume(false);
        // eslint-disable-next-line no-undef, no-restricted-globals
        location.reload();
      } else {
        setIsLoadingResume(false);
      }
    } catch (e) {
      setIsLoadingResume(false);
    }
  };

  const calcCancelationDiscountText = () => {
    const planToDisplay = currentPlan.nextPlanDetails || currentPlan;
    if (cancelationDiscount.type === 'percent') {
      return (
        Math.round(
          planToDisplay.price * (1 - cancelationDiscount.value / 100) * 100
        ) / 100
      );
    }
    return (
      Math.round((planToDisplay.price - cancelationDiscount.value) * 100) / 100
    );
  };

  const onStopSubscription = () => {
    setSelectedAction(null);
    setSelectedActionExtraInfo(null);
    logSubscriptionManagementEvent({
      name: 'on_stop_subscription_btn_click',
      data: {
        currentStep,
      },
    });
    // const tryCancelSubscription = async () => {
    //   initVerification({ reason: verificationReasons.cancelSubscription });
    // };
    if (currentStep === steps.cancelSubscriptionConfirm) {
      // if (
      //   currentPlan.deactivationOptions
      //     .isEligibleForDeactivationAlternativeDiscount ||
      //   currentPlan.deactivationOptions.isEligibleToByPassTrainingMonths ||
      //   currentPlan.deactivationOptions.isEligibleToPauseSubscription
      // ) {
      //   if (currentPlan.deactivationOptions.isEligibleToByPassTrainingMonths) {
      //     const { planOptions } = plans[planTypes.continuityPlans];
      //     const suggestedPlan = planOptions.find(
      //       plan =>
      //         plan.billingPeriodInMonths === currentPlan.billingPeriodInMonths
      //     );
      //     setSuggestedContinuityPlanBeforeDeactivation(suggestedPlan);
      //   }
      //   if (currentPlan.deactivationOptions.isEligibleToPauseSubscription) {
      //     setPauseDaysCredit(currentPlan.pauseDaysCredit);
      //   }
      //   setCurrentStep(steps.cancelDiscount);
      // } else {
      //   setCurrentStep(steps.cancelSubscriptionReasons);
      // }
      // setCurrentStep(steps.cancelSubscriptionReasons);
      window.open(
        !environmentConfig.isDev
          ? `https://myndlift.typeform.com/to/YOQxAW4b#fbtoken=${
              getCurrentURLParams().fbtoken
            }&mtrCode=${getCurrentURLParams().mtrCode}&customerId=${
              getCurrentURLParams().customerId
            }&adminUserId=${getCurrentURLParams().adminUserId}`
          : `https://myndlift.typeform.com/to/wA1xS48j#fbtoken=${
              getCurrentURLParams().fbtoken
            }&mtrCode=${getCurrentURLParams().mtrCode}&customerId=${
              getCurrentURLParams().customerId
            }&adminUserId=${getCurrentURLParams().adminUserId}`,
        '_self'
      );
    }
    // if (currentStep === steps.cancelSubscriptionReasons) {
    //   if (reasonToCancelSubscription === null) {
    //     setReasonsError(
    //       'Please select one of reasons for canceling your subscription'
    //     );
    //   } else {
    //     setReasonsError(null);
    //     if (reasonToCancelSubscription === cancelSubscriptionReasons.other) {
    //       if (
    //         commentForCancelingSubscription === null ||
    //         commentForCancelingSubscription.trim() === ''
    //       ) {
    //         setCommentForCancelingSubscriptionError(
    //           'Please provide the specific reason for canceling your subscription'
    //         );
    //       } else {
    //         setCommentForCancelingSubscriptionError(null);
    //         tryCancelSubscription();
    //       }
    //     } else {
    //       tryCancelSubscription();
    //     }
    //   }
    // }
  };

  const CurrentSubscriptionCard = () => {
    const subscriptionExtraInfo = (() => {
      const { nextPlanDetails, scheduledPlanDetails } = currentPlan;

      if (currentPlan.deactivationRequested) {
        return <>Your plan won't renew.</>;
      }

      if (currentPlan.isPaused) {
        return (
          <>
            Subscription is paused until{' '}
            {dayJS(currentPlan.expiration).format(
              DAY_JS_DATE_FORMATS.namedMonthDayYear
            )}
            {'. '}
            To manage your subscription, please resume it first.
          </>
        );
      }

      if (nextPlanDetails) {
        const formattedNextPlanType =
          formattedPlanTypes[nextPlanDetails.planType];
        const nextPlanNameBasedOnBillingPeriod = getPlanTypeByBillingPeriodInMonths(
          { billingPeriodInMonths: nextPlanDetails.billingPeriodInMonths }
        );

        return (
          <>
            Your plan will switch to {formattedNextPlanType}{' '}
            {nextPlanNameBasedOnBillingPeriod} on {currentPlanRenewalDate} and
            you will be charged ${nextPlanDetails.price} then.{' '}
          </>
        );
      }

      if (scheduledPlanDetails) {
        const continuityDate = dayJS(scheduledPlanDetails.start).format(
          DAY_JS_DATE_FORMATS.namedMonthDayYear
        );
        const continuityPrice = scheduledPlanDetails.price;

        return (
          <>
            Your Core plan is set to renew on {currentPlanRenewalDate}, with a
            charge of ${currentPlan.price}. Upon becoming eligible on{' '}
            {continuityDate}, you will transition to Continuity Training, and
            charges will commence at ${continuityPrice} from that point forward.
          </>
        );
      }

      return (
        <>
          Your plan will renew on {currentPlanRenewalDate} and you will be
          charged ${currentPlan.price}.{' '}
        </>
      );
    })();

    const discountInfo =
      currentPlan.discount && currentPlan.discount.duration === 'once'
        ? `${currentPlan.discount.value}${
            currentPlan.discount.type === 'percent' ? '%' : '$'
          } off discount applied (one-time only)`
        : '';

    if (shouldRenderNewPlans) {
      const isContinuity = currentPlan.planType === 'continuityPlans';
      const isMonthly = currentPlan.billingPeriodInMonths === 1;
      const shouldReadFromNextPlan = !!currentPlan.nextPlanDetails;
      const nextPlan = shouldReadFromNextPlan
        ? currentPlan.nextPlanDetails
        : currentPlan.scheduledPlanDetails;
      const isMonthlyNextPlan =
        nextPlan && nextPlan.billingPeriodInMonths === 1;

      const discountInfoNextPlan =
        nextPlan && nextPlan.discount && nextPlan.discount.duration === 'once'
          ? `${nextPlan.discount.value}${
              nextPlan.discount.type === 'percent' ? '%' : '$'
            } off discount applied (one-time only)`
          : '';

      if (currentPlan.isPaused) {
        return (
          <div className={styles.current_subscription_card}>
            <SubscriptionCard
              label="Current subscription plan:"
              title={
                <>
                  {formattedPlanTypes[currentPlan.planType]},{' '}
                  {currentPlanNameBasedOnPeriod}
                </>
              }
              subTitle={<>(${currentPlanPricePerMonth}/month)</>}
              extraInfo={subscriptionExtraInfo}
              discountInfo={discountInfo}
            />
          </div>
        );
      }

      if (isContinuity) {
        return (
          <div className={styles.current_subscription_card}>
            <SubscriptionCard
              label="Current subscription plan:"
              title={<>{`${currentPlan.planTypeName}, Monthly`}</>}
              subTitle={
                <>{`($${currentPlan.price}${isMonthly ? '/mo' : ''})`}</>
              }
              extraInfo={
                <>
                  {currentPlan.deactivationRequested
                    ? "Your plan won't renew."
                    : `Your plan will renew on ${dayJS(
                        currentPlan.expiration
                      ).format(
                        DAY_JS_DATE_FORMATS.namedMonthDayYear
                      )} and you will be charged $${currentPlan.price}/mo.`}
                </>
              }
              discountInfo={discountInfo}
            />
          </div>
        );
      }

      return (
        <div>
          <div className={styles.current_subscription_card}>
            <SubscriptionCard
              label="Current subscription plan:"
              title={
                <>
                  {`${currentPlan.commitmentInMonths} Month Membership`},{' '}
                  {isMonthly ? 'Monthly' : 'Upfront'}
                </>
              }
              subTitle={
                <>{`($${currentPlan.price}${isMonthly ? '/mo' : ''})`}</>
              }
              extraInfo={
                <>
                  {// eslint-disable-next-line no-nested-ternary
                  !nextPlan &&
                  currentPlan.expiration === currentPlan.commitmentEndTimestamp
                    ? null
                    : isMonthly
                    ? `Your next payment is on ${dayJS(
                        currentPlan.expiration
                      ).format(
                        DAY_JS_DATE_FORMATS.namedMonthDayYear
                      )} and you will be charged $${currentPlan.price}/mo. ${
                        currentPlan.paymentNumber
                          ? `This payment is ${currentPlan.paymentNumber} out of ${currentPlan.commitmentInMonths} total payments.`
                          : ''
                      }`
                    : `Your plan will end on ${dayJS(
                        currentPlan.expiration
                      ).format(
                        DAY_JS_DATE_FORMATS.namedMonthDayYear
                      )} and will be switched to ${
                        nextPlan.planTypeName
                      } Monthly.`}
                  {!nextPlan && (
                    <>
                      <br />
                      Your plan won’t renew
                    </>
                  )}
                </>
              }
              discountInfo={discountInfo}
            />
          </div>
          {currentStep !== steps.selectAction && nextPlan && (
            <div className={styles.current_subscription_card}>
              <SubscriptionCard
                label="Upcoming subscription plan:"
                title={
                  <>
                    {`${nextPlan.planTypeName}`},{' '}
                    {isMonthlyNextPlan ? 'Monthly' : 'Upfront'}
                  </>
                }
                subTitle={
                  <>{`($${nextPlan.price}${isMonthlyNextPlan ? '/mo' : ''})`}</>
                }
                extraInfo={`Your plan will switch to ${
                  nextPlan.planTypeName
                } Monthly (with no Neuro Coach calls included) on ${dayJS(
                  shouldReadFromNextPlan
                    ? currentPlan.expiration
                    : nextPlan.start
                ).format(
                  DAY_JS_DATE_FORMATS.namedMonthDayYear
                )} and you will be charged $${
                  nextPlan.price
                }/mo. Billed monthly, no commitment.`}
                discountInfo={discountInfoNextPlan}
              />
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={styles.current_subscription_card}>
        <SubscriptionCard
          label="Current subscription plan:"
          title={
            <>
              {formattedPlanTypes[currentPlan.planType]},{' '}
              {currentPlanNameBasedOnPeriod}
            </>
          }
          subTitle={<>(${currentPlanPricePerMonth}/month)</>}
          extraInfo={subscriptionExtraInfo}
          discountInfo={discountInfo}
        />
      </div>
    );
  };

  const renderSubscriptionInfoStep = () => {
    const animationClass = styles.slide_in_left;
    const calcBtnText = () => {
      if (isLoadingResume) {
        return (
          <div style={{ minWidth: 150 }}>
            <CircularProgress size={18} color="#ffffff" />
          </div>
        );
      }
      if (currentPlan.isPaused) {
        return 'RESUME SUBSCRIPTION';
      }
      if (shouldRenderNewPlans) {
        return 'MANAGE SUBSCRIPTION';
      }
      return 'Manage subscription';
    };

    return (
      <div
        className={classNames(styles.subscription_info_step, animationClass)}
      >
        <div className={styles.customer_profile}>
          <div className={styles.circle}>
            <img
              src={
                customerDetails.gender === 'Male'
                  ? museOnMaleHeadImage
                  : museOnFemaleHeadImage
              }
            />
          </div>
          <div>
            <p className={styles.name}>{customerDetails.name}</p>
            <p className={styles.email}>{customerDetails.email}</p>
          </div>
        </div>
        <CurrentSubscriptionCard />
        <OutstandingPaymentErrorDialog
          isOpen={isErrorDialogOpen}
          amount={currentPlan.outstandingPaymentsAmount}
          onCancel={() => {
            setIsErrorDialogOpen(false);
          }}
          onConfirm={() => {
            setIsErrorDialogOpen(false);
            window.open(
              `/mtr-retry-invoices.html${window.location.search}&isFromManageSubs=true`,
              '_self'
            );
          }}
          onRetry={() => {
            setIsErrorDialogOpen(false);
            onRetryPaymentClicked();
          }}
        />

        {!currentCardInfo ? (
          <div className={styles.btn_container}>
            <button
              type="button"
              onClick={() => {
                createOnEditPaymentMethod(steps.subscriptionInfo)();
              }}
              className={styles.change_subscription_btn}
            >
              Add credit card
            </button>
          </div>
        ) : (
          <PaymentDetails
            onEditPaymentMethod={createOnEditPaymentMethod(
              steps.subscriptionInfo
            )}
            currentCardInfo={currentCardInfo}
          />
        )}
        <div>
          <div className={styles.btn_container}>
            <button
              type="button"
              onClick={() => {
                if (
                  currentPlan.accessBlockType &&
                  currentPlan.outstandingPaymentsAmount !== undefined
                ) {
                  setIsErrorDialogOpen(true);
                } else {
                  if (currentPlan.isPaused) {
                    resumePlan();
                    return;
                  }
                  setCurrentStep(steps.selectAction);
                  logSubscriptionManagementEvent({
                    name: 'on_change_subscription_plan_btn_click',
                  });
                }
              }}
              className={styles.change_subscription_btn}
            >
              <span>{calcBtnText()}</span>
            </button>
          </div>
          {currentPlan.deactivationOptions.isEligibleToPauseSubscription ? (
            <div className={styles.btn_container}>
              <button
                type="button"
                onClick={() => {
                  setCurrentStep(steps.pauseSubscription);
                }}
                className={styles.change_subscription_btn_secondary}
              >
                <span>Pause for up to {pauseDaysCredit} days</span>
              </button>
            </div>
          ) : null}

          {currentPlan.deactivationOptions.isEligibleToByPassTrainingMonths &&
          !(currentPlan.planType === 'continuityPlans') &&
          !(
            currentPlan.nextPlanDetails &&
            currentPlan.nextPlanDetails.planType === 'continuityPlans'
          ) &&
          !currentPlan.isPaused &&
          !(
            currentPlan.scheduledPlanDetails &&
            currentPlan.scheduledPlanDetails.planType === 'continuityPlans'
          ) ? (
            <div className={styles.btn_container}>
              <button
                type="button"
                onClick={() => {
                  setCurrentStep(steps.downgradeToContinuity);
                  setIsDowngradeStepFromMainScreen(true);
                }}
                className={styles.change_subscription_btn_secondary}
              >
                <span>
                  Switch to continuity ($
                  {suggestedContinuityPlanBeforeDeactivation &&
                    suggestedContinuityPlanBeforeDeactivation.price}
                  /mo)
                </span>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const calcCancelExtraInfoText = (
    isContinuity,
    isMonthly,
    newPlansExpiration,
    currentPlanRenewalDateText
  ) =>
    // eslint-disable-next-line no-nested-ternary
    isContinuity && currentPlan.commitmentEndTimestamp ? (
      <p>
        {`You can keep your access to Myndlift until ${dayJS(
          currentPlan.commitmentEndTimestamp
        ).format(
          DAY_JS_DATE_FORMATS.namedMonthDayYear
        )} and your plan will not be renewed.`}
      </p>
    ) : shouldRenderNewPlans ? (
      <p>
        {isMonthly ? (
          <div>
            Your monthly membership has a commitment of{' '}
            {
              plans[currentPlan.planType].planOptions[currentPlan.packageIndex]
                .commitmentInMonths
            }{' '}
            months. The monthly charge will continue with app access until{' '}
            {newPlansExpiration} and your subscription will get canceled.
          </div>
        ) : (
          <div>
            If you select this option, you’ll still have access to Myndlift
            until {newPlansExpiration} and your subscription will get canceled.
          </div>
        )}
        <div style={{ marginTop: 10 }}>
          For further assistance, please contact the Myndlift Care team via chat
          or email.
        </div>
      </p>
    ) : (
      <p>
        You can keep your access to Myndlift only until{' '}
        {currentPlanRenewalDateText} and your plan will not be renewed.
      </p>
    );

  const renderSelectActionStep = () => {
    const isBtnDisabled =
      // (selectedAction === actionIds.switchToContinuityPlan &&
      //   !isEligibleForContinuityPlan) ||
      selectedAction === actionIds.cancelSubscription &&
      currentPlan.deactivationRequested;

    const btnText = (() => {
      if (
        selectedAction === actionIds.cancelSubscription ||
        selectedAction === actionIds.cancelDeactivation
      ) {
        return 'Continue';
      }

      if (
        selectedAction === actionIds.switchToContinuityPlan &&
        !isEligibleForContinuityPlan
      ) {
        const availabilityDate = dayJS(
          currentPlan.eligibleToSwitchToContinuityTimestamp
        ).format(DAY_JS_DATE_FORMATS.namedMonthDayYear);
        return `Will be available for you after ${availabilityDate}`;
      }

      return 'Review plan';
    })();

    const animationClass = styles.slide_in_left;
    const isDeactivatedNewPlans = !!currentPlan.deactivationRequested;
    const nextPlanType =
      currentPlan.nextPlanDetails && currentPlan.nextPlanDetails.planType;
    // eslint-disable-next-line no-nested-ternary
    const actionsToShow = !shouldRenderNewPlans
      ? actions.filter(
          action =>
            !(
              action.value === actionIds.cancelSubscription &&
              currentPlan.deactivationRequested
            ) &&
            !(
              (currentPlan.planType === 'continuityPlans' ||
                (currentPlan.scheduledPlanDetails &&
                  currentPlan.scheduledPlanDetails.planType) ===
                  'continuityPlans' ||
                nextPlanType === 'continuityPlans') &&
              action.value === actionIds.switchToContinuityPlan
            )
        )
      : isDeactivatedNewPlans
      ? newPlansDeactivatedActions
      : newPlansActions;
    if (!shouldRenderNewPlans && currentPlan.deactivationRequested) {
      actionsToShow.push(newPlansDeactivatedActions[0]);
    }
    const isMonthly = currentPlan.billingPeriodInMonths === 1;
    const newPlansExpiration = dayJS(currentPlan.commitmentEndTimestamp).format(
      DAY_JS_DATE_FORMATS.namedMonthDayYear
    );

    return (
      <div className={classNames(styles.select_action_step, animationClass)}>
        <div className={styles.customer_profile}>
          <div className={styles.circle}>
            <img
              src={
                customerDetails.gender === 'Male'
                  ? museOnMaleHeadImage
                  : museOnFemaleHeadImage
              }
            />
          </div>
          <p className={styles.name}>{customerDetails.name}</p>
        </div>
        <CurrentSubscriptionCard />
        <div className={styles.options}>
          <RadioGroup
            value={selectedAction}
            onChange={event => {
              const newSelectedAction = event.target.value;
              logSubscriptionManagementEvent({
                name: 'on_action_select',
                data: {
                  newActionId: newSelectedAction,
                },
              });
              if (newSelectedAction === actionIds.cancelSubscription) {
                const isContinuity = currentPlan.planType === 'continuityPlans';
                setSelectedActionExtraInfo(
                  calcCancelExtraInfoText(
                    isContinuity,
                    isMonthly,
                    newPlansExpiration,
                    currentPlanRenewalDate
                  )
                );
              } else if (
                newSelectedAction === actionIds.switchToContinuityPlan
              ) {
                setSelectedActionExtraInfo(
                  <>
                    <p>
                      If you opt for this choice,{' '}
                      <span className={styles.bold}>
                        your existing plan will end on{' '}
                        {// eslint-disable-next-line no-nested-ternary
                        !isEligibleForContinuityPlan
                          ? dayJS(
                              currentPlan.eligibleToSwitchToContinuityTimestamp
                            ).format(DAY_JS_DATE_FORMATS.namedMonthDayYear)
                          : currentPlan.scheduledPlanDetails
                          ? dayJS(
                              currentPlan.scheduledPlanDetails.start
                            ).format(DAY_JS_DATE_FORMATS.namedMonthDayYear)
                          : currentPlanRenewalDate}
                        .{' '}
                      </span>
                      After that, you will transition to the Continuity plan,
                      which does not incorporate Neuro Coach sessions, priced at
                      $
                      {suggestedContinuityPlanBeforeDeactivation &&
                        suggestedContinuityPlanBeforeDeactivation.price}{' '}
                      per month.
                    </p>
                    <p>
                      You’re eligible to enroll in this plan only once you’ve
                      completed{' '}
                      <span className={styles.bold}>
                        at least three months of Neuro Coach supervised training
                      </span>{' '}
                      (core period).
                    </p>
                  </>
                );
              } else if (newSelectedAction === actionIds.cancelDeactivation) {
                setSelectedActionExtraInfo(
                  <>
                    <p>
                      By selecting this option, your subscription will
                      reactivate.
                    </p>
                    {currentPlan.commitmentEndTimestamp && postMembershipPlan && (
                      <p>
                        {`Your plan will switch to ${
                          plans[postMembershipPlan.planType].planTypeName
                        } 
                      (with no Neuro Coach calls included) on 
                      ${dayJS(currentPlan.commitmentEndTimestamp).format(
                        DAY_JS_DATE_FORMATS.namedMonthDayYear
                      )}
                      and you will be charged 
                      $${
                        plans[postMembershipPlan.planType].planOptions[
                          postMembershipPlan.planInd
                        ].price
                      }/mo. 
                      Billed monthly, no commitment.`}
                      </p>
                    )}
                  </>
                );
              } else {
                setSelectedActionExtraInfo(null);
              }
              setSelectedAction(newSelectedAction);
            }}
          >
            {actionsToShow.map(option => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    classes={{
                      checked: styles.radio_checked,
                      root: styles.radio_root,
                    }}
                  />
                }
                label={option.label}
                classes={{
                  label: styles.radio_label,
                  root: classNames(styles.label_root, {
                    [styles.active]: selectedAction === option.value,
                  }),
                }}
              />
            ))}
          </RadioGroup>
        </div>
        {selectedActionExtraInfo && (
          <div className={styles.extra_text}>{selectedActionExtraInfo}</div>
        )}
        {selectedAction && (
          <div className={styles.btn_container}>
            <button
              type="button"
              onClick={onSelectAction}
              className={classNames({ [styles.disabled]: isBtnDisabled })}
            >
              <span>{btnText}</span>
            </button>
          </div>
        )}
      </div>
    );
  };

  const calcUnPauseDate = pauseDaysCredits => {
    const date = Date.now();
    const dayInMS = 24 * 60 * 60 * 1000;
    return dayJS(date + dayInMS * pauseDaysCredits).format(
      DAY_JS_DATE_FORMATS.namedMonthDayYear
    );
  };

  const renderCancelDiscountStep = () => {
    const isBtnDisabled = isLoadinCancelationDiscount;

    const btnText = isLoadinCancelationDiscount ? (
      <CircularProgress size={18} color="#ffffff" />
    ) : (
      'Continue'
    );

    const animationClass = styles.slide_in_left;

    const actionsToShow = cancelDiscountActions;

    const isContinuity = currentPlan.planType === 'continuityPlans';
    const isMonthly = currentPlan.billingPeriodInMonths === 1;

    const newPlansExpiration = dayJS(currentPlan.commitmentEndTimestamp).format(
      DAY_JS_DATE_FORMATS.namedMonthDayYear
    );

    return (
      <div className={classNames(styles.select_action_step, animationClass)}>
        {/* <CurrentSubscriptionCard /> */}
        <div className={styles.cancel_discount_top}>
          {/* <img
            src={undrawChooseImage}
            className={styles.cancel_discount_image}
            width={150}
            height={145}
          /> */}
          <p
            style={{
              fontSize: 18,
              fontWeight: 900,
              color: '#767676',
              textAlign: 'center',
              marginBottom: 20,
            }}
          >
            {currentPlan.deactivationOptions.isEligibleToPauseSubscription
              ? 'How about pausing your subscription or trying an offer instead?'
              : 'Instead of canceling, how about trying one of these offers?'}
          </p>
          {/* <p className={styles.info}>
            We’d like to offer you options to help you continue to use Myndlift!
          </p> */}
        </div>
        <div className={styles.options}>
          <RadioGroup
            value={selectedAction}
            onChange={event => {
              const newSelectedAction = event.target.value;
              logSubscriptionManagementEvent({
                name: 'on_action_select',
                data: {
                  newActionId: newSelectedAction,
                },
              });
              // setSelectedActionExtraInfo(null);
              setSelectedAction(newSelectedAction);
              if (newSelectedAction === actionIds.cancelSubscriptionDiscount) {
                setSelectedActionExtraInfo(
                  calcCancelExtraInfoText(
                    isContinuity,
                    isMonthly,
                    newPlansExpiration,
                    currentPlanRenewalDate
                  )
                );
              }
              if (
                newSelectedAction === actionIds.keepSubscriptionWithDiscount
              ) {
                setSelectedActionExtraInfo(
                  <p>
                    {`You will be charged $${calcCancelationDiscountText()} instead of $${
                      currentPlan.nextPlanDetails
                        ? currentPlan.nextPlanDetails.price
                        : currentPlan.price
                    } on ${currentPlanRenewalDate}.`}
                  </p>
                );
              }
              if (newSelectedAction === actionIds.switchToContinuityPlan) {
                setSelectedActionExtraInfo(
                  <p>
                    {`You will be charged $${
                      suggestedContinuityPlanBeforeDeactivation
                        ? suggestedContinuityPlanBeforeDeactivation.price
                        : 0
                    } instead of $${
                      currentPlan.nextPlanDetails
                        ? currentPlan.nextPlanDetails.price
                        : currentPlan.price
                    } on ${currentPlanRenewalDate}.`}
                  </p>
                );
              }
              if (newSelectedAction === actionIds.pauseSubscription) {
                setSelectedActionExtraInfo(
                  <p>
                    {`Your subscription will be paused until ${calcUnPauseDate(
                      currentPlan.pauseDaysCredit
                    )}. You can resume at anytime from the train tab.`}
                  </p>
                );
              }
            }}
          >
            {actionsToShow.map(option => {
              if (
                option.value === actionIds.keepSubscriptionWithDiscount &&
                !currentPlan.deactivationOptions
                  .isEligibleForDeactivationAlternativeDiscount
              ) {
                return null;
              }
              if (
                option.value === actionIds.switchToContinuityPlan &&
                !currentPlan.deactivationOptions
                  .isEligibleToByPassTrainingMonths
              ) {
                return null;
              }
              if (
                option.value === actionIds.pauseSubscription &&
                !currentPlan.deactivationOptions.isEligibleToPauseSubscription
              ) {
                return null;
              }
              return (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={
                    <Radio
                      classes={{
                        checked: styles.radio_checked,
                        root: styles.radio_root,
                      }}
                    />
                  }
                  label={option.label}
                  classes={{
                    label: styles.radio_label,
                    root: classNames(styles.label_root, {
                      [styles.active]: selectedAction === option.value,
                    }),
                  }}
                />
              );
            })}
          </RadioGroup>
        </div>
        {selectedActionExtraInfo && (
          <div className={styles.extra_text}>{selectedActionExtraInfo}</div>
        )}
        {selectedAction && (
          <div className={styles.btn_container}>
            <button
              type="button"
              onClick={() => {
                if (isBtnDisabled) {
                  return;
                }
                onSelectAction();
              }}
              className={classNames({ [styles.disabled]: isBtnDisabled })}
            >
              <span>{btnText}</span>
            </button>
          </div>
        )}
      </div>
    );
  };

  const getPlanInReviewDetails = () => {
    const planInReviewBillingPeriodInMonths =
      planInReview.billingPeriodInMonths;
    // const planInReviewBillingPeriodInWeeks =
    //   planInReviewBillingPeriodInMonths * 4;
    const planInReviewPricePerMonth = getPlanPriceByMonth({
      price: planInReview.price,
      billingPeriodInMonths: planInReviewBillingPeriodInMonths,
    });
    const planInReviewNameBasedOnBillingPeriod = getPlanTypeByBillingPeriodInMonths(
      { billingPeriodInMonths: planInReviewBillingPeriodInMonths }
    );
    const planInReviewRenewalDate = dayJS()
      .add(planInReviewBillingPeriodInMonths, 'month')
      .format(DAY_JS_DATE_FORMATS.namedMonthDayYear);

    return {
      planInReviewBillingPeriodInMonths,
      // planInReviewBillingPeriodInWeeks,
      planInReviewPricePerMonth,
      planInReviewNameBasedOnBillingPeriod,
      planInReviewRenewalDate,
      planInReviewPrice: planInReview.price,
      planInReviewFormattedType:
        formattedPlanTypes[planTypesToReview] || planTypesToReview,
    };
  };

  const renderReviewActionStep = () => {
    const [monthlyPlan] = plans[planTypesToReview].planOptions;
    const onChangePlanInReview = newPlan => {
      logSubscriptionManagementEvent({
        name: 'on_change_plan',
        data: {
          planBillingPeriodInMonths: newPlan.billingPeriodInMonths,
          planType: planTypesToReview,
        },
      });
      setPlanInReview(newPlan);
    };

    const {
      planInReviewBillingPeriodInMonths,
      // planInReviewBillingPeriodInWeeks,
      planInReviewPricePerMonth,
      planInReviewNameBasedOnBillingPeriod,
      planInReviewRenewalDate,
      planInReviewFormattedType,
    } = getPlanInReviewDetails();

    const unusedTimeForCurrentPlan = currentPlan.expiration - currentPlan.start;

    const unusedTimeForCurrentPlanInMonths = dayJS
      .duration(unusedTimeForCurrentPlan)
      .asMonths();

    const creditBasedOnUnusedTime = parseFloat(
      (unusedTimeForCurrentPlanInMonths / currentPlanPricePerMonth).toFixed(2),
      10
    );

    const {
      isSwitchingToContinuity,
      isUpgrading,
    } = getPlanSwitchingInformation({
      currentPlanPrice: currentPlan.price,
      currentPlanType: currentPlan.planType,
      nextPlanPrice: planInReview.price,
      nextPlanType: planTypesToReview,
    });

    const extraDescription = (() => {
      if (isSwitchingToContinuity) {
        return (
          <>
            <p>
              You will stay on your {formattedCurrentPlanType} plan until it
              ends on{' '}
              {currentPlan.eligibleToSwitchToContinuityTimestamp
                ? dayJS(
                    currentPlan.eligibleToSwitchToContinuityTimestamp
                  ).format(DAY_JS_DATE_FORMATS.namedMonthDayYear)
                : currentPlanRenewalDate}
              .
            </p>
            <p>
              The continuity plan will take effect on{' '}
              {currentPlan.eligibleToSwitchToContinuityTimestamp
                ? dayJS(
                    currentPlan.eligibleToSwitchToContinuityTimestamp
                  ).format(DAY_JS_DATE_FORMATS.namedMonthDayYear)
                : currentPlanRenewalDate}{' '}
              and you will be charged ${planInReview.price} then.
            </p>
          </>
        );
      }
      if (isUpgrading) {
        return (
          <>
            <p>
              You authorize Myndlift to charge you for the amount above. The
              next charge will be for ${planInReview.price} on{' '}
              {planInReviewRenewalDate}.
            </p>
            {planInReviewBillingPeriodInMonths === 1 ? (
              <p>Your subscription renews every month until you cancel.</p>
            ) : (
              <p>
                Your subscription renews every{' '}
                {planInReviewBillingPeriodInMonths} months until you cancel.
              </p>
            )}
          </>
        );
      }

      return (
        <>
          <p>
            You will stay on your {formattedCurrentPlanType}{' '}
            {currentPlanNameBasedOnPeriod} plan until it ends on{' '}
            {currentPlanRenewalDate}.{' '}
          </p>
          <p>
            The {planInReviewNameBasedOnBillingPeriod} plan will take effect on{' '}
            {currentPlanRenewalDate} and you will be charged $
            {planInReview.price} then.
          </p>
        </>
      );
    })();

    const animationClass = styles.slide_in_left;

    return (
      <div className={classNames(styles.review_action_step, animationClass)}>
        <div className={styles.program_overview}>
          {planTypesToReview !== planTypes.continuityPlans ? (
            <>
              <p>{formattedCurrentPlanType}</p>
              <p>
                Unlimited personalized training sessions, 1x monthly Neuro Coach
                consultations
              </p>
            </>
          ) : (
            <>
              <p>Continuity Training</p>
              <p>
                Unlimited personalized training session, on-demand Neuro Coach
                sessions ($75/call)
              </p>
            </>
          )}
        </div>
        <div className={styles.plans_container}>
          {[...plans[planTypesToReview].planOptions].reverse().map(plan => {
            const { billingPeriodInMonths, price } = plan;
            const planTypeByBillingPeriodInMonths = getPlanTypeByBillingPeriodInMonths(
              {
                billingPeriodInMonths,
              }
            );
            const planPricePerMonth = getPlanPriceByMonth({
              price,
              billingPeriodInMonths,
            });

            const savings = getPlanSaving({
              price,
              billingPeriodInMonths,
              monthlyPlanPrice: monthlyPlan.price,
            });

            const isCurrentPlan =
              billingPeriodInMonths === currentPlan.billingPeriodInMonths &&
              currentPlan.planType === planTypesToReview;
            const isPlanInReview = plan === planInReview;

            const { nextPlanDetails } = currentPlan;
            const isNextPlan =
              nextPlanDetails &&
              billingPeriodInMonths === nextPlanDetails.billingPeriodInMonths &&
              planTypesToReview === nextPlanDetails.planType;

            return (
              <div className={styles.plan_container} key={plan.stripeSubId}>
                {isCurrentPlan && (
                  <p className={styles.current_plan_label}>Current</p>
                )}
                {isNextPlan && (
                  <div className={styles.next_plan_label}>
                    <p>Starting from</p>
                    <p>{currentPlanRenewalDate}</p>
                  </div>
                )}
                <div
                  className={classNames(styles.plan, {
                    [styles.current]: isCurrentPlan || isNextPlan,
                    [styles.in_review_plan]: isPlanInReview,
                  })}
                  key={plan.stripeSubId}
                  onClick={
                    isCurrentPlan || isNextPlan
                      ? () => {}
                      : () => onChangePlanInReview(plan)
                  }
                >
                  <p className={styles.name}>
                    Pay {planTypeByBillingPeriodInMonths}
                  </p>
                  <p className={styles.price_per_week}>
                    ${planPricePerMonth}/month
                  </p>
                  {savings > 0 && (
                    <p className={styles.savings}>Save ${savings}</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <PaymentDetails
          onEditPaymentMethod={createOnEditPaymentMethod(steps.reviewAction)}
          currentCardInfo={currentCardInfo}
        />
        <div className={styles.summary}>
          <p className={styles.summary_label}>Summary</p>

          <div className={styles.summary_box}>
            <div className={styles.plan_info}>
              <p>
                {planInReviewFormattedType},{' '}
                {planInReviewNameBasedOnBillingPeriod} (
                {planInReviewBillingPeriodInMonths}x$
                {planInReviewPricePerMonth})
              </p>
              <p>${planInReview.price}</p>
            </div>
            {isUpgrading ? (
              <div className={styles.unused_time_current_plan}>
                <p>
                  Unused time on current plan ({currentPlanNameBasedOnPeriod})
                </p>
                <p className={styles.light_green}>
                  -${creditBasedOnUnusedTime}
                </p>
              </div>
            ) : (
              <div>
                <p>Will be charged when current plan ends</p>
              </div>
            )}
            <div className={styles.divider} />
            <div className={styles.amount_being_charged_today}>
              <p>Amount being charged today</p>
              {isUpgrading ? (
                <p>${planInReview.price - creditBasedOnUnusedTime}</p>
              ) : (
                <p>$0</p>
              )}
            </div>
          </div>
        </div>
        <div className={styles.action_extra_description}>
          {extraDescription}
        </div>
        <div className={styles.buttons_container}>
          <button type="button" onClick={onUpdateSubscription}>
            <span>UPDATE SUBSCRIPTION</span>
          </button>
          <p className={styles.cancel_btn} onClick={onBack}>
            cancel
          </p>
        </div>
      </div>
    );
  };

  const CancelSubscriptionTextWithIcon = () => (
    <div className={styles.text_image}>
      <img src={cancellationImage} />
      <p>Cancel your Myndlift subscription</p>
    </div>
  );

  const CancelSubscriptionButtons = () => (
    <div className={styles.buttons_container}>
      <button type="button" onClick={onKeepMyTraining}>
        KEEP MY TRAINING
      </button>
      <button
        type="button"
        className={styles.secondary}
        onClick={onStopSubscription}
      >
        STOP SUBSCRIPTION
      </button>
    </div>
  );

  const renderPauseSubscriptionStep = () => {
    const animationClass = styles.slide_in_left;
    return (
      <div
        className={classNames(
          styles.cancel_subscription_confirm_step,
          animationClass
        )}
      >
        <img src={womanPracticeMobileImage} style={{ width: '100vw' }} />
        <div className={styles.pause_subscription_step}>
          <div className={styles.pause_subscription_title}>
            How about pausing your subscription for up to {pauseDaysCredit}{' '}
            days?
          </div>
          <div className={styles.pause_subscription_description}>
            Take a break from your training hassle-free. Pause for up to 90 days
            and resume anytime. On{' '}
            {calcUnPauseDate(currentPlan.pauseDaysCredit)}, your subscription
            will automatically restart, and you'll be billed as usual.
          </div>
        </div>
        <div className={styles.buttons_container}>
          <button
            type="button"
            onClick={pauseSubscription}
            style={{ fontWeight: 600 }}
          >
            {isLoadinCancelationDiscount ? (
              <CircularProgress color="#ffffff" />
            ) : (
              'Pause my subscription'
            )}
          </button>
          <button
            type="button"
            className={styles.secondary}
            style={{ color: '#28d1bb', fontWeight: 600 }}
            onClick={() => {
              if (isLoadinCancelationDiscount) {
                return;
              }
              if (!selectedAction) {
                setCurrentStep(steps.subscriptionInfo);
              } else if (
                currentPlan.deactivationOptions.isEligibleToByPassTrainingMonths
              ) {
                setCurrentStep(steps.downgradeToContinuity);
              } else if (
                currentPlan.deactivationOptions
                  .isEligibleForDeactivationAlternativeDiscount
              ) {
                setCurrentStep(steps.discountBeforeReactivation);
              } else {
                setCurrentStep(steps.cancelSubscriptionConfirm);
              }
            }}
          >
            No, I'm not interested
          </button>
        </div>
      </div>
    );
  };

  const renderDowngradeToContinuityStepStep = () => {
    const animationClass = styles.slide_in_left;
    return (
      <div
        className={classNames(
          styles.cancel_subscription_confirm_step,
          animationClass
        )}
      >
        <img src={nerdWithHeadsetMobileImage} style={{ width: '100vw' }} />
        <div className={styles.pause_subscription_step}>
          <div className={styles.pause_subscription_title}>
            Keep your Myndlift access by switching to Continuity ($
            {suggestedContinuityPlanBeforeDeactivation.price}/mo)?
          </div>
          <div className={styles.pause_subscription_description}>
            <div className={styles.pause_subscription_description_p}>
              You'll still have full access to Myndlift, except for the Neuro
              Coach calls. This option is perfect if you're ready to fly solo
              without the Neuro Coach's guidance. If you ever need extra
              support, you can still book consultations for a fee.
            </div>
            {/* <div className={styles.pause_subscription_description_p}>
              It’s a great option if you feel you don’t need the supervision of
              the Neuro Coach anymore and can continue on your own.
            </div> */}
            <div className={styles.pause_subscription_description_p}>
              {isEligibleForContinuityPlan
                ? `Note: You will continue to be charged for your current plan until ${currentPlanRenewalDate}.`
                : `Note: You’re eligible to switch to this plan only once you’ve completed the three months of Neuro Coach supervised training (core period) on ${dayJS(
                    currentPlan.eligibleToSwitchToContinuityTimestamp
                  ).format(
                    DAY_JS_DATE_FORMATS.namedMonthDayYear
                  )}. Until then, you will continue to be charged for your current plan.`}
            </div>
          </div>
        </div>
        <div className={styles.buttons_container}>
          <button
            type="button"
            onClick={() => {
              const newPlanTypeToReview = planTypes.continuityPlans;
              setPlanTypesToReview(newPlanTypeToReview);
              const { nextPlanDetails } = currentPlan;
              const { planOptions } = plans[newPlanTypeToReview];
              const possiblePlanOptions = planOptions.filter(
                plan =>
                  plan.stripeSubId !== currentPlan.stripeSubId &&
                  (!nextPlanDetails ||
                    plan.stripeSubId !== nextPlanDetails.stripeSubId)
              );

              setPlanInReview(possiblePlanOptions[0]);
              setCurrentStep(steps.reviewAction);
              setIsContinuityTrainStepFromMainScreen(true);
            }}
            style={{ fontWeight: 600 }}
          >
            {isLoadinCancelationDiscount ? (
              <CircularProgress color="#ffffff" />
            ) : (
              'Switch to Continuity'
            )}
          </button>
          <button
            type="button"
            className={styles.secondary}
            style={{ color: '#28d1bb', fontWeight: 600 }}
            onClick={() => {
              if (isLoadinCancelationDiscount) {
                return;
              }
              if (isDowngradeStepFromMainScreen) {
                setCurrentStep(steps.subscriptionInfo);
                setIsDowngradeStepFromMainScreen(false);
                return;
              }
              if (
                currentPlan.deactivationOptions
                  .isEligibleForDeactivationAlternativeDiscount
              ) {
                setCurrentStep(steps.discountBeforeReactivation);
              } else {
                setCurrentStep(steps.cancelSubscriptionConfirm);
              }
            }}
          >
            No, I'm not interested
          </button>
        </div>
      </div>
    );
  };

  const renderDiscountBeforeReactivation = () => {
    const animationClass = styles.slide_in_left;
    const discountString =
      cancelationDiscount.type === 'percent'
        ? `${cancelationDiscount.value}%`
        : `$${cancelationDiscount.value}`;
    const planToDisplay = currentPlan.nextPlanDetails || currentPlan;
    return (
      <div
        className={classNames(
          styles.cancel_subscription_confirm_step,
          animationClass
        )}
      >
        <img src={girlHeadsetAppImage} style={{ width: '100vw' }} />
        <div className={styles.pause_subscription_step}>
          <div className={styles.pause_subscription_title}>
            One last chance offer: Get {discountString} off your next charge!
          </div>
          <div className={styles.pause_subscription_primary_description}>
            ${calcCancelationDiscountText()} instead of ${planToDisplay.price}.
          </div>
          <div className={styles.pause_subscription_description}>
            While we work to make Myndlift right for you, we’re happy to offer
            you a membership at a reduced rate.
          </div>
        </div>
        <div className={styles.buttons_container}>
          <button
            type="button"
            onClick={applyCancelationDiscount}
            style={{ fontWeight: 600 }}
          >
            {isLoadinCancelationDiscount ? (
              <CircularProgress color="#ffffff" />
            ) : (
              'Get 50% off next charge'
            )}
          </button>
          <button
            type="button"
            className={styles.secondary}
            style={{ color: '#28d1bb', fontWeight: 600 }}
            onClick={() => {
              if (isLoadinCancelationDiscount) {
                return;
              }
              setCurrentStep(steps.cancelSubscriptionConfirm);
            }}
          >
            Cancel my subscription
          </button>
        </div>
      </div>
    );
  };

  const renderCancelSubscriptionConfirmStep = () => {
    const animationClass = styles.slide_in_left;

    const newRenewalDate = shouldRenderNewPlans
      ? dayJS(currentPlan.commitmentEndTimestamp).format(
          DAY_JS_DATE_FORMATS.namedMonthDayYear
        )
      : currentPlanRenewalDate;

    return (
      <div
        className={classNames(
          styles.cancel_subscription_confirm_step,
          animationClass
        )}
      >
        <CancelSubscriptionTextWithIcon />
        <p className={styles.note_label}>
          If you decide to unsubscribe, it’s important to note that:
        </p>
        <ul className={styles.things_to_note}>
          <li>
            Your plan won’t renew and you won’t be charged on {newRenewalDate}
          </li>
          <li>You’ll still have access to Myndlift until {newRenewalDate}</li>
          <li>
            Once you lose access, you won’t be able to train, but you can
            re-activate anytime.
          </li>
        </ul>
        <CancelSubscriptionButtons />
      </div>
    );
  };

  const renderCancelSubscriptionReasonsStep = () => {
    const animationClass = styles.slide_in_left;
    return (
      <div
        className={classNames(
          styles.cancel_subscription_reasons_step,
          animationClass
        )}
      >
        <CancelSubscriptionTextWithIcon />
        <p className={styles.note_label}>We’re sad to see you go.</p>
        <p className={styles.question}>Can we ask why you’d like to cancel?</p>
        <div className={styles.reasons}>
          <FormControl error={!!reasonsError}>
            <RadioGroup
              value={reasonToCancelSubscription}
              onChange={event => {
                const newReason = event.target.value;
                setReasonToCancelSubscription(newReason);
                setReasonsError(null);
              }}
            >
              {/* eslint-disable-next-line no-unused-vars */}
              {Object.entries(cancelSubscriptionReasons).map(([key, value]) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={
                    <Radio
                      classes={{
                        checked: styles.radio_checked,
                        root: styles.radio_root,
                      }}
                    />
                  }
                  label={value}
                  classes={{
                    label: styles.radio_label,
                    root: classNames(styles.label_root, {
                      [styles.active]: reasonToCancelSubscription === value,
                    }),
                  }}
                />
              ))}
            </RadioGroup>
            {reasonsError && <FormHelperText>{reasonsError}</FormHelperText>}
          </FormControl>
        </div>
        <div className={styles.comments_container}>
          <p>Comments:</p>
          <FormControl
            // error={!!commentForCancelingSubscriptionError}
            fullWidth
          >
            <TextField
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              onChange={event => {
                setCommentForCancelingSubscription(event.target.value);
              }}
              value={commentForCancelingSubscription}
            />
            {/* {commentForCancelingSubscriptionError && (
              <FormHelperText>
                <span style={{ marginTop: 8 }}>
                  {commentForCancelingSubscriptionError}
                </span>
              </FormHelperText>
            )} */}
          </FormControl>
        </div>
        <CancelSubscriptionButtons />
      </div>
    );
  };

  const renderLoaderStep = () => {
    return (
      <div className={classNames(styles.loader_step, styles.slide_in_left)}>
        {loaderText && <p className={styles.loader_text}>{loaderText}</p>}
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  };

  const renderSubscriptionWasCanceledStep = () => {
    const isMonthly = currentPlan.billingPeriodInMonths === 1;
    const animationClass = styles.slide_in_left;
    return (
      <div className={animationClass}>
        <GenericSubscriptionUpdatedStep
          label="Current subscription plan:"
          title={
            // eslint-disable-next-line no-nested-ternary
            !shouldRenderNewPlans ? (
              <>
                {formattedCurrentPlanType}, {currentPlanNameBasedOnPeriod}
              </>
            ) : !currentPlan.commitmentInMonths && postMembershipPlan ? (
              <>
                {`${plans[postMembershipPlan.planType].planTypeName}, Monthly`}
              </>
            ) : (
              <>
                {`${currentPlan.commitmentInMonths} Month Membership`},{' '}
                {isMonthly ? 'Monthly' : 'Upfront'}
              </>
            )
          }
          extraInfo="Will not be renewed"
          onDoneBtnClick={onDoneBtnClick}
        />
      </div>
    );
  };

  const renderEditPaymentMethodStep = () => {
    const animationClass = styles.slide_in_left;
    return (
      <div className={classNames(styles.payment_step, animationClass)}>
        <div className={styles.inputs}>
          <div className={styles.stripe_element}>
            <CardElement
              onChange={onCreditCardChange}
              onReady={element => {
                creditCardElementRef.current = element;
              }}
              style={stripeElementStyle}
              hidePostalCode
            />
            <p className={styles.error}>{creditCardError}</p>
          </div>

          {stripeError && (
            <div className={styles.stripe_error}>
              <p>{stripeError}</p>
            </div>
          )}

          {creditCardUpdateNote && (
            <div className={styles.credit_card_update_note}>
              <p>{creditCardUpdateNote}</p>
            </div>
          )}
        </div>
        <div className={styles.buttons_container}>
          <button type="button" onClick={onUpdateCreditCard}>
            <span>Update Credit Card</span>
          </button>
          <p className={styles.cancel_btn} onClick={onBack}>
            cancel
          </p>
        </div>
      </div>
    );
  };

  const renderVerificationStep = () => {
    return (
      <div
        className={classNames(styles.verification_step, styles.slide_in_left)}
      >
        <p className={styles.note}>
          Before we complete your request, we sent a code to verify this change.
        </p>
        <p className={styles.info}>
          Please enter the 6 digit code that was sent to {adminEmail}
        </p>
        <div className={styles.input_container}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              fullWidth
              value={verificationCode}
              onChange={event => {
                setVerificationCode(event.target.value);
                if (event.target.value.trim() !== '') {
                  setVerificationCodeError(null);
                }
              }}
              error={!!verificationCodeError}
              helperText={verificationCodeError}
            />
          </FormControl>
        </div>

        <div className={styles.buttons_container}>
          <button
            type="button"
            className={styles.verify_btn}
            // onClick={
            //   // eslint-disable-next-line no-nested-ternary
            //   verificationReason === verificationReasons.cancelSubscription
            //     ? onCancelSubscriptionAfterVerification
            //     : verificationReason === verificationReasons.cancelDeavtivation
            //     ? onCancelDeactivationAfterVerification
            //     : onChangeSubscriptionAfterVerification
            // }
          >
            <span>Verify</span>
          </button>
          <p
            type="button"
            className={styles.send_me_another_code_btn}
            onClick={onSendAnotherVerificationCode}
          >
            <span>SEND ME ANOTHER CODE</span>
          </p>
        </div>
      </div>
    );
  };

  const renderVerificationFailedStep = () => {
    return (
      <div
        className={classNames(
          styles.verification_failed_step,
          styles.slide_in_left
        )}
      >
        <img src={failedImage} className={styles.failed_image} />
        <p className={styles.info}>Entered code was incorrect.</p>
        <div className={styles.btn_container}>
          <button type="submit" onClick={onTryVerificationAgain}>
            <span>TRY AGAIN</span>
          </button>
        </div>
      </div>
    );
  };

  const renderPaymentSucceededStep = () => {
    return (
      <div className={styles.slide_in_left}>
        <GenericOutstandingPaymentsUpdatedStep
          title="Thank you!"
          subTitle="All invoices have been paid"
          isSuccess
          onDoneBtnClick={() => {
            setCurrentStep(initialStep);
          }}
        />
      </div>
    );
  };

  const renderPaymentPartiallySucceededStep = () => {
    return (
      <div className={styles.slide_in_left}>
        <GenericOutstandingPaymentsUpdatedStep
          title="Oops!"
          subTitle={`Seems like some invoices are still unpaid, $${currentOutstandingPaymentAmount} (USD)`}
          isSuccess={false}
          btnLbl="Back"
          onDoneBtnClick={() => {
            setCurrentStep(initialStep);
          }}
        />
      </div>
    );
  };

  const renderSubscriptionUpgradedStep = () => {
    const {
      planInReviewNameBasedOnBillingPeriod,
      planInReviewPricePerMonth,
      planInReviewPrice,
      planInReviewRenewalDate,
      planInReviewFormattedType,
    } = getPlanInReviewDetails();

    const {
      isSwitchingToContinuity,
      isUpgrading,
    } = getPlanSwitchingInformation({
      currentPlanPrice: currentPlan.price,
      currentPlanType: currentPlan.planType,
      nextPlanPrice: planInReview.price,
      nextPlanType: planTypesToReview,
    });

    const label = isUpgrading
      ? 'Updated subscription plan:'
      : 'Current subscription plan:';
    let subTitle = isUpgrading ? (
      <>(${planInReviewPricePerMonth}/month)</>
    ) : (
      undefined
    );

    let extraInfo = (() => {
      if (isUpgrading) {
        return (
          <>
            Your plan will renew on {planInReviewRenewalDate} and you will be
            charged ${planInReviewPrice}.{' '}
          </>
        );
      }
      if (isSwitchingToContinuity) {
        return (
          <>
            Your plan will switch to Continuity Training on{' '}
            {currentPlan.eligibleToSwitchToContinuityTimestamp
              ? dayJS(currentPlan.eligibleToSwitchToContinuityTimestamp).format(
                  DAY_JS_DATE_FORMATS.namedMonthDayYear
                )
              : currentPlanRenewalDate}{' '}
            and you will be charged ${planInReviewPrice} then.{' '}
          </>
        );
      }
      return (
        <>
          Your plan will switch to {formattedCurrentPlanType}{' '}
          {planInReviewNameBasedOnBillingPeriod} on {currentPlanRenewalDate} and
          you will be charged ${planInReviewPrice} then.{' '}
        </>
      );
    })();

    let updatedPlanTitle = isUpgrading ? (
      <>
        {planInReviewFormattedType}, {planInReviewNameBasedOnBillingPeriod}
      </>
    ) : (
      <>
        {formattedCurrentPlanType}, {currentPlanNameBasedOnPeriod}
      </>
    );

    if (shouldRenderNewPlans) {
      // const shouldReadFromNextPlan = !!currentPlan.nextPlanDetails;
      // let nextPlan = shouldReadFromNextPlan
      //   ? currentPlan.nextPlanDetails
      //   : currentPlan.scheduledPlanDetails;
      const commitmentEndTimestamp = dayJS(
        planInReview.commitmentEndTimestamp
      ).format(DAY_JS_DATE_FORMATS.namedMonthDayYear);
      updatedPlanTitle = (
        <>
          {`${planInReview.commitmentInMonths} Month Membership`},{' '}
          {planInReview.billingPeriodInMonths === 1 ? 'Monthly' : 'Upfront'}
        </>
      );
      subTitle = <>{`($${planInReview.price}/mo)`}</>;
      extraInfo =
        planInReview.billingPeriodInMonths === 1
          ? `Your next payment is on ${commitmentEndTimestamp} and you will be charged $${planInReview.price}/mo.`
          : `Your plan will end on ${commitmentEndTimestamp} and will be switched to Gold Monthly.`;
    }

    return (
      <div className={styles.slide_in_left}>
        <GenericSubscriptionUpdatedStep
          title={updatedPlanTitle}
          {...{ label, subTitle, extraInfo }}
          onDoneBtnClick={onDoneBtnClick}
          addebleCard={
            postMembershipPlan ? (
              <SubscriptionCard
                label="Upcoming subscription plan:"
                title={
                  <>
                    {`${plans[postMembershipPlan.planType].planTypeName}`},
                    {' Monthly'}
                  </>
                }
                subTitle={
                  <>
                    {`($${
                      plans[postMembershipPlan.planType].planOptions[
                        postMembershipPlan.planInd
                      ].price
                    }/mo)`}
                  </>
                }
                extraInfo={`Your plan will switch to ${
                  plans[postMembershipPlan.planType].planTypeName
                } (with no Neuro Coach calls included) on ${dayJS(
                  currentPlan.commitmentEndTimestamp
                ).format(
                  DAY_JS_DATE_FORMATS.namedMonthDayYear
                )} and you will be charged $${
                  plans[postMembershipPlan.planType].planOptions[
                    postMembershipPlan.planInd
                  ].price
                }/mo. Billed monthly, no commitment.`}
              />
            ) : null
          }
          shouldRemoveFirstCard={
            shouldRenderNewPlans && !planInReview.commitmentInMonths
          }
        />
      </div>
    );
  };

  const renderErrorScreen = () => {
    const errorHandler =
      errorContainer.handler ||
      (() => {
        setErrorContainer(initialErrorContainer);
        setCurrentStep(initialStep);
      });

    return (
      <div className={styles.error_screen}>
        <p className={styles.label}>Something went wrong</p>
        <p className={styles.message}>{errorContainer.msg}</p>

        <div className={styles.btn_container}>
          <button type="button" onClick={errorHandler}>
            <span>ok</span>
          </button>
        </div>
      </div>
    );
  };

  const shouldHideBackIcon =
    currentStep === initialStep ||
    currentStep === steps.subscriptionUpgraded ||
    currentStep === steps.subscriptionWasCanceled ||
    currentStep === steps.verificationFailed ||
    currentStep === steps.loader ||
    (errorContainer && errorContainer.msg !== null);

  return (
    <div className={styles.root}>
      <div className={styles.logo_container}>
        <img
          src={logoImage}
          style={{
            paddingLeft: shouldHideBackIcon ? 0 : 50,
          }}
        />

        <div
          className={styles.back_icon_container}
          style={{
            display: shouldHideBackIcon ? 'none' : 'initial',
          }}
        >
          <ArrowBackIcon
            style={{ color: 'inherit', fontSize: 34 }}
            onClick={onBack}
          />
        </div>
      </div>
      <div
        className={styles.main_divider}
        style={
          currentStep === steps.pauseSubscription ||
          currentStep === steps.downgradeToContinuity ||
          currentStep === steps.discountBeforeReactivation
            ? { display: 'none' }
            : null
        }
      />
      <div className={classNames(styles.content_container)}>
        <div
          className={styles.content}
          style={
            currentStep === steps.pauseSubscription ||
            currentStep === steps.downgradeToContinuity ||
            currentStep === steps.discountBeforeReactivation
              ? { maxWidth: 'unset', width: '100%' }
              : null
          }
        >
          {errorContainer.msg === null ? (
            <>
              {currentStep === steps.subscriptionInfo &&
                wrapWithDelayedRenderer(renderSubscriptionInfoStep())}
              {currentStep === steps.selectAction &&
                wrapWithDelayedRenderer(renderSelectActionStep())}
              {currentStep === steps.cancelDiscount &&
                wrapWithDelayedRenderer(renderCancelDiscountStep())}
              {currentStep === steps.reviewAction &&
                wrapWithDelayedRenderer(renderReviewActionStep())}
              {currentStep === steps.cancelSubscriptionConfirm &&
                wrapWithDelayedRenderer(renderCancelSubscriptionConfirmStep())}
              {currentStep === steps.pauseSubscription &&
                wrapWithDelayedRenderer(renderPauseSubscriptionStep())}
              {currentStep === steps.downgradeToContinuity &&
                wrapWithDelayedRenderer(renderDowngradeToContinuityStepStep())}
              {currentStep === steps.discountBeforeReactivation &&
                wrapWithDelayedRenderer(renderDiscountBeforeReactivation())}
              {currentStep === steps.cancelSubscriptionReasons &&
                wrapWithDelayedRenderer(renderCancelSubscriptionReasonsStep())}
              {currentStep === steps.subscriptionWasCanceled &&
                wrapWithDelayedRenderer(renderSubscriptionWasCanceledStep())}
              {currentStep === steps.loader &&
                wrapWithDelayedRenderer(renderLoaderStep())}
              {currentStep === steps.editPayment &&
                wrapWithDelayedRenderer(renderEditPaymentMethodStep())}
              {currentStep === steps.verification &&
                wrapWithDelayedRenderer(renderVerificationStep())}
              {currentStep === steps.verificationFailed &&
                wrapWithDelayedRenderer(renderVerificationFailedStep())}
              {currentStep === steps.subscriptionUpgraded &&
                wrapWithDelayedRenderer(renderSubscriptionUpgradedStep())}
              {currentStep === steps.paymentMightHaveSucceededPartially &&
                wrapWithDelayedRenderer(renderPaymentPartiallySucceededStep())}
              {currentStep === steps.paymentSucceeded &&
                wrapWithDelayedRenderer(renderPaymentSucceededStep())}
            </>
          ) : (
            renderErrorScreen()
          )}
        </div>
      </div>
    </div>
  );
};

MTRManageSubscriptionPageBase.propTypes = exact({
  customerDetails: PropTypes.object.isRequired,
  currentPlan: PropTypes.object.isRequired,
  plans: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  mtrCode: PropTypes.string.isRequired,
  adminUserId: PropTypes.string.isRequired,
  adminEmail: PropTypes.string.isRequired,
  currentCardInfo: PropTypes.object,
  handleCardInfoUpdate: PropTypes.func.isRequired,
  shouldRenderNewPlans: PropTypes.bool,
  postMembershipPlan: PropTypes.object,
  cancelationDiscount: PropTypes.object,
});

export const MTRManageSubscriptionPage = React.memo(
  injectStripe(MTRManageSubscriptionPageBase)
);
MTRManageSubscriptionPage.displayName = 'MTRManageSubscriptionPage';
