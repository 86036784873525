import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { MTRRetryInvoicesPage } from './MTRRetryInvoicesPage';
import {
  getCurrentURLParams,
  postToFunctions,
  notifyAppThatComponentIsLoaded,
} from '../../utils/utils';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';

const MTRRetryInvoicesPageContainerBase = ({ onComponentReadyToRender }) => {
  const {
    customerId,
    mtrCode,
    adminUserId,
    fbtoken,
    isFromManageSubs,
  } = getCurrentURLParams();

  const [currentCardInfo, setCurrentCardInfo] = useState(null);
  const [isComponentReadyToRender, setIsComponentReadyToRender] = useState(
    null
  );

  const [adminEmail] = useFirebaseDB({
    path: `remoteAdminUsers/${adminUserId}/email`,
    onceListener: true,
  });

  const [customerInfo] = useFirebaseDB({
    path: `userInfo/${customerId}`,
    onceListener: true,
  });

  const [outstandingPaymentsAmount] = useFirebaseDB({
    path: `remoteBilling/currentPlans/${adminUserId}/subscriptions/${mtrCode}/outstandingPaymentsAmount`,
    onceListener: true,
  });

  useEffect(() => {
    if (
      !!customerInfo &&
      !!currentCardInfo &&
      outstandingPaymentsAmount !== undefined &&
      !!adminEmail
    ) {
      if (!isComponentReadyToRender) {
        setIsComponentReadyToRender(true);
        notifyAppThatComponentIsLoaded();
      }
    }
  }, [
    adminEmail,
    outstandingPaymentsAmount,
    customerInfo,
    isComponentReadyToRender,
    currentCardInfo,
  ]);

  const updateCurrentCardInfo = useCallback(async () => {
    const { cardInfo } = await postToFunctions({
      path: 'billing-paymentSourceInfo',
      data: {
        userId: adminUserId,
        fbtoken,
      },
    });
    setCurrentCardInfo(cardInfo);
  }, [adminUserId, fbtoken]);

  const handleCardInfoUpdate = useCallback(() => {
    return updateCurrentCardInfo();
  }, [updateCurrentCardInfo]);

  useEffect(() => {
    updateCurrentCardInfo();
  }, [updateCurrentCardInfo]);

  useEffect(() => {
    if (adminEmail) {
      onComponentReadyToRender();
    }
  }, [adminEmail, onComponentReadyToRender]);

  return isComponentReadyToRender ? (
    <StripeProvider
      stripe={window.stripe}
      key={window.stripe ? 'stripe' : 'null'}
    >
      <Elements>
        <MTRRetryInvoicesPage
          customerDetails={{
            name: `${customerInfo.firstName} ${customerInfo.lastName}`,
            gender: customerInfo.privateDetails.gender,
            email: customerInfo.email,
            id: customerId,
          }}
          adminUserId={adminUserId}
          mtrCode={mtrCode}
          stripe={window.stripe}
          currentCardInfo={currentCardInfo}
          handleCardInfoUpdate={handleCardInfoUpdate}
          adminEmail={adminEmail}
          outstandingPaymentsAmount={outstandingPaymentsAmount}
          isFromManageSubs={isFromManageSubs}
        />
      </Elements>
    </StripeProvider>
  ) : null;
};

MTRRetryInvoicesPageContainerBase.propTypes = exact({
  onComponentReadyToRender: PropTypes.func.isRequired,
});

export const MTRRetryInvoicesPageContainer = React.memo(
  MTRRetryInvoicesPageContainerBase
);
MTRRetryInvoicesPageContainer.displayName = 'MTRRetryInvoicesPageContainer';
