import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import {
  getCurrentURLParams,
  postToFunctions,
  getEventLogger,
  postMessageToApp,
} from '../../utils/utils';
import { getPlanTypeByBillingPeriodInMonths } from '../../models/mtr-subscription/mtr-subscription';
import checkedImage from '../../../../myndlift-signup/public/assets/checked.png';
import logoImage from '../../../../myndlift-signup/public/assets/modern_logo_black.png';

import styles from './MTRManageSubscriptionPage.scss';

const planTypes = {
  continuityPlans: 'continuityPlans',
  corePlans: 'corePlans',
  familyMemberPlans: 'familyMemberPlans',
};

const formattedPlanTypes = {
  [planTypes.corePlans]: 'Core Training',
  [planTypes.continuityPlans]: 'Continuity Training',
  [planTypes.familyMemberPlans]: 'Family Member Training',
};

const SubscriptionCard = ({
  label,
  title,
  subTitle,
  extraInfo,
  discountInfo,
}) => {
  return (
    <div className={styles.subscription_card}>
      <p className={styles.card_label}>{label}</p>
      <div className={styles.subscription_info}>
        <p>{title}</p>
        {subTitle && <p>{subTitle}</p>}
      </div>
      {extraInfo && (
        <p className={styles.subscription_extra_info}>{extraInfo}</p>
      )}
      {discountInfo && (
        <p className={styles.subscription_extra_discount_info}>
          {discountInfo}
        </p>
      )}
    </div>
  );
};
SubscriptionCard.propTypes = exact({
  label: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
  extraInfo: PropTypes.node,
  discountInfo: PropTypes.node,
});

const GenericSubscriptionUpdatedStep = ({
  label,
  title,
  subTitle,
  extraInfo,
  onDoneBtnClick,
  addebleCard,
  shouldRemoveFirstCard,
}) => {
  return (
    <div className={styles.generic_subscription_updated_step}>
      <img src={checkedImage} className={styles.check_image} />
      <div className={styles.info_text}>
        <p>Thank you!</p>
        <p>Your plan was updated</p>
      </div>
      <div className={styles.updated_subscription_card}>
        {!shouldRemoveFirstCard && (
          <SubscriptionCard {...{ label, title, subTitle, extraInfo }} />
        )}
        <div style={{ marginTop: 10 }}>{addebleCard}</div>
      </div>
      <div className={styles.btn_container}>
        <button type="button" onClick={onDoneBtnClick}>
          <span>Done</span>
        </button>
      </div>
    </div>
  );
};
GenericSubscriptionUpdatedStep.propTypes = exact({
  label: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
  extraInfo: PropTypes.node,
  onDoneBtnClick: PropTypes.func.isRequired,
  addebleCard: PropTypes.node,
  shouldRemoveFirstCard: PropTypes.bool,
});

const MTRDeactivationBase = ({
  shouldRenderNewPlans,
  currentPlan,
  postMembershipPlan,
  plans,
  adminUserId,
  mtrCode,
  onComponentReadyToRender,
  // fbtoken,
  // customerDetails,
}) => {
  const fbTokenRef = useRef(getCurrentURLParams().fbtoken);

  const formattedCurrentPlanType =
    formattedPlanTypes[currentPlan.planType] || currentPlan.planType;

  const currentPlanNameBasedOnPeriod = getPlanTypeByBillingPeriodInMonths({
    billingPeriodInMonths: currentPlan.billingPeriodInMonths,
  });

  const logSubscriptionManagementEvent = getEventLogger({
    prefix: 'mtr_subscription_management_app',
  });

  const onDoneBtnClick = () => {
    logSubscriptionManagementEvent({
      name: 'on_done_btn_click',
    });
    postMessageToApp('doneBtnClicked');
  };

  const postToFunctionsWithDefaultData = params =>
    postToFunctions({
      ...params,
      data: {
        fbtoken: fbTokenRef.current,
        ...params.data,
      },
    });

  useEffect(() => {
    postToFunctionsWithDefaultData({
      path: 'mtr-changeActivation',
      data: {
        shouldDeactivate: true,
        userId: adminUserId,
        mtrCode,
      },
    }).then(() => {
      onComponentReadyToRender();
    });
  }, []);

  const isMonthly = currentPlan.billingPeriodInMonths === 1;
  const animationClass = styles.slide_in_left;
  return (
    <div className={styles.root}>
      <div className={styles.logo_container}>
        <img
          src={logoImage}
          style={{
            paddingLeft: 0,
          }}
        />
        {/* <img src={logoOnlyTextImage} /> */}
      </div>
      <div className={styles.main_divider} />
      <div className={classNames(styles.content_container)}>
        <div className={styles.content}>
          <div className={animationClass}>
            <GenericSubscriptionUpdatedStep
              label="Current subscription plan:"
              title={
                // eslint-disable-next-line no-nested-ternary
                !shouldRenderNewPlans ? (
                  <>
                    {formattedCurrentPlanType}, {currentPlanNameBasedOnPeriod}
                  </>
                ) : !currentPlan.commitmentInMonths && postMembershipPlan ? (
                  <>
                    {`${
                      plans[postMembershipPlan.planType].planTypeName
                    }, Monthly`}
                  </>
                ) : (
                  <>
                    {`${currentPlan.commitmentInMonths} Month Membership`},{' '}
                    {isMonthly ? 'Monthly' : 'Upfront'}
                  </>
                )
              }
              extraInfo="Will not be renewed"
              onDoneBtnClick={onDoneBtnClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MTRDeactivationBase.propTypes = exact({
  currentPlan: PropTypes.object.isRequired,
  plans: PropTypes.object.isRequired,
  mtrCode: PropTypes.string.isRequired,
  adminUserId: PropTypes.string.isRequired,
  shouldRenderNewPlans: PropTypes.bool,
  postMembershipPlan: PropTypes.object,
  onComponentReadyToRender: PropTypes.func,
});

export const MTRDeactivation = React.memo(MTRDeactivationBase);
MTRDeactivation.displayName = 'MTRDeactivation';
