import React from 'react';

export const MoneyIcon = props => (
  <svg width={24.17} height={24} {...props} viewBox="0 0 24.17 14.502">
    <path
      id="Icon_awesome-money-bill"
      data-name="Icon awesome-money-bill"
      d="M22.961,4.5H1.208A1.209,1.209,0,0,0,0,5.708V17.793A1.209,1.209,0,0,0,1.208,19H22.961a1.209,1.209,0,0,0,1.208-1.208V5.708A1.209,1.209,0,0,0,22.961,4.5ZM1.813,17.189V14.772A2.417,2.417,0,0,1,4.23,17.189Zm0-8.459V6.313H4.23A2.417,2.417,0,0,1,1.813,8.73Zm10.272,6.647c-1.669,0-3.021-1.624-3.021-3.625s1.353-3.625,3.021-3.625,3.021,1.623,3.021,3.625S13.753,15.376,12.085,15.376Zm10.272,1.813H19.94a2.417,2.417,0,0,1,2.417-2.417Zm0-8.459A2.417,2.417,0,0,1,19.94,6.313h2.417Z"
      transform="translate(0 -4.5)"
      fill="#525264"
    />
  </svg>
);
