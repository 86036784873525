import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog } from '@material-ui/core';
import styles from './PaymentStatusDialog.scss';

const PaymentStatusDialogBase = ({
  isOpen,
  onPrimaryBtnClick,
  primaryBtnText,
  image,
  primaryText,
  primaryTextDescription,
}) => {
  return (
    <Dialog open={isOpen} classes={{ paper: styles.dialog_paper }}>
      <div className={styles.dialog_content}>
        <img src={image} />
        <p className={styles.payment_status_label}>{primaryText}</p>
        <p className={styles.payment_status_description}>
          {primaryTextDescription}
        </p>
        <div className={styles.btn_container}>
          <button onClick={onPrimaryBtnClick} type="button">
            <span>{primaryBtnText}</span>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

PaymentStatusDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onPrimaryBtnClick: PropTypes.func.isRequired,
  primaryBtnText: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  primaryText: PropTypes.node.isRequired,
  primaryTextDescription: PropTypes.node.isRequired,
});

export const PaymentStatusDialog = React.memo(PaymentStatusDialogBase);
PaymentStatusDialog.displayName = 'PaymentStatusDialog';
