import React from 'react';

export const ShippingIcon = props => (
  <svg width={25.151} height={24} {...props} viewBox="0 0 25.151 18.292">
    <path
      id="Icon_material-local-shipping"
      data-name="Icon material-local-shipping"
      d="M23.221,10.573h-3.43V6H3.786A2.293,2.293,0,0,0,1.5,8.286V20.862H3.786a3.43,3.43,0,0,0,6.859,0h6.859a3.43,3.43,0,0,0,6.859,0h2.286V15.146Zm-16.005,12a1.715,1.715,0,1,1,1.715-1.715A1.713,1.713,0,0,1,7.216,22.577ZM22.65,12.288l2.241,2.858h-5.1V12.288ZM20.935,22.577a1.715,1.715,0,1,1,1.715-1.715A1.713,1.713,0,0,1,20.935,22.577Z"
      transform="translate(-1.5 -6)"
      fill="#525264"
    />
  </svg>
);
