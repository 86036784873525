import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './USBTypeDialog.scss';
import portTypeImg from '../../../../../myndlift-signup/public/assets/usb_type_differ@2x.png';
import PortTypeImgGrey from '../../../../../myndlift-signup/public/assets/usb_type_differ_grey@2x.png';
import PortTypeImgMobileGrey from '../../../../../myndlift-signup/public/assets/popup_img_mobile@2x.png';

const USBTypeDialogBase = ({ isOpen, onClose, ABTestVersion }) => {
  function getImageSrc() {
    let portTypeSrcImg;
    if (ABTestVersion === 'A') {
      portTypeSrcImg = PortTypeImgGrey;
    } else if (ABTestVersion === 'B') {
      portTypeSrcImg = portTypeImg;
    }
    return window.innerWidth < 480 ? PortTypeImgMobileGrey : portTypeSrcImg;
  }

  const [imageSrc, setImageSrc] = useState(getImageSrc());

  useEffect(() => {
    const handleResize = () => setImageSrc(getImageSrc());

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Dialog
      open={isOpen}
      classes={{ paper: styles.dialog_paper }}
      maxWidth="md"
    >
      <div className={styles.icon_continer}>
        <CloseIcon
          style={{
            cursor: 'pointer',
            color: '#808080',
            marginTop: 20,
            marginRight: 30,
          }}
          onClick={onClose}
        />
      </div>
      <div className={styles.content_continer}>
        <div className={styles.content_title}>
          What charging port does your Muse have?
        </div>
        <img src={imageSrc} className={styles.popup_img} />
        <div className={styles.text_content}>
          If you can plug it in both ways, you have a USB-C. If it only fits one
          way, it's a Micro-USB.
        </div>
        <div className={styles.text_content}>
          USB-C is oval and smooth, while Micro-USB has a slight notch on one
          side.
        </div>
      </div>
    </Dialog>
  );
};

USBTypeDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ABTestVersion: PropTypes.string.isRequired,
});

export const USBTypeDialog = React.memo(USBTypeDialogBase);
USBTypeDialog.displayName = 'USBTypeDialog';
