import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import axios from 'axios';

import { StripeProvider, Elements } from 'react-stripe-elements';
import { MTRSubscriptionPage } from './MTRSubscriptionPage';
// import { MTRSubscriptionPageNew } from './MTRSubscriptionPageNew';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import {
  notifyAppThatComponentIsLoaded,
  getCurrentURLParams,
  // postToNode,
  // defensiveThrow,
  postToFunctions,
} from '../../utils/utils';

// const AB_TESTING_PRICING_PARAM_NAME = 'ab_pricing';

const initialValue = Symbol('iv');
const MTRSubscriptionPageContainerBase = ({
  onComponentReadyToRender,
  remoteDefaultGroupId,
}) => {
  const {
    customerId,
    fbtoken,
    mtrCode,
    adminUserId,
    groupId,
    // email,
    userId,
    couponCode: couponCodeURL,
  } = getCurrentURLParams();

  const nodeURL = environmentConfig.appengine.node;
  const funcURL = environmentConfig.functions;

  const [isComponentReadyToRender, setIsComponentReadyToRender] = useState(
    false
  );
  const [plans, setPlans] = useState(initialValue);
  const [customerInfo, setCustomerInfo] = useState(initialValue);

  const [discountCouponAmount, setDiscountCouponAmount] = useState(
    initialValue
  );
  const [userPlan, setUserPlan] = useState(initialValue);
  const [reactivationGroupId, setReactivationGroupId] = useState(initialValue);
  const [extraCredit, setExtraCredit] = useState(0);
  // const [ABToggle, setABToggle] = useState(null);
  const [ABPricingToggle, setABPricingToggle] = useState(initialValue);
  const [beforeDiscountPlan, setBeforeDiscountPlan] = useState(null);
  const [reviews, setReviews] = useState([]);
  // const [finalGroupId, setFinalGroupId] = useState(remoteDefaultGroupId);
  const finalGroupId = remoteDefaultGroupId;
  // const [
  //   wasRemoteConfigValuesFetched,
  //   setWasRemoteConfigValuesFetched,
  // ] = useState(false);

  // const finalGroupId = ABPricingToggle ? '-M2cV5aZ1s8RjW3xZfB4mJ' : remoteDefaultGroupId;

  // useEffect(() => {
  //   if (ABPricingToggle === true) {
  //     setFinalGroupId('-M2cV5aZ1s8RjW3xZfB4mJ');
  //   }
  // }, [finalGroupId, ABPricingToggle]);

  // if (email) {
  //   // eslint-disable-next-line no-undef
  //   amplitude.setUserId(btoa(email));
  // }

  const [isUrlCouponValid, setIsUrlCouponValid] = useState(false);
  const [customCouponText, setCustomCouponText] = useState('');

  useEffect(() => {
    (async () => {
      if (adminUserId) {
        const data = await postToFunctions({
          path: 'mtr-subscribedPlanDetails',
          data: {
            userId: customerId,
            mtrCode,
            fbtoken,
          },
        });
        setUserPlan(data[mtrCode]);
      } else {
        setUserPlan(null);
      }
    })();
  }, [customerId, fbtoken, mtrCode, adminUserId]);

  useEffect(() => {
    axios
      .get(
        'https://stamped.io/api/widget/reviews?productId&productType&productTitle&email&isWithPhotos&minRating&take&page&dateFrom&dateTo&sortReviews&tags&storeUrl=signup.myndlift.com'
      )
      .then(res => {
        setReviews(res.data.data);
      });
  }, []);

  useEffect(() => {
    if (!beforeDiscountPlan) {
      database
        .ref('remoteBilling/customPlans/-firstDefaultGroupId')
        .once('value', snapshot => {
          setBeforeDiscountPlan(snapshot.val());
        });
    }
  }, [plans]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const variant = JSON.parse(localStorage.getItem('skip_varinat'));
    setABPricingToggle(variant);
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     // eslint-disable-next-line no-undef
  //     remoteConfig
  //       .fetchAndActivate()
  //       .then(() => {
  //         const remoteConfigValue = window.remoteConfig.getValue(
  //           AB_TESTING_PRICING_PARAM_NAME
  //         );
  //         // eslint-disable-next-line no-underscore-dangle
  //         const abVal = JSON.parse(remoteConfigValue._value);
  //         setABPricingToggle(abVal);
  //         // eslint-disable-next-line no-undef
  //         ga('set', 'dimension2', abVal ? 'B' : 'A');
  //         // setWasRemoteConfigValuesFetched(true);
  //         // eslint-disable-next-line no-undef
  //         localStorage.setItem('ABToggle', abVal);
  //         // eslint-disable-next-line no-undef
  //         amplitude.track('got_variant_from_remote_config', {
  //           abVal: abVal ? 'B' : 'A',
  //           email,
  //         });
  //         // console.log('remote config');
  //         // console.log('selected variant: ', abVal ? 'B' : 'A');
  //       })
  //       .catch(err => {
  //         defensiveThrow(err);
  //         setABPricingToggle(false);
  //         // setWasRemoteConfigValuesFetched(true);
  //         // eslint-disable-next-line no-undef
  //         amplitude.track('got_variant_manually_error_from_remote_config', {
  //           abVal: 'A',
  //         });
  //       });
  //   })();
  //   // eslint-disable-next-line no-undef
  // }, [remoteConfig]);

  const [faqs] = useFirebaseDB({
    path: 'FAQ/new-remote-billing',
    onceListener: true,
  });

  // const [faqsNotPersolized] = useFirebaseDB({
  //   path: 'FAQ/new-remote-billing-not-personalized',
  //   onceListener: true,
  //   initialValue,
  // });

  const [newFaqs] = useFirebaseDB({
    path: 'FAQ/remote-billing-v4',
    onceListener: true,
  });

  const [defaultGroupId] = useFirebaseDB({
    path: 'config/remoteDefaultGroupId',
    onceListener: true,
    initialValue,
  });

  const [museDevicePrice] = useFirebaseDB({
    path: 'remoteUsersConfig/additionalHardwareCosts/headset/price',
    onceListener: true,
  });
  const [discountCoupon] = useFirebaseDB({
    path: `remoteUsersConfig/${
      adminUserId ? 'reactivationDiscountCoupon' : 'discountCoupon'
    }/${
      adminUserId && reactivationGroupId !== initialValue
        ? reactivationGroupId
        : finalGroupId
    }`,
    onceListener: true,
    initialValue,
  });

  const [discountCouponText] = useFirebaseDB({
    path: `remoteUsersConfig/bannerText/${
      adminUserId ? 'reactivationFlow' : 'registrationFlow'
    }`,
    onceListener: true,
  });

  const [additionalSessionPrice] = useFirebaseDB({
    path: 'remoteUsersConfig/additionalCallsCost',
    onceListener: true,
    initialValue,
  });

  const [hideMuseHeadset] = useFirebaseDB({
    path: 'inventory/initialShop/hideMuseHeadset',
    onceListener: true,
    initialValue,
  });

  const [postMembershipPlan] = useFirebaseDB({
    path: `remoteBilling/customPlans/${finalGroupId}/postMembershipPlan`,
    onceListener: true,
    initialValue,
  });

  const [excludedCountries] = useFirebaseDB({
    path: 'remoteUsersConfig/excludedCountries',
    onceListener: true,
  });

  // const [remoteClinicsInfo] = useFirebaseDB({
  //   path: 'remoteClinics/clinicInfo',
  //   onceListener: true,
  //   initialValue,
  // });

  const { stripe } = window;

  useEffect(() => {
    (async () => {
      if (finalGroupId && !adminUserId && !userId) {
        database
          .ref(`remoteBilling/customPlans/${finalGroupId}`)
          .once('value', snapshot => setPlans(snapshot.val()));
      } else {
        if (adminUserId) {
          const groupIdFB = await database
            .ref(`/remoteBilling/billingConfigs/${adminUserId}/allowedGroupId`)
            .once('value')
            .then(snapshot => snapshot.val());
          if (groupIdFB) {
            setReactivationGroupId(groupIdFB);
            database
              .ref(`remoteBilling/customPlans/${groupIdFB}`)
              .once('value', snapshot => setPlans(snapshot.val()));
          } else {
            window.open('404.html', '_self');
          }
        } else if (userId) {
          const groupIdFB = await database
            .ref(`/remoteBilling/billingConfigs/${userId}/allowedGroupId`)
            .once('value')
            .then(snapshot => snapshot.val());
          if (groupIdFB) {
            setReactivationGroupId(groupIdFB);
            database
              .ref(`remoteBilling/customPlans/${groupIdFB}`)
              .once('value', snapshot => setPlans(snapshot.val()));
          } else {
            window.open('404.html', '_self');
          }
        } else {
          window.open('404.html', '_self');
        }
      }
    })();
  }, [remoteDefaultGroupId, finalGroupId, ABPricingToggle]);

  useEffect(() => {
    (async () => {
      if (customerId) {
        // eslint-disable-next-line no-undef
        await firebase
          .auth()
          // eslint-disable-next-line no-undef
          .setPersistence(firebase.auth.Auth.Persistence.SESSION);
        // eslint-disable-next-line no-undef
        await firebase.auth().signInWithCustomToken(fbtoken);

        setCustomerInfo(
          await database
            .ref(`userInfo/${customerId}`)
            .once('value')
            .then(snapshot => snapshot.val())
        );
      } else {
        setCustomerInfo(null);
      }
    })();
  }, [customerId, fbtoken, groupId]);

  useEffect(() => {
    if (
      // faqs !== initialValue &&
      plans !== initialValue &&
      // museDevicePrice !== initialValue &&
      discountCoupon !== initialValue &&
      discountCouponAmount !== initialValue &&
      // discountCouponText !== initialValue &&
      // customerInfo !== initialValue &&
      userPlan !== initialValue &&
      remoteDefaultGroupId !== initialValue &&
      ABPricingToggle !== initialValue
      // remoteClinicsInfo !== initialValue
    ) {
      if (!isComponentReadyToRender) {
        onComponentReadyToRender();
        setIsComponentReadyToRender(true);
        notifyAppThatComponentIsLoaded();
        // eslint-disable-next-line no-undef
        amplitude.track('subscription_page_loaded', {
          // eslint-disable-next-line no-undef
          loading_time: Date.now() - mountingTime,
        });
      }
    }
  }, [
    faqs,
    plans,
    onComponentReadyToRender,
    museDevicePrice,
    isComponentReadyToRender,
    discountCoupon,
    discountCouponAmount,
    discountCouponText,
    customerInfo,
    userPlan,
    // ABToggle,
    ABPricingToggle,
    // remoteClinicsInfo,
  ]);

  const getCouponDiscount = async coupon => {
    const discountCouponResponse = await window.fetch(
      `${funcURL}shop-myndliftCouponDiscount`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          couponCode: coupon,
          // ...(userId ? { userId } : adminUserId ? { userId: adminUserId } : {}),
          ...(adminUserId ? { userId: adminUserId } : {}),
          ...(mtrCode ? { activationCode: mtrCode } : {}),
        }),
      }
    );

    const discountCouponResponseData = await discountCouponResponse.json();

    const discountAmount =
      discountCouponResponseData && discountCouponResponseData.result === true
        ? discountCouponResponseData.discountAmount
        : 0;

    return discountAmount;
  };

  useEffect(() => {
    (async () => {
      let discountAmount;
      if (couponCodeURL) {
        discountAmount = await getCouponDiscount(couponCodeURL);
        setDiscountCouponAmount(discountAmount);
        if (discountAmount) {
          setIsUrlCouponValid(true);
        }
        const customCouponTextVal = await database
          .ref('remoteUsersConfig/customCouponsText')
          .once('value')
          .then(snapshot => snapshot.val());
        if (customCouponTextVal) {
          setCustomCouponText(customCouponTextVal);
        }
      }
      if (discountCoupon !== initialValue && !discountAmount) {
        if (discountCoupon) {
          discountAmount = await getCouponDiscount(discountCoupon);
          setDiscountCouponAmount(discountAmount);
        } else {
          setDiscountCouponAmount(0);
        }
      }
    })();
  }, [discountCoupon, funcURL]);

  useEffect(() => {
    (async () => {
      if (adminUserId) {
        const response = await postToFunctions({
          path: 'billing-accountBalance',
          data: {
            userId: customerId,
            fbtoken,
          },
        });
        if (response.result) {
          setExtraCredit(response.balance);
        }
      }
    })();
  }, []);

  if (plans === initialValue) {
    return;
  }

  // if (ABPricingToggle && !adminUserId && !userId) {
  //   // eslint-disable-next-line consistent-return
  //   return (
  //     <StripeProvider stripe={stripe} key={stripe ? 'stripe' : 'null'}>
  //       <Elements>
  //         {isComponentReadyToRender ? (
  //           <MTRSubscriptionPageNew
  //             stripe={stripe}
  //             nodeURL={nodeURL}
  //             faqs={plans.groupType === 'membership' ? newFaqs : faqs}
  //             plans={plans}
  //             groupId={
  //               adminUserId || userId
  //                 ? reactivationGroupId
  //                 : finalGroupId || defaultGroupId
  //             }
  //             museDevicePrice={museDevicePrice}
  //             discountCouponAmount={
  //               discountCouponAmount === initialValue ? 0 : discountCouponAmount
  //             }
  //             discountCoupon={isUrlCouponValid ? couponCodeURL : discountCoupon}
  //             customCouponText={customCouponText}
  //             additionalSessionPrice={additionalSessionPrice}
  //             customerInfo={customerInfo}
  //             userPlan={userPlan}
  //             isEligibleForContinuityPlan={
  //               userPlan && userPlan.numActiveCycles >= 3
  //             }
  //             hideMuseHeadset={hideMuseHeadset}
  //             shouldRenderNewPlans={plans.groupType === 'membership'}
  //             postMembershipPlan={postMembershipPlan}
  //             extraCredit={extraCredit}
  //             discountCouponText={discountCouponText}
  //             funcURL={funcURL}
  //             // ABToggle={ABToggle}
  //             ABPricingToggle={ABPricingToggle}
  //             // beforeDiscountPlan={beforeDiscountPlan}
  //             reviews={reviews}
  //             excludedCountries={excludedCountries}
  //             // remoteClinicsInfo={remoteClinicsInfo}
  //           />
  //         ) : (
  //           <div id="loader-container">
  //             <div className="lds-ring">
  //               <div />
  //               <div />
  //               <div />
  //               <div />
  //             </div>
  //           </div>
  //         )}
  //       </Elements>
  //     </StripeProvider>
  //   );
  // }

  // eslint-disable-next-line consistent-return
  return (
    <StripeProvider stripe={stripe} key={stripe ? 'stripe' : 'null'}>
      <Elements>
        {isComponentReadyToRender ? (
          <MTRSubscriptionPage
            stripe={stripe}
            nodeURL={nodeURL}
            faqs={plans.groupType === 'membership' ? newFaqs : faqs}
            plans={plans}
            groupId={
              adminUserId || userId
                ? reactivationGroupId
                : finalGroupId || defaultGroupId
            }
            museDevicePrice={museDevicePrice}
            discountCouponAmount={
              discountCouponAmount === initialValue ? 0 : discountCouponAmount
            }
            discountCoupon={isUrlCouponValid ? couponCodeURL : discountCoupon}
            customCouponText={customCouponText}
            additionalSessionPrice={additionalSessionPrice}
            customerInfo={customerInfo}
            userPlan={userPlan}
            isEligibleForContinuityPlan={
              userPlan && userPlan.numActiveCycles >= 3
            }
            hideMuseHeadset={hideMuseHeadset}
            shouldRenderNewPlans={plans.groupType === 'membership'}
            postMembershipPlan={postMembershipPlan}
            extraCredit={extraCredit}
            discountCouponText={discountCouponText}
            funcURL={funcURL}
            // ABToggle={ABToggle}
            ABPricingToggle={ABPricingToggle}
            // beforeDiscountPlan={beforeDiscountPlan}
            reviews={reviews}
            excludedCountries={excludedCountries}
            // remoteClinicsInfo={remoteClinicsInfo}
          />
        ) : (
          <div id="loader-container">
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        )}
      </Elements>
    </StripeProvider>
  );
};

MTRSubscriptionPageContainerBase.propTypes = exact({
  onComponentReadyToRender: PropTypes.func.isRequired,
  remoteDefaultGroupId: PropTypes.string,
});

export const MTRSubscriptionPageContainer = React.memo(
  MTRSubscriptionPageContainerBase
);
MTRSubscriptionPageContainer.displayName = 'MTRSubscriptionPageContainer';
