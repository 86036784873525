import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import cloneDeep from 'lodash/cloneDeep';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Link as ScrollLink } from 'react-scroll';
import countryTelData from 'country-telephone-data';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import { TextField, CircularProgress } from '@material-ui/core';
import clinicianJPG from '../../../../../myndlift-signup/public/assets/clinician2.jpg';
import styles from './CheckoutForm.scss';
import { removeAllAfterLastChar } from '../../../utils/utils';
import { CustomBtn } from '../CustomBtn/CustomBtn';

const countriesDataList = cloneDeep(countryTelData).allCountries.map(
  country => {
    const newCountry = cloneDeep(country);
    newCountry.name = removeAllAfterLastChar(country.name, '(').trim();
    return newCountry;
  }
);

const urlSearchParams = new URLSearchParams(window.location.search);
const isFamilyMemberWithEquipmentCheckoutForm =
  urlSearchParams.get('cta') === 'none';

const CheckoutFormBase = (
  {
    onSelectedMuseOptionChange,
    onCreditCardNumberChange,
    creditCardNumberError,
    creditCardExpiryError,
    creditCardCVCError,
    onCreditCardExpiryChange,
    onCreditCardCVCChange,
    register,
    errors,
    onConfirm,
    museOptions,
    selectedMuseOption,
    selectedPaymentMethod,
    paymentMethods,
    onSelectedPaymentMethodChange,
    total,
    subTotal,
    hasCompatibleHeadset,
    stripeError,
    paymentOptionsId,
    compatibleMuseText,
    compatibleMuseTextColor,
    isBillingInfoDisplayed,
    billingInfoSectionId,
    validateEmail,
    isFamilyCheckoutForm,
    shouldShowBillingEmailInput,
    // eslint-disable-next-line no-unused-vars
    compatibleHeadsetDiscount,
    compatibleMuseDiscountDescription,
    couponCode,
    isCouponCodeBtnLoading,
    onCouponCodeChange,
    onCouponCodeApply,
    couponDiscount,
    couponCodeError,
  },
  ref
) => {
  const cardNumberElementRef = useRef();
  const cardExpiryElementRef = useRef();
  const cardCVCElementRef = useRef();

  useImperativeHandle(ref, () => ({
    clearCreditCardDetailsFields: () => {
      cardNumberElementRef.current.clear();
      cardExpiryElementRef.current.clear();
      cardCVCElementRef.current.clear();
    },
    disableChangeListeners: () => {
      cardNumberElementRef.current.off('change');
      cardExpiryElementRef.current.off('change');
      cardCVCElementRef.current.off('change');
    },
    enableChangeListeners: () => {
      cardNumberElementRef.current.on('change', onCreditCardNumberChange);
      cardExpiryElementRef.current.on('change', onCreditCardExpiryChange);
      cardCVCElementRef.current.on('change', onCreditCardCVCChange);
    },
  }));

  const renderCouponInput = () => (
    <div className={styles.coupon_input_container}>
      <TextField
        label="Coupon"
        variant="outlined"
        classes={{ root: styles.textfield }}
        value={couponCode}
        onChange={onCouponCodeChange}
        fullWidth
        error={!!couponCodeError}
        helperText={couponCodeError}
      />

      <div className={styles.coupon_code_btn_container}>
        <CustomBtn onClick={onCouponCodeApply}>
          <span
            style={{
              minWidth: 80,
              height: 29,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {isCouponCodeBtnLoading ? (
              <div>
                <CircularProgress size={22} color="inherit" />
              </div>
            ) : (
              'apply'
            )}
          </span>
        </CustomBtn>
      </div>
    </div>
  );

  return (
    <div className={styles.root}>
      {!isFamilyCheckoutForm && (
        <React.Fragment>
          <div style={{ background: '#657ece', borderRadius: 16 }}>
            <p style={{ color: 'white', padding: '23px 26px' }}>
              Due to the COVID-19 pandemic, we are experiencing high demand, and
              some of our stock is unavailable. We ask that you buy a Muse
              headset separately.{' '}
              <a
                href="https://www.amazon.com/Muse-2-Brain-Sensing-Headband/dp/B07HL2S9JQ/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#C8C8CF' }}
              >
                You can buy it from here.
              </a>
            </p>
          </div>
          <div id="headset-options" className={styles.muse_options}>
            <h4 className={styles.muse_header}>
              CHECK IF YOUR MUSE IS COMPATIBLE
            </h4>
            <div className={styles.radio_group_container}>
              <RadioGroup
                value={selectedMuseOption}
                onChange={onSelectedMuseOptionChange}
              >
                {Object.entries(museOptions).map(([optionKey, optionText]) => (
                  <FormControlLabel
                    key={optionKey}
                    value={optionText}
                    control={
                      <Radio
                        classes={{
                          checked: styles.checked,
                          root: styles.radio_root,
                        }}
                      />
                    }
                    label={optionText}
                    classes={{
                      root: styles.radio_label,
                    }}
                  />
                ))}
              </RadioGroup>
              <p style={{ color: compatibleMuseTextColor }}>
                {compatibleMuseText}
              </p>
            </div>
          </div>
        </React.Fragment>
      )}

      <div className={styles.shipping_options}>
        {!isFamilyCheckoutForm && (
          <h4 className={styles.shipping_header}>SHIPPING INFO</h4>
        )}
        <div className={styles.inputs}>
          {!isFamilyCheckoutForm && (
            <React.Fragment>
              <TextField
                label="First name"
                error={!!errors['First Name']}
                helperText={
                  errors['First Name'] && errors['First Name'].message
                }
                variant="outlined"
                classes={{ root: styles.textfield }}
                fullWidth
                inputProps={{
                  name: 'First Name',
                }}
                inputRef={register({ required: true })}
              />
              <TextField
                label="Last name"
                variant="outlined"
                classes={{ root: styles.textfield }}
                fullWidth
                inputProps={{
                  name: 'Last Name',
                }}
                inputRef={register({ required: true })}
                error={!!errors['Last Name']}
                helperText={errors['Last Name'] && errors['Last Name'].message}
              />
              <TextField
                label="Country"
                select
                name="Country"
                inputRef={register({ required: true })}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                fullWidth
                defaultValue="United States"
              >
                {countriesDataList.map(country => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </TextField>
              <TextField
                label="State (optional)"
                variant="outlined"
                classes={{ root: styles.textfield }}
                fullWidth
                inputProps={{
                  name: 'State',
                }}
                inputRef={register({ required: false })}
              />
              <TextField
                label="City"
                variant="outlined"
                classes={{ root: styles.textfield }}
                fullWidth
                inputProps={{
                  name: 'City',
                }}
                inputRef={register({ required: true })}
                error={!!errors.City}
                helperText={errors.City && errors.City.message}
              />
              <TextField
                label="Street, p.o. box, etc..."
                variant="outlined"
                classes={{ root: styles.textfield }}
                fullWidth
                inputProps={{
                  name: 'Street',
                }}
                inputRef={register({ required: false })}
              />
              <TextField
                label="Apt, floor, etc… (optional)"
                variant="outlined"
                classes={{ root: styles.textfield }}
                fullWidth
                inputProps={{
                  name: 'Apt',
                }}
                inputRef={register({ required: false })}
              />
              <TextField
                label="Zip"
                variant="outlined"
                classes={{ root: styles.textfield }}
                fullWidth
                inputProps={{
                  name: 'Zip Code',
                }}
                inputRef={register({
                  required: true,
                })}
                error={!!errors['Zip Code']}
                helperText={errors['Zip Code'] && errors['Zip Code'].message}
              />
              <TextField
                label="Phone"
                variant="outlined"
                classes={{ root: styles.textfield }}
                fullWidth
                placeholder="Phone (e.g. 9171234567)"
                inputProps={{
                  name: 'Phone',
                }}
                inputRef={register({
                  required: true,
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Invalid phone',
                  },
                })}
                error={!!errors.Phone}
                helperText={errors.Phone && errors.Phone.message}
              />
              <div className={styles.image_container}>
                <img src={clinicianJPG} />
              </div>
              <div className={styles.payment_options}>
                <RadioGroup
                  value={selectedPaymentMethod}
                  onChange={onSelectedPaymentMethodChange}
                >
                  {Object.entries(paymentMethods).map(
                    ([paymentMethodId, paymentMethodText]) => (
                      <FormControlLabel
                        value={paymentMethodText}
                        key={paymentMethodId}
                        control={
                          <Radio
                            classes={{
                              checked: styles.checked,
                              root: styles.radio_root,
                            }}
                          />
                        }
                        label={paymentMethodText}
                        classes={{
                          root: styles.radio_label,
                        }}
                      />
                    )
                  )}
                  {!isFamilyMemberWithEquipmentCheckoutForm && (
                    <p style={{ fontWeight: 900 }}>
                      <ScrollLink
                        to={paymentOptionsId}
                        href={`#${paymentOptionsId}`}
                        spy
                        smooth
                        duration={800}
                        offset={-100}
                      >
                        Learn more about pricing
                      </ScrollLink>
                    </p>
                  )}
                </RadioGroup>
              </div>

              {selectedPaymentMethod === paymentMethods.oneTimePayment &&
                renderCouponInput()}

              <div className={styles.divider} />

              <React.Fragment>
                <div className={styles.extra_info}>
                  <div>
                    <p>Sub-total</p>
                    <p>{subTotal}</p>
                  </div>

                  <div style={{}}>
                    <p>{compatibleMuseDiscountDescription}:</p>
                    <p style={{ textAlign: 'right' }}>
                      {hasCompatibleHeadset ? (
                        <span
                          style={{
                            color: 'rgb(36, 199, 182)',
                            wordBreak: 'keep-all',
                            whiteSpace: 'no-wrap',
                          }}
                        >
                          Discount applied
                        </span>
                      ) : (
                        'Not eligible'
                      )}
                    </p>
                  </div>
                  <div>
                    <ScrollLink
                      to="headset-options"
                      href="#headset-options"
                      spy
                      smooth
                      duration={800}
                      offset={-100}
                    >
                      Check headset compatibility
                    </ScrollLink>
                  </div>

                  {couponDiscount > 0 && (
                    <div>
                      <p>Coupon discount:</p>
                      <p
                        style={{
                          color: 'rgb(36, 199, 182)',
                          wordBreak: 'keep-all',
                          whiteSpace: 'no-wrap',
                        }}
                      >
                        -${couponDiscount}
                      </p>
                    </div>
                  )}
                </div>

                <div className={styles.divider} />
              </React.Fragment>
            </React.Fragment>
          )}
          <div
            className={
              isFamilyCheckoutForm ? styles.emphasized_total : styles.total
            }
          >
            <div>
              <p>Total</p>
              <p>{total}</p>
            </div>
          </div>

          <div className={styles.divider} />

          {!isFamilyCheckoutForm && (
            <React.Fragment>
              <div className={styles.guarantees}>
                {selectedPaymentMethod === paymentMethods.oneTimePayment ? (
                  <React.Fragment>
                    <p>FREE SHIPPING</p>
                    <p>30-DAY MONEY-BACK GUARANTEE</p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p>FREE SHIPPING</p>
                    <p>
                      Please note that the Three-Payment Plan isn't eligible for
                      the 30 day money-back guarantee
                    </p>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}

          <Collapse in={isBillingInfoDisplayed}>
            <div
              id={billingInfoSectionId}
              data-is-billing-info-displayed={isBillingInfoDisplayed}
              className={styles.inputs}
            >
              <div>
                <h4 className={styles.billing_header}>BILLING INFO</h4>
              </div>

              <TextField
                label="First name"
                variant="outlined"
                classes={{ root: styles.textfield }}
                fullWidth
                inputProps={{
                  name: 'First Name Billing',
                }}
                inputRef={register({ required: true })}
                error={!!errors['First Name Billing']}
                helperText={
                  errors['First Name Billing'] &&
                  errors['First Name Billing'].message
                }
              />
              <TextField
                label="Last name"
                variant="outlined"
                classes={{ root: styles.textfield }}
                fullWidth
                inputProps={{
                  name: 'Last Name Billing',
                }}
                inputRef={register({ required: true })}
                error={!!errors['Last Name Billing']}
                helperText={
                  errors['Last Name Billing'] &&
                  errors['Last Name Billing'].message
                }
              />

              {shouldShowBillingEmailInput && (
                <TextField
                  label="Email"
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  inputProps={{
                    name: 'Email Billing',
                  }}
                  inputRef={register({ validate: validateEmail })}
                  error={!!errors['Email Billing']}
                  helperText={
                    errors['Email Billing'] && 'Invalid email address'
                  }
                />
              )}
              <div className={styles.stripe_element}>
                <CardNumberElement
                  onChange={onCreditCardNumberChange}
                  onReady={element => {
                    cardNumberElementRef.current = element;
                  }}
                />
                <p className={styles.error}>{creditCardNumberError}</p>
              </div>

              <div className={styles.expiry_container}>
                <div className={styles.stripe_element}>
                  <CardExpiryElement
                    onChange={onCreditCardExpiryChange}
                    onReady={element => {
                      cardExpiryElementRef.current = element;
                    }}
                  />
                  <p className={styles.error}>{creditCardExpiryError}</p>
                </div>
                <div className={styles.stripe_element}>
                  <CardCvcElement
                    onChange={onCreditCardCVCChange}
                    onReady={element => {
                      cardCVCElementRef.current = element;
                    }}
                  />
                  <p className={styles.error}>{creditCardCVCError}</p>
                </div>
              </div>

              {stripeError && (
                <div className={styles.stripe_error}>
                  <p>{stripeError}</p>
                </div>
              )}
            </div>

            {isFamilyCheckoutForm && (
              <div style={{ paddingTop: 12 }}>{renderCouponInput()}</div>
            )}
          </Collapse>

          <div className={styles.pay_now_btn}>
            <CustomBtn onClick={onConfirm}>
              <span>
                {isBillingInfoDisplayed ? 'checkout' : 'proceed to checkout'}
              </span>
            </CustomBtn>
          </div>
        </div>
      </div>

      <div className={styles.no_card}>
        <h2>Questions? </h2>
        <h2>
          Call{' '}
          <a
            href="tel:+1855-3217800"
            data-content="+1855-3217800"
            data-type="phone"
          >
            855.321.7800
          </a>
        </h2>
        <p>Monday to Friday 9:30am - 6pm Eastern Time</p>
      </div>
    </div>
  );
};

const CheckoutForm = React.memo(forwardRef(CheckoutFormBase));
CheckoutForm.displayName = 'CheckoutForm';

CheckoutFormBase.propTypes = exact({
  onSelectedMuseOptionChange: PropTypes.func.isRequired,
  onCreditCardNumberChange: PropTypes.func.isRequired,
  creditCardNumberError: PropTypes.string,
  creditCardExpiryError: PropTypes.string,
  creditCardCVCError: PropTypes.string,
  onCreditCardExpiryChange: PropTypes.func.isRequired,
  onCreditCardCVCChange: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  museOptions: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  selectedMuseOption: PropTypes.string.isRequired,
  selectedPaymentMethod: PropTypes.string.isRequired,
  paymentMethods: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  onSelectedPaymentMethodChange: PropTypes.func.isRequired,
  total: PropTypes.string.isRequired,
  subTotal: PropTypes.string.isRequired,
  hasCompatibleHeadset: PropTypes.bool.isRequired,
  stripeError: PropTypes.string,
  paymentOptionsId: PropTypes.string.isRequired,
  compatibleMuseText: PropTypes.node,
  compatibleMuseTextColor: PropTypes.string,
  isBillingInfoDisplayed: PropTypes.bool.isRequired,
  billingInfoSectionId: PropTypes.string.isRequired,
  validateEmail: PropTypes.func.isRequired,
  compatibleHeadsetDiscount: PropTypes.number.isRequired,
  isFamilyCheckoutForm: PropTypes.bool.isRequired,
  shouldShowBillingEmailInput: PropTypes.bool.isRequired,
  couponCode: PropTypes.string.isRequired,
  onCouponCodeChange: PropTypes.func.isRequired,
  isCouponCodeBtnLoading: PropTypes.bool.isRequired,
  onCouponCodeApply: PropTypes.func.isRequired,
  couponDiscount: PropTypes.number.isRequired,
  compatibleMuseDiscountDescription: PropTypes.string.isRequired,
  couponCodeError: PropTypes.string,
});

export { CheckoutForm };
