import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './MuseDialog.scss';
import museImage from '../../../../../myndlift-signup/public/assets/muse_popup.png';

const MuseDialogBase = ({
  isOpen,
  onClose,
  // hideMuseHeadset
}) => {
  return (
    <Dialog
      open={isOpen}
      classes={{ paper: styles.dialog_paper }}
      maxWidth="md"
    >
      <div className={styles.icon_continer}>
        <CloseIcon
          style={{
            cursor: 'pointer',
            color: '#808080',
            marginTop: 10,
            marginRight: 10,
          }}
          onClick={onClose}
        />
      </div>
      <div className={styles.content_continer}>
        <div className={styles.content_title}>What's a Muse headband?</div>
        <img src={museImage} className={styles.popup_img} />
        <div style={{ marginBottom: 20 }}>
          The Muse headband is the device we use to measure your brain activity
          and is necessary to be able to use Myndlift.
        </div>
        <div style={{ marginBottom: 15, fontWeight: 900 }}>
          Can I use it for other purposes?
        </div>
        <div>
          Yes! Other than Myndlift, there are a few apps out there that work
          with this headband for meditation and sleep tracking!
        </div>
        <div style={{ marginBottom: 15, marginTop: 15, fontWeight: 900 }}>
          I have a Muse. Is it supported?
        </div>
        <div>
          Myndlift works with the Original Muse 2016 model (MU-02), Muse 2
          (MU-03), and Muse S.
        </div>
      </div>
    </Dialog>
  );
};

MuseDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // hideMuseHeadset: PropTypes.bool,
});

export const MuseDialog = React.memo(MuseDialogBase);
MuseDialog.displayName = 'MuseDialog';
