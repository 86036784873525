import React from 'react';

export const WhiteMoneyIcon = props => (
  <svg width={35.17} height={21.102} {...props} viewBox="0 0 35.17 21.102">
    <path
      id="Icon_awesome-money-bill"
      data-name="Icon awesome-money-bill"
      d="M33.411,4.5H1.758A1.759,1.759,0,0,0,0,6.258V23.843A1.759,1.759,0,0,0,1.758,25.6H33.411a1.759,1.759,0,0,0,1.758-1.758V6.258A1.759,1.759,0,0,0,33.411,4.5ZM2.638,22.964V19.447a3.517,3.517,0,0,1,3.517,3.517Zm0-12.309V7.138H6.155A3.517,3.517,0,0,1,2.638,10.655Zm14.947,9.672c-2.428,0-4.4-2.362-4.4-5.275s1.968-5.275,4.4-5.275,4.4,2.362,4.4,5.275S20.012,20.326,17.585,20.326Zm14.947,2.638H29.015a3.517,3.517,0,0,1,3.517-3.517Zm0-12.309a3.517,3.517,0,0,1-3.517-3.517h3.517Z"
      transform="translate(0 -4.5)"
      fill="#fff"
    />
  </svg>
);
