import React from 'react';

export const StarsIcon = props => (
  <svg width={57.038} height={24} {...props} viewBox="0 0 57.038 9.282">
    <g id="Group_1" data-name="Group 1" transform="translate(-334 -695.505)">
      <path
        id="Icon_awesome-star"
        data-name="Icon awesome-star"
        d="M5.77.322l-1.184,2.4-2.648.386a.58.58,0,0,0-.321.99L3.533,5.965,3.08,8.6a.58.58,0,0,0,.841.611L6.29,7.968,8.659,9.213A.58.58,0,0,0,9.5,8.6L9.047,5.965,10.963,4.1a.58.58,0,0,0-.321-.99L7.994,2.722,6.81.322a.581.581,0,0,0-1.04,0Z"
        transform="translate(332.559 695.506)"
        fill="#525264"
      />
      <path
        id="Icon_awesome-star-2"
        data-name="Icon awesome-star"
        d="M5.77.322l-1.184,2.4-2.648.386a.58.58,0,0,0-.321.99L3.533,5.965,3.08,8.6a.58.58,0,0,0,.841.611L6.29,7.968,8.659,9.213A.58.58,0,0,0,9.5,8.6L9.047,5.965,10.963,4.1a.58.58,0,0,0-.321-.99L7.994,2.722,6.81.322a.581.581,0,0,0-1.04,0Z"
        transform="translate(344.394 695.506)"
        fill="#525264"
      />
      <path
        id="Icon_awesome-star-3"
        data-name="Icon awesome-star"
        d="M5.77.322l-1.184,2.4-2.648.386a.58.58,0,0,0-.321.99L3.533,5.965,3.08,8.6a.58.58,0,0,0,.841.611L6.29,7.968,8.659,9.213A.58.58,0,0,0,9.5,8.6L9.047,5.965,10.963,4.1a.58.58,0,0,0-.321-.99L7.994,2.722,6.81.322a.581.581,0,0,0-1.04,0Z"
        transform="translate(356.229 695.506)"
        fill="#525264"
      />
      <path
        id="Icon_awesome-star-4"
        data-name="Icon awesome-star"
        d="M5.77.322l-1.184,2.4-2.648.386a.58.58,0,0,0-.321.99L3.533,5.965,3.08,8.6a.58.58,0,0,0,.841.611L6.29,7.968,8.659,9.213A.58.58,0,0,0,9.5,8.6L9.047,5.965,10.963,4.1a.58.58,0,0,0-.321-.99L7.994,2.722,6.81.322a.581.581,0,0,0-1.04,0Z"
        transform="translate(368.064 695.506)"
        fill="#525264"
      />
      <path
        id="Icon_awesome-star-5"
        data-name="Icon awesome-star"
        d="M5.77.322l-1.184,2.4-2.648.386a.58.58,0,0,0-.321.99L3.533,5.965,3.08,8.6a.58.58,0,0,0,.841.611L6.29,7.968,8.659,9.213A.58.58,0,0,0,9.5,8.6L9.047,5.965,10.963,4.1a.58.58,0,0,0-.321-.99L7.994,2.722,6.81.322a.581.581,0,0,0-1.04,0Z"
        transform="translate(379.899 695.506)"
        fill="#525264"
      />
    </g>
  </svg>
);
