import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import ReactHtmlParser from 'react-html-parser';
import styles from './CouponDiscountBanner.scss';

const CouponDiscountBannerBase = ({
  discountCoupon,
  discountCouponAmount,
  discountCouponText,
  discountCouponGroupText,
  // couponDiscount,
  // lastValidCouponCode,
  customCouponText,
}) => {
  const htmlText =
    customCouponText || discountCouponGroupText || discountCouponText;
  let text = htmlText;
  if (htmlText && htmlText.includes('{{coupon_amount}}')) {
    text = htmlText.replace(
      '{{coupon_amount}}',
      `<span className={styles.bold}>${discountCouponAmount}</span>`
    );
  }

  if (htmlText && htmlText.includes('{{discountCoupon}}')) {
    text = text.replace('{{discountCoupon}}', discountCoupon);
  }

  // if (lastValidCouponCode) {
  //   return (
  //     <div>
  //       <div className={styles.coupon_banner}>
  //         <p>
  //           <>
  //             {htmlText ? ReactHtmlParser(text) : '🎉 BLACK FRIDAY SALE! Get'}{' '}
  //             <span className={styles.bold}>
  //               {couponDiscount.type === 'Const' ? '$' : null}
  //               {couponDiscount.amount}
  //               {couponDiscount.type === 'Percent' ? '%' : null}
  //             </span>{' '}
  //             OFF with code "{lastValidCouponCode}"
  //           </>
  //         </p>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    (discountCouponAmount > 0 || discountCouponGroupText) && (
      <div>
        <div className={styles.coupon_banner}>
          <p>
            {htmlText ? (
              ReactHtmlParser(text)
            ) : (
              <>
                Limited time offer: get{' '}
                <span className={styles.bold}>${discountCouponAmount}</span> off
                with code "{discountCoupon}"
              </>
            )}
          </p>
        </div>
      </div>
    )
  );
};

CouponDiscountBannerBase.propTypes = exact({
  discountCoupon: PropTypes.string.isRequired,
  discountCouponAmount: PropTypes.number.isRequired,
  discountCouponText: PropTypes.strings,
  discountCouponGroupText: PropTypes.strings,
  // couponDiscount: PropTypes.object,
  // lastValidCouponCode: PropTypes.string,
  customCouponText: PropTypes.strings,
});

export const CouponDiscountBanner = React.memo(CouponDiscountBannerBase);
CouponDiscountBanner.displayName = 'CouponDiscountBanner';
